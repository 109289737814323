/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { rgba } from 'polished';
import { ReactNode, useEffect, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Clickable from './components/Clickable';
import Form from './components/Field/Form';
import useTimelapseField from './components/Field/useTimelapseField';
import ActivityForm from './components/forms/ActivityForm';
import Loading from './components/Loading';
import Panel from './components/Panel';
import Panels from './components/Panel/Panels';
import Typo from './components/Typo';
import Clients from './screens/Clients';
import Contracts from './screens/Contracts';
import Expenses from './screens/Expenses';
import Invoices, {
  InvoiceCreation,
  InvoiceDetail,
  InvoicesPanel,
} from './screens/Invoices';
import InvoicesExport from './screens/Invoices/InvoicesExport';
import Login from './screens/Login';
import Moves from './screens/Moves';
import MoveCreation from './screens/Moves/MoveCreation';
import MoveEdition from './screens/Moves/MoveEdition';
import { default as MovesExport } from './screens/Moves/MovesExport';
import Payments from './screens/Payments';
import Performances from './screens/Performances';
import Projections from './screens/Projections';
import YearProjections from './screens/Projections/ProjectionsListPanel';
import TimesheetExport from './screens/TimesheetExport';
import TimesheetTable from './screens/TimesheetTable';
import YearPerformances from './screens/YearPerformances';
import Api from './utilities/Api';
import AppTimelapse from './utilities/AppTimelapse';
import Css from './utilities/Css';
import { useDropdown } from './utilities/Dropdown';
import Environment from './utilities/Environment';
import Me from './utilities/Me';
import Repository from './utilities/Repository';
import Zones, { Routing, Zone } from './utilities/Routes';
import Services, { ServiceList } from './utilities/Services';
import Store from './utilities/Store';
import Theme from './utilities/Theme';
import Typos from './utilities/Typos';
import useChange from './utilities/useChange';
import useResponsive from './utilities/useResponsive';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.locale(fr);

export default function App() {
  useDropdown();

  const [services, setServices] = useState<ServiceList | null>(null);

  const globalCss = css`
    * {
      box-sizing: border-box;
    }

    html {
      ${Typos.body};
      height: 100%;
    }
    body {
      position: relative;
      background-color: #f0f0f0;
      height: 100%;
    }
    #root {
      height: 100%;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
    .react-calendar {
      border: none;
      width: 100%;
      ${Typos.body};
      button {
        ${Css.buttonReset};
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .react-calendar__tile {
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 4px;
        border: 1px dotted rgba(0, 0, 0, 0);
      }
      .react-calendar__tile--now {
        border: 1px dotted rgba(0, 0, 0, 0.2);
      }
      .react-calendar__tile--active {
        background-color: ${rgba('blue', 0.5)};
      }
    }
  `;

  useEffect(() => {
    // Data.init()
    // Holidays.init()
    const me = new Me();
    const api = new Api(Environment.string('REACT_APP_API_URL'), me);
    const store = new Store(api);
    const repository = new Repository(
      Environment.string('REACT_APP_API_URL'),
      me,
    );
    setServices({ api, store, repository });
  }, []);

  const Component = useResponsive({
    1200: MobileApp,
    bigger: DesktopApp,
  });

  let content: ReactNode = null;

  if (!services) {
    content = <Loading containerCss={Css.pageSize} />;
  } else {
    content = (
      <Services.Provider services={services}>
        <Component />
      </Services.Provider>
    );
  }

  return (
    <BrowserRouter>
      <Global styles={globalCss} />
      {content}
    </BrowserRouter>
  );
}

function DesktopApp() {
  const { api } = Services.use();
  const isLoggedIn = api.me.useHasToken();

  if (!isLoggedIn) {
    return <Login />;
  }

  return (
    <Routes>
      <Route
        path={Routing.getZonePattern(Zones.Home)}
        element={<DesktopRoot />}>
        <Route
          path={Routing.getZonePattern(Zones.Contracts)}
          element={<Contracts />}
        />
        <Route
          path={Routing.getZonePattern(Zones.Depenses)}
          element={<Expenses />}
        />
        <Route
          path={Routing.getZonePattern(Zones.Clients)}
          element={<Clients />}
        />
        <Route
          path={Routing.getZonePattern(Zones.Projections)}
          element={<Projections />}>
          <Route
            path={Routing.getZonePattern(Zones.YearProjections)}
            element={<YearProjections />}
          />
        </Route>
        <Route
          path={Routing.getZonePattern(Zones.Timesheet)}
          element={<Outlet />}>
          <Route index element={<TimesheetTable />} />
          <Route
            path={Routing.getZonePattern(Zones.TimesheetExport)}
            element={<TimesheetExport />}
          />
        </Route>
        <Route
          path={Routing.getZonePattern(Zones.Performances)}
          element={<Performances />}>
          <Route
            path={Routing.getZonePattern(Zones.YearPerformances)}
            element={<YearPerformances />}
          />
        </Route>
        <Route
          path={Routing.getZonePattern(Zones.Invoices)}
          element={<Invoices />}>
          <Route index element={<InvoicesPanel />} />
          <Route
            path={Routing.getZonePattern(Zones.InvoiceCreation)}
            element={<InvoiceCreation />}
          />
          <Route
            path={Routing.getZonePattern(Zones.Invoice)}
            element={<InvoiceDetail />}
          />
        </Route>
        <Route
          path={Routing.getZonePattern(Zones.InvoicesExport)}
          element={<InvoicesExport />}
        />
        <Route
          path={Routing.getZonePattern(Zones.Payments)}
          element={<Payments />}
        />
        <Route path={Routing.getZonePattern(Zones.Moves)} element={<Outlet />}>
          <Route index element={<Moves />} />
          <Route
            path={Routing.getZonePattern(Zones.MovesExport)}
            element={<MovesExport />}
          />
          <Route
            path={Routing.getZonePattern(Zones.MoveCreation)}
            element={<MoveCreation />}
          />
          <Route
            path={Routing.getZonePattern(Zones.MoveEdition)}
            element={<MoveEdition />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

function DesktopRoot() {
  const containerCss = css({
    display: 'flex',
  });

  const menuCss = css({
    width: 200,
    flexShrink: 0,
  });

  const contentCss = css({
    flexGrow: 1,
    minWidth: 1,
  });

  return (
    <div css={containerCss}>
      <div css={menuCss}>
        <DesktopMenu />
      </div>
      <div css={contentCss}>
        <Outlet />
      </div>
    </div>
  );
}

function DesktopMenu() {
  const neutral = Theme.useNeutralColor();
  const color = Theme.useMainColor();

  const containerCss = css(Css.hideInPrint, {
    width: 200,
  });

  const menuCss = css(Css.hideInPrint, {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 10,
    zIndex: 1000,
    width: 200,
  });

  const appTimelapse = AppTimelapse.use();

  const timelapseField = useTimelapseField({
    label: 'Période',
    initialValue: appTimelapse,
  });
  const timelapse = timelapseField.value;

  useChange(() => {
    if (timelapse) {
      AppTimelapse.set(timelapse);
    } else {
      AppTimelapse.set(dayjs().format('YYYY-MM'));
    }
  }, [timelapse]);

  return (
    <div css={containerCss}>
      <div css={menuCss}>
        <Form onSubmit={() => {}} hideSubmit>
          {timelapseField.render()}
        </Form>
        <MenuItem zone={Zones.Clients} label="Clients" />
        <MenuItem zone={Zones.Depenses} label="Dépenses" />
        <MenuItem zone={Zones.Projections} label="Prévisionnel" />
        <MenuItem zone={Zones.Contracts} label="Contrats" />
        <MenuItem zone={Zones.Timesheet} label="Activité" />
        <MenuItem zone={Zones.Invoices} label="Facturation" />
        <MenuItem zone={Zones.Payments} label="Paiements" />
        <MenuItem zone={Zones.Moves} label="Déplacements" />
        <MenuItem zone={Zones.Performances} label="Performances" />
      </div>
    </div>
  );
}

type MenuItemProps = {
  zone: Zone;
  label: string;
};

function MenuItem(props: MenuItemProps) {
  const { zone, label } = props;

  const selected = Routing.useIsUnderZone(zone);

  const itemCss = css({
    display: 'flex',
    padding: 10,
    color: selected ? 'blue' : 'black',
  });

  return (
    <Clickable css={itemCss} to={Routing.getPattern(zone)}>
      <Typo typo="button">{label}</Typo>
    </Clickable>
  );
}

/*
  <Route path="clients" element={<Clients />} />
        
        <Route
          path="performances"
          element={
            <Navigate
              to={`/performances/${Time.format(new Date(), 'yyyy')}`}
            />
          }
        />
        <Route path="performances/:year" element={<Performances />} />
*/

function MobileApp() {
  const { api } = Services.use();
  const isLoggedIn = api.me.useHasToken();

  if (!isLoggedIn) {
    return <Login />;
  }

  return <Panels main={ActivityCreationPanel} />;
}

function ActivityCreationPanel() {
  return (
    <Panel.Container>
      <Panel.Header title="Nouvelle activité" />
      <ActivityForm />
    </Panel.Container>
  );
}
