import { nanoid } from 'nanoid';
import { DependencyList, useCallback, useState } from 'react';

/**
 * Equivalent de useState limité aux valeurs binaires (gain de perf grâce à memoïsation)
 *
 * @returns state : Etat
 * @returns setState.toFalse()
 * @returns setState.toTrue()
 * @returns setState.to(state)
 * @returns setState.toggle()
 */

export default function useAsyncCallback<TArgs extends Array<any>, TReturn>(
  fn: (...args: TArgs) => Promise<TReturn>,
  deps: DependencyList,
) {
  const [loading, setLoading] = useState<string | null>(null);

  const callback = useCallback(async (...args: TArgs) => {
    const id = nanoid();
    setLoading(id);
    try {
      const result = await fn(...args);
      setLoading((n) => (n === id ? null : n));
      return result;
    } catch (err) {
      setLoading((n) => (n === id ? null : n));
      throw err;
    }
  }, deps);

  return [callback, !!loading] as const;
}
