import { useCallback, useState } from 'react';

/**
 * Equivalent de useState limité aux valeurs binaires (gain de perf grâce à memoïsation)
 *
 * @returns state : Etat
 * @returns setState.toFalse()
 * @returns setState.toTrue()
 * @returns setState.to(state)
 * @returns setState.toggle()
 */

export default function useBooleanState(
  initialValue: boolean | (() => boolean),
) {
  const [state, setState] = useState<boolean>(initialValue);
  const toFalse = useCallback(() => setState(false), []);
  const toTrue = useCallback(() => setState(true), []);
  const toggle = useCallback(() => setState((s) => !s), []);
  const to = setState;
  return [state, { toFalse, toTrue, toggle, to }] as const;
}
