import { useCallback, useMemo } from 'react';
import Updater from './Updater';

export default class Speakers {
  private updater = new Updater();
  private lock: number | null = null;

  useSpeaker() {
    const id = useMemo(() => Math.random(), []);
    const locked = this.updater.useValue(() => this.lock === id, [id]);
    const speak = useCallback(() => {
      this.lock = id;
      this.updater.update();
    }, [id]);
    const shutUp = useCallback(() => {
      if (this.lock !== id) return;
      this.shutUp();
    }, [id]);
    return [locked, speak, shutUp] as const;
  }

  shutUp() {
    this.lock = null;
    this.updater.update();
  }
}
