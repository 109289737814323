/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import Services from 'src/utilities/Services';
import useAsyncCallback from 'src/utilities/useAsyncCallback';
import Button from '../components/Button';
import { SpacerDouble, SpacerHalf } from '../components/Spacer';
import Typo from '../components/Typo';
import Css from '../utilities/Css';
import Layout from '../utilities/Layout';

export default function Login() {
  const { api } = Services.use();
  const [token, setToken] = useState<string>('');

  const containerCss = css`
    ${Css.centerContent};
    height: 100vh;
    background-color: ${Layout.background2Cells};
  `;

  const formCss = css`
    ${Css.centerContent};
    flex-direction: column;
    border: 1px solid ${Layout.borderColor};
    border-radius: 8px;
    padding: ${Layout.S * 2}px;
    background-color: ${Layout.background1Cell};
  `;

  const inputCss = css`
    ${Css.inputReset};
    flex-direction: column;
    border: 1px solid ${Layout.borderColor};
    border-radius: 8px;
    padding: ${Layout.S}px;
    background-color: ${Layout.background1Cell};
    width: 260px;
    text-align: center;
  `;

  const [onSubmit] = useAsyncCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      api.me.setToken(token);
      const me = await api.getMe();
      console.log(me);
    },
    [token],
  );

  return (
    <div css={containerCss}>
      <form css={formCss} onSubmit={onSubmit}>
        <label htmlFor="apiKey">
          <Typo>Jeton de connexion</Typo>
        </label>
        <SpacerHalf />
        <input
          type="password"
          css={inputCss}
          id="token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
        <br />
        <SpacerDouble />
        <Button label="Continuer" submit />
      </form>
    </div>
  );
}
