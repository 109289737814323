import { DaysPurpose } from 'src/utilities/Purposes';
import useSubmit from 'src/utilities/useSubmit';
import { Required } from '../Field/FieldValidation';
import Form from '../Field/Form';
import useNumberField from '../Field/useNumberField';

type DaysPurposeFormProps = {
  purpose: Partial<DaysPurpose> | null;
  onSubmit: (p: DaysPurpose) => any;
};

export default function DaysPurposeForm(props: DaysPurposeFormProps) {
  const { purpose, onSubmit } = props;

  const daysField = useNumberField({
    label: 'Jours',
    initialValue: purpose ? purpose.days : null,
    unit: (v) => (v > 1 ? ' jours' : ' jour'),
    validation: (p) => Required(p),
  });

  const dailyPriceField = useNumberField({
    label: 'Taux journalier',
    initialValue: purpose ? purpose.daily_price : null,
    unit: (v) => ` € / jour`,
    validation: (p) => Required(p),
  });

  const onSubmitForm = useSubmit(() => {
    const days = daysField.validate();
    const daily_price = dailyPriceField.validate();
    onSubmit({ type: 'days', days, daily_price });
  }, [onSubmit]);

  return (
    <Form onSubmit={onSubmitForm}>
      {daysField.render()}
      {dailyPriceField.render()}
    </Form>
  );
}
