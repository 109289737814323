/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Layout from '../utilities/Layout';

type SpacerBaseProps = {
  grow?: boolean;
};

type SpacerFreeProps = SpacerBaseProps & {
  size: number;
};
function SpacerFree(props: SpacerFreeProps) {
  const containerCss = css`
    width: ${props.size}px;
    height: ${props.size}px;
    flex-shrink: 0;
    ${props.grow ? 'flex-grow : 1' : ''}
  `;
  return <div css={containerCss} />;
}

export function SpacerPrint(props: { n?: number }) {
  const n = props.n || 1;
  const containerCss = css`
    width: ${n * 0.2}cm;
    height: ${n * 0.2}cm;
    flex-shrink: 0;
  `;
  return <div css={containerCss} />;
}

const Spacer = (props: SpacerBaseProps) => (
  <SpacerFree size={Layout.S} {...props} />
);
export function SpacerHalf(props: SpacerBaseProps) {
  return <SpacerFree size={Layout.S / 2} {...props} />;
}

export function SpacerDouble(props: SpacerBaseProps) {
  return <SpacerFree size={Layout.S * 2} {...props} />;
}

export function SpacerGrow(props: SpacerBaseProps) {
  return (
    <div
      css={css`
        flex-grow: 1;
      `}
    />
  );
}

export default Spacer;
