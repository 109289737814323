import { Fragment } from 'react';
import Button from 'src/components/Button';
import DelayedView from 'src/components/DelayedView';
import ClientForm from 'src/components/forms/ClientForm';
import ProjectItem from 'src/components/items/ProjectItem';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Model, { ClientPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import ProjectCreationPanel from '../Project/ProjectCreationPanel';
import ProjectEditionPanel from '../Project/ProjectEditionPanel';

type ClientEditionPanelProps = {
  client: string;
};

export default function ClientEditionPanel(props: ClientEditionPanelProps) {
  const id = props.client;
  const { repository } = Services.use();
  const panels = usePanels();

  const client = repository.useData(() => repository.getClient(id), [id]);
  const projects = repository.useData(
    () => repository.getClientProjects(id),
    [id],
  );

  const onCreateProject = useSubmit(() => {
    panels.push(ProjectCreationPanel, { ref_client: id });
  }, []);

  const onClickProject = useSubmit((p: Model.Project) => {
    panels.push(ProjectEditionPanel, { project: p.id });
  }, []);

  const onSubmit = useSubmit((p: ClientPayload) => {
    repository.axios.patch<Model.Client>('/clients/' + id, p);
    repository.invalidate(repository.storage.searchKeys('clients', id));
  }, []);

  return (
    <Panel.Container>
      <DelayedView promise={client}>
        {(client) => {
          return (
            <Fragment>
              <Panel.Header title={client.name} />
              <ClientForm client={client} onSubmit={onSubmit} />
              <DelayedView promise={projects}>
                {(projects) => {
                  return (
                    <Fragment>
                      <Panel.List
                        label="Projets"
                        items={projects}
                        renderItem={(p) => (
                          <ProjectItem
                            project={p}
                            onClick={() => onClickProject(p)}
                          />
                        )}
                        getKey={(p) => p.id}
                      />
                      <Button
                        label="Nouveau projet"
                        onClick={onCreateProject}
                      />
                    </Fragment>
                  );
                }}
              </DelayedView>
            </Fragment>
          );
        }}
      </DelayedView>
    </Panel.Container>
  );
}
