import { ExtraPurpose } from 'src/utilities/Purposes';
import useSubmit from 'src/utilities/useSubmit';
import { Required } from '../Field/FieldValidation';
import Form from '../Field/Form';
import useNumberField from '../Field/useNumberField';
import useTextField from '../Field/useTextField';

type ExtraPurposeFormProps = {
  purpose: Partial<ExtraPurpose> | null;
  onSubmit: (p: ExtraPurpose) => any;
};

export default function ExtraPurposeForm(props: ExtraPurposeFormProps) {
  const { purpose, onSubmit } = props;

  const labelField = useTextField({
    label: 'Description',
    initialValue: purpose ? purpose.label : null,
    validation: (p) => Required(p),
  });

  const priceField = useNumberField({
    label: 'Tarif',
    initialValue: purpose ? purpose.price : null,
    unit: (v) => (v > 1 ? ' €' : ' €'),
    decimals: 2,
    validation: (p) => Required(p),
  });

  const quantityField = useNumberField({
    label: 'Quantité',
    initialValue: purpose ? purpose.quantity : null,
    validation: (p) => Required(p),
  });

  const onSubmitForm = useSubmit(() => {
    const label = labelField.validate();
    const price = priceField.validate();
    const quantity = quantityField.validate();
    onSubmit({ type: 'extra', label, price, quantity });
  }, [onSubmit]);

  return (
    <Form onSubmit={onSubmitForm}>
      {labelField.render()}
      {priceField.render()}
      {quantityField.render()}
    </Form>
  );
}
