import React, { useContext } from 'react';
import Api from 'src/utilities/Api';
import DataSet from 'src/utilities/DataSet';
import Model, { InvoicePayload } from 'src/utilities/Model';
import R from 'src/utilities/R';
import { Timelapse } from 'src/utilities/Timelapses';
import {
  EmptyUnsubscriber,
  Service,
} from '../TimesheetTable/TimesheetTableController';

export default class InvoicesController implements Service {
  private r = new R();
  private invoices!: Array<Model.Invoice>;

  constructor(readonly api: Api, readonly timelapse: Timelapse) {}

  async init() {
    const [invoices] = await Promise.all([this.fetchInvoices()]);
    this.invoices = invoices;
    return EmptyUnsubscriber;
  }

  private async fetchInvoices() {
    return this.api.getInvoices(this.timelapse);
  }

  // Tasks

  useInvoices() {
    return this.r.useSelector(() => this.invoices);
  }

  useInvoice(id: string) {
    return this.r.useSelector(() => DataSet.find(this.invoices, id), [id]);
  }

  async createInvoice(data: InvoicePayload) {
    const output = await this.api.createInvoice(data);
    console.log(output);
    this.invoices = DataSet.upsert(this.invoices, output);
    this.r.notify();

    return output;
  }

  async updateInvoice(id: string, data: InvoicePayload) {
    const output = await this.api.updateInvoice(id, data);
    this.invoices = DataSet.replace(this.invoices, output);
    this.r.notify();
  }

  async deleteInvoice(id: string) {
    await this.api.deleteInvoice(id);
    this.invoices = DataSet.remove(this.invoices, id);
    this.r.notify();
  }
}

export const InvoicesControllerContext =
  React.createContext<InvoicesController | null>(null);
export function useInvoicesController() {
  const service = useContext(InvoicesControllerContext);
  if (!service) throw new Error('No service service');
  return service;
}
