import { DependencyList, useEffect, useRef } from 'react';

export default function useChange(fn: () => any, deps: DependencyList) {
  const mountPassed = useRef<boolean>(false);
  useEffect(() => {
    if (mountPassed.current) {
      fn();
    }
    mountPassed.current = true;
  }, deps);
}
