/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { rgba } from 'polished';
import { PropsWithChildren } from 'react';
import useResponsive from 'src/utilities/useResponsive';

type FieldDropdownProps = PropsWithChildren<{
  visible: boolean;
  height?: number;
}>;

export default function FieldDropdown(props: FieldDropdownProps) {
  const Component = useResponsive({
    1200: MobileFieldDropdown,
    bigger: DesktopFieldDropdown,
  });
  return <Component {...props} />;
}

function MobileFieldDropdown(props: FieldDropdownProps) {
  const { visible, children, height } = props;

  const containerCss = css({
    border: `1px solid ${rgba('black', 0.2)}`,
    position: 'fixed',
    bottom: 0,
    left: '50%',
    width: '100%',
    maxWidth: 450,
    transform: `translate3d(-50%, ${visible ? '0%' : '100%'}, 0px)`,
    transition: 'transform 200ms, opacity 200ms',
    zIndex: 2,
    opacity: visible ? 1 : 0.5,
    pointerEvents: visible ? 'auto' : 'none',
    background: 'white',
    maxHeight: height,
    overflow: 'auto',
    boxShadow: `0px 0px 100px ${rgba('black', 0.5)}`,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  });

  return <div css={containerCss}>{children}</div>;
}

function DesktopFieldDropdown(props: FieldDropdownProps) {
  const { visible, children, height } = props;

  const containerCss = css({
    border: `1px solid ${rgba('black', 0.2)}`,
    position: 'absolute',
    top: `calc(100% + 2px)`,
    left: -1,
    right: -1,
    zIndex: 2,
    opacity: visible ? 1 : 0,
    pointerEvents: visible ? 'auto' : 'none',
    background: 'white',
    maxHeight: visible ? height : 0,
    overflow: 'auto',
  });

  return <div css={containerCss}>{children}</div>;
}
