/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import overlapColors from 'src/utilities/overlapColors';
import Theme from 'src/utilities/Theme';

type EmptyTopRightCellProps = {};

export default function EmptyTopRightCell(props: EmptyTopRightCellProps) {
  const containerCss = css`
    background-color: ${overlapColors('white', Theme.useColor(2))};
    border-left: 1px solid ${Theme.useColor(2)};
    border-bottom: 1px solid ${Theme.useColor(2)};
  `;

  return <div css={containerCss}></div>;
}
