/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import useMemoState from 'src/utilities/useMemoState';
import FieldContainer from './FieldContainer';
import FieldInput from './FieldInput';
import fieldStyles from './fieldStyles';
import { useValidateFn, ValidationFn } from './FieldValidation';

type TextFieldConfig<TValidated extends string | null> = {
  label: string | null;
  initialValue: string | null | undefined;
  placeholder?: string;
  validation?: ValidationFn<string | null, TValidated>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
};

export default function useTextField<TValidated extends string | null>(
  config: TextFieldConfig<TValidated>,
) {
  const {
    initialValue,
    label,
    placeholder,
    validation,
    inputProps = {},
  } = config;

  const [value, setValue] = useMemoState(() => {
    if (initialValue === undefined) return null;
    else if (initialValue === null) return null;
    else if (initialValue === '') return null;
    else return initialValue;
  }, [initialValue]);

  const displayedValue = value === null ? '' : value;

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    if (v === '') setValue(null);
    else setValue(v);
  }, []);

  const render = () => (
    <FieldContainer label={label}>
      <FieldInput
        {...inputProps}
        type="text"
        css={fieldStyles.inputCss}
        value={displayedValue}
        onChange={onChange}
        placeholder={placeholder}
      />
    </FieldContainer>
  );

  const validate = useValidateFn(value, validation);

  return { value, render, validate };
}
