import { useCallback, useRef } from 'react';

export const Required = <TInput>(v: TInput): Exclude<TInput, null> => {
  if (v === null) throw new Error('Value cannot be null');
  return v as Exclude<TInput, null>;
};

export type ValidationFn<I, O extends I> = (input: I) => O;

export function useValidateFn<I, O extends I>(
  value: I,
  validation?: ValidationFn<I, O>,
): () => O {
  const valueRef = useRef<I>(value);
  valueRef.current = value;
  return useCallback(() => {
    const v = valueRef.current;
    if (validation) return validation(v);
    else return v as O;
  }, [value]);
}

export function useGetFn<I>(value: I): () => I {
  const valueRef = useRef<I>(value);
  valueRef.current = value;
  return useCallback(() => {
    return valueRef.current as I;
  }, [value]);
}
