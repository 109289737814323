import { Fragment, ReactNode } from 'react';
import Defered, { useDefered } from 'src/utilities/Defered';

type DelayedViewProps<TValue> = {
  promise: Promise<TValue>;
  children: (value: TValue) => ReactNode;
  renderPending?: () => ReactNode;
  renderRejected?: (error: any) => ReactNode;
};

export default function DelayedView<TValue>(props: DelayedViewProps<TValue>) {
  const { promise, children } = props;

  const defered = useDefered(promise, true);

  const renderPending = props.renderPending || (() => null);
  const renderRejected = props.renderRejected || (() => null);

  if (Defered.isPending(defered)) {
    return <Fragment>{renderPending()}</Fragment>;
  } else if (Defered.isResolved(defered)) {
    return <Fragment>{children(defered.value)}</Fragment>;
  } else {
    return <Fragment>{renderRejected(defered.error)}</Fragment>;
  }
}

export function useDelayedString<TValue>(
  promise: Promise<TValue>,
  resolved: (v: TValue) => string,
  config: {
    pending?: () => string;
    rejected?: (error: any) => string;
  } = {},
) {
  const pending = config.pending || (() => '');
  const rejected = config.rejected || (() => '');

  const defered = useDefered(promise, true);

  if (Defered.isPending(defered)) {
    return pending();
  } else if (Defered.isResolved(defered)) {
    return resolved(defered.value);
  } else {
    return rejected(defered.error);
  }
}
