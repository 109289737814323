/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Fragment } from 'react';
import Css from 'src/utilities/Css';
import Clickable from '../Clickable';
import Typo from '../Typo';

type TabsProps<TTab, TKey extends string> = {
  tabs: ReadonlyArray<TTab>;
  value: TKey;
  onChange: (t: TKey) => any;
  keyExtractor: (o: TTab) => TKey;
  labelExtractor: (o: TTab) => string;
};

export default function PanelTabs<TTab, TKey extends string>(
  props: TabsProps<TTab, TKey>,
) {
  const { tabs, keyExtractor, labelExtractor, value, onChange } = props;

  const containerCss = css({
    display: 'flex',
  });

  const tabCss = css(Css.buttonReset, {
    padding: 6,
    border: `1px solid lightgrey`,
    background: `#EEEEEE`,
    cursor: 'pointer',
  });

  const tabSelectedCss = css(tabCss, {
    borderBottom: `1px solid transparent`,
    background: `transparent`,
  });

  const spacerCss = css({
    borderBottom: `1px solid lightgrey`,
    minWidth: 10,
  });
  const fillerCss = css({
    borderBottom: `1px solid lightgrey`,
    flexGrow: 1,
    minWidth: 10,
  });

  return (
    <div css={containerCss}>
      {tabs.map((tab) => (
        <Fragment key={keyExtractor(tab)}>
          <div css={spacerCss} />
          <Clickable
            css={value === keyExtractor(tab) ? tabSelectedCss : tabCss}
            onClick={() => onChange(keyExtractor(tab))}>
            <Typo>{labelExtractor(tab)}</Typo>
          </Clickable>
        </Fragment>
      ))}
      <div css={fillerCss} />
    </div>
  );
}
