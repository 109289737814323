/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useCallback } from 'react';
import useMemoState from 'src/utilities/useMemoState';
import FieldContainer from './FieldContainer';
import { useValidateFn, ValidationFn } from './FieldValidation';

type CheckboxFieldConfig<TValidated extends boolean | null> = {
  label: string | null;
  initialValue: boolean | null | undefined;
  placeholder?: string;
  validation?: ValidationFn<boolean | null, TValidated>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
};

export default function useCheckboxField<TValidated extends boolean | null>(
  config: CheckboxFieldConfig<TValidated>,
) {
  const {
    initialValue,
    label,
    placeholder,
    validation,
    inputProps = {},
  } = config;

  const [value, setValue] = useMemoState(() => {
    if (initialValue === undefined) return null;
    else if (initialValue === null) return null;
    else return initialValue;
  }, [initialValue]);

  const displayedValue = value === null ? '-' : value === true ? 'Oui' : 'Non';

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // const v = e.target.value;
    // if (v === '') setValue(null);
    // else setValue(v);
  }, []);

  const render = () => (
    <FieldContainer label={label}>
      <div
        css={css({ display: 'flex', alignItems: 'center' })}
        onClick={() => setValue(!value)}>
        {value === true ? (
          <Icon path={mdiCheckboxMarked} size="20px" />
        ) : value === false ? (
          <Icon path={mdiCheckboxBlankOutline} size="20px" />
        ) : null}
        {displayedValue}
      </div>
      {/* <FieldInput
        {...inputProps}
        type="text"
        css={fieldStyles.inputCss}
        value={displayedValue}
        onChange={onChange}
        placeholder={placeholder}
      /> */}
    </FieldContainer>
  );

  const validate = useValidateFn(value, validation);

  return { value, render, validate };
}
