import { normal } from 'color-blend';
import { parseToRgb, rgbToColorString } from 'polished';

function toRgba(color: string) {
  const rgba = parseToRgb(color);
  return {
    r: rgba.red,
    g: rgba.green,
    b: rgba.blue,
    a: 'alpha' in rgba ? rgba.alpha : 1,
  };
}

export default function overlapColors(main: string, ...colors: Array<string>) {
  const output = colors.reduce(
    (blended, color) => normal(blended, toRgba(color)),
    toRgba(main),
  );

  return rgbToColorString({
    red: output.r,
    green: output.g,
    blue: output.b,
    alpha: output.a,
  });
}
