import { round } from 'lodash';
import Currency from 'src/utilities/Format';
import Model from 'src/utilities/Model';
import { Reaction } from '../Clickable';
import ListItem from '../Panel/ListItem';

type InvoiceItemProps = {
  invoice: Model.Invoice;
  taxes?: boolean;
} & Reaction;

export default function InvoiceItem(props: InvoiceItemProps) {
  const { invoice, taxes = false, ...reaction } = props;

  const amount = taxes ? invoice.to_pay_amount : invoice.amount;
  let label = `${invoice.label}`;
  if (invoice.reference) label += ` (${invoice.reference})`;
  const paidPercent = `Payée à ${round(
    (invoice.paid_amount / invoice.to_pay_amount) * 100,
  )}%`;
  return (
    <ListItem
      label={label}
      helper={paidPercent}
      rightText={Currency.format(amount)}
      {...reaction}
    />
  );
}
