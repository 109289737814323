import { Fragment } from 'react';
import DelayedView from 'src/components/DelayedView';
import TopicForm from 'src/components/forms/TopicForm';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Model, { TopicPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';

type TopicEditionPanelProps = {
  topic: string;
};

export default function TopicEditionPanel(props: TopicEditionPanelProps) {
  const id = props.topic;

  const { repository } = Services.use();
  const panels = usePanels();

  const topic = repository.useData(() => repository.getTopic(id), [id]);

  const onSubmit = useSubmit(
    async (topic: TopicPayload) => {
      await repository.axios.patch<Model.Topic>(`/topics/${id}`, {
        ...topic,
      });
      repository.invalidate(repository.storage.searchKeys('topics', id));
    },
    [id],
  );

  const onDelete = useSubmit(async () => {
    await repository.axios.delete<Model.Topic>(`/topics/${id}`);
    repository.invalidate(repository.storage.searchKeys('topics'));
    panels.close();
  }, [id]);

  return (
    <Panel.Container>
      <DelayedView promise={topic}>
        {(topic) => {
          return (
            <Fragment>
              <Panel.Header title={topic.label} />
              <TopicForm
                topic={topic}
                onSubmit={onSubmit}
                onDelete={onDelete}
              />
            </Fragment>
          );
        }}
      </DelayedView>
    </Panel.Container>
  );
}
