import dayjs from 'dayjs';
import Timelapses, { Timelapse } from './Timelapses';
import Updater from './Updater';

function getInQuery() {
  const params = new URLSearchParams(window.location.search);
  if (params.has('timelapse')) {
    const timelapse = params.get('timelapse');
    if (timelapse === null) return null;
    if (Timelapses.is(timelapse)) {
      return timelapse;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function getInStorage() {
  const timelapse = localStorage.getItem('timesheet-timelapse');
  if (!timelapse) return null;
  if (Timelapses.is(timelapse)) {
    return timelapse;
  } else {
    return null;
  }
}

let appTimelapse: Timelapse = getInStorage() || dayjs().format('YYYY-MM');
const updater = new Updater();

set(appTimelapse);

function get() {
  return appTimelapse;
}

function set(timelapse: Timelapse) {
  appTimelapse = timelapse;
  updater.update();
  localStorage.setItem('timesheet-timelapse', timelapse);
}

function use() {
  return updater.useValue(get);
}

const AppTimelapse = {
  get,
  use,
  set,
};

export default AppTimelapse;
