/** @jsxImportSource @emotion/react */
import { Fragment, ReactNode } from 'react';

type PrintLinesProps = {
  gap?: string;
  children: Array<ReactNode>;
};

export default function Lines(props: PrintLinesProps) {
  const { gap = '0cm' } = props;
  return (
    <Fragment>
      {props.children.map((c, i) => (
        <div style={{ paddingTop: gap }} key={i}>
          {c}
        </div>
      ))}
    </Fragment>
  );
}

Lines.style = ``;
