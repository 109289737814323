import { css } from '@emotion/react';

const centerContent = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const inputReset = css`
  border: none;
  font-family: inherit;
  font-size: inherit;
  background: transparent;
  padding: 0px;
  margin: 0px;
  bordet-radius: 0px;
  resize: none;
  min-width: 0px;
  &:focus {
    outline: none;
  }
`;

const buttonReset = css`
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  padding: 0px;
  text-decoration: none;
  margin: 0px;
  bordet-radius: 0px;
  resize: none;
  text-align: left;
  color: inherit;
  display: flex;
  &:focus {
    outline: none;
  }
`;

const pageSize = css`
  width: 100vw;
  height: 100vh;
`;

const fill = css`
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const absoluteFill = css`
  position: absolute;
  ${fill}
`;

const hideInPrint = css`
  @media print {
    & {
      display: none !important;
    }
  }
`;

const Css = {
  centerContent,
  inputReset,
  buttonReset,
  pageSize,
  absoluteFill,
  fill,
  hideInPrint,
};

export default Css;
