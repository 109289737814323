import { sum } from 'lodash';
import { useMemo } from 'react';
import DefaultImages from 'src/utilities/DefaultImages';
import Currency from 'src/utilities/Format';
import Model from 'src/utilities/Model';
import Purposes from 'src/utilities/Purposes';
import Services from 'src/utilities/Services';
import Timelapses from 'src/utilities/Timelapses';
import { Reaction } from '../Clickable';
import DelayedView from '../DelayedView';
import ListItem from '../Panel/ListItem';

type ContractItemProps = {
  contract: Model.Contract;
} & Reaction;

export default function ContractItem(props: ContractItemProps) {
  const { repository } = Services.use();
  const { contract, ...reaction } = props;
  const client = repository.useData(
    () => repository.getClient(contract.ref_client),
    [contract.ref_client],
  );

  const total = useMemo(() => {
    return sum(contract.purposes.map((p) => Purposes.getAmount(p)));
  }, [contract]);

  const timelapse = useMemo(
    () => Timelapses.buildCustom(contract.start, contract.end),
    [contract.start, contract.end],
  );

  return (
    <DelayedView promise={client}>
      {(client) => {
        return (
          <ListItem
            image={client.icon || DefaultImages.client}
            helper={Timelapses.format(timelapse)}
            label={contract.label}
            rightText={Currency.format(total)}
            {...reaction}
          />
        );
      }}
    </DelayedView>
  );
}
