function string(name: string) {
  const value = process.env[name];
  if (value === undefined) throw new Error(`Missing env. : ${name}`);
  return value;
}

const Environment = {
  string,
};

export default Environment;
