/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { ReactNode } from 'react';
import Css from 'src/utilities/Css';
import Clickable, { Reaction } from '../Clickable';
import Typo from '../Typo';

type ListItemProps = {
  label: string;
  rightText?: string;
  helper?: string;
  image?: string | null;
  color?: string;
  onDelete?: () => any;
} & Reaction;

export default function ListItem(props: ListItemProps) {
  const { label, rightText, helper, color, image, onDelete, ...reaction } =
    props;

  const containerCss = css(Css.buttonReset, {
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  });

  const mainTextCss = css({
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 1,
  });

  let imageNode: ReactNode;
  if (image) {
    const imageCss = css({
      marginRight: 6,
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: 40,
      height: 40,
      borderRadius: 3,
    });
    imageNode = <div css={imageCss} />;
  }

  let helperNode: ReactNode;
  if (helper) {
    const helperCss = css({ paddingTop: 3 });
    helperNode = (
      <div css={helperCss}>
        <Typo typo="minor" opacity={0.5}>
          {helper}
        </Typo>
      </div>
    );
  }

  let rightTextNode: ReactNode;
  if (rightText) {
    const rightTextCss = css({ paddingLeft: 6 });
    rightTextNode = (
      <div css={rightTextCss}>
        <Typo typo="minor" opacity={0.5}>
          {rightText}
        </Typo>
      </div>
    );
  }

  let colorNode: ReactNode;
  if (color) {
    colorNode = <span css={css({ color })}>⬤ </span>;
  }

  let onDeleteNode: ReactNode;
  if (onDelete) {
    const deleteNodeCss = css(Css.buttonReset, { paddingLeft: 6 });
    onDeleteNode = (
      <Clickable onClick={onDelete} css={deleteNodeCss}>
        <Icon path={mdiClose} size="20px" />
      </Clickable>
    );
  }

  return (
    <Clickable css={containerCss} {...reaction}>
      {imageNode}
      <div css={mainTextCss}>
        <Typo oneLine>
          {colorNode}
          {label}
        </Typo>
        {helperNode}
      </div>
      {rightTextNode}
      {onDeleteNode}
    </Clickable>
  );
}
