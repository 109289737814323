import { endOfMonth, format, isValid, parse, startOfMonth } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { useMemo } from 'react';
import Time, { IsoDay, IsoMonth, IsoYear } from './Time';

export type Timelapse = IsoDay | IsoMonth | IsoYear | string | 'all';

const YEAR = 'YYYY';
const MONTH = 'YYYY-MM';
const DAY = 'YYYY-MM-DD';

export type TimelapseInfo = {
  timelapse: Timelapse;
  start: IsoDay;
  end: IsoDay;
} & (
  | { type: 'year'; year: number; startParsed: Dayjs; endParsed: Dayjs }
  | {
      type: 'month';
      year: number;
      month: number;
      startParsed: Dayjs;
      endParsed: Dayjs;
    }
  | {
      type: 'day';
      year: number;
      month: number;
      date: number;
      parsed: Dayjs;
      startParsed: Dayjs;
      endParsed: Dayjs;
    }
  | { type: 'custom'; startParsed: Dayjs; endParsed: Dayjs }
);

function getInfos(timelapse: Timelapse): TimelapseInfo {
  const parsedAsYear = dayjs(timelapse, 'YYYY', true);
  const parsedAsMonth = dayjs(timelapse, 'YYYY-MM', true);
  const parsedAsDay = dayjs(timelapse, 'YYYY-MM-DD', true);
  let isCustom = false;
  if (timelapse.includes('_')) {
    const [start, end] = timelapse.split('_');
    const startValid = dayjs(start, 'YYYY-MM-DD', true).isValid();
    const endValid = dayjs(end, 'YYYY-MM-DD', true).isValid();
    if (startValid && endValid) isCustom = true;
  }
  if (parsedAsYear.isValid()) {
    const startParsed = parsedAsYear.startOf('year');
    const endParsed = parsedAsYear.endOf('year');
    return {
      timelapse,
      start: startParsed.format('YYYY-MM-DD'),
      end: endParsed.format('YYYY-MM-DD'),
      startParsed,
      endParsed,
      type: 'year',
      year: parsedAsYear.year(),
    };
  } else if (parsedAsMonth.isValid()) {
    const startParsed = parsedAsMonth.startOf('month');
    const endParsed = parsedAsMonth.endOf('month');
    return {
      timelapse,
      start: startParsed.format('YYYY-MM-DD'),
      end: endParsed.format('YYYY-MM-DD'),
      startParsed,
      endParsed,
      type: 'month',
      year: parsedAsMonth.year(),
      month: parsedAsMonth.month(),
    };
  } else if (parsedAsDay.isValid()) {
    return {
      timelapse,
      parsed: parsedAsDay,
      startParsed: parsedAsDay,
      endParsed: parsedAsDay,
      start: timelapse,
      end: timelapse,
      type: 'day',
      year: parsedAsDay.year(),
      month: parsedAsDay.month(),
      date: parsedAsDay.date(),
    };
  } else if (isCustom) {
    const [start, end] = timelapse.split('_');
    const startParsed = dayjs(start, 'YYYY-MM-DD', true);
    const endParsed = dayjs(end, 'YYYY-MM-DD', true);
    return {
      type: 'custom',
      timelapse,
      start,
      end,
      startParsed,
      endParsed,
    };
  } else {
    throw new Error(`Unhandled timelapse parsing : ${timelapse}`);
  }
}

export type TimelapseType = 'year' | 'month' | 'day' | 'custom' | 'all';

export type TimelapseBoudaries = {
  range: 'year' | 'month' | 'day' | 'custom';
  start: IsoDay;
  end: IsoDay;
};

function is(timelapse: string): timelapse is Timelapse {
  if (timelapse === 'all') return true;
  else if (dayjs(timelapse, 'YYYY-MM-DD', true).isValid()) return true;
  else if (dayjs(timelapse, 'YYYY-MM', true).isValid()) return true;
  else if (dayjs(timelapse, 'YYYY', true).isValid()) return true;
  else if (timelapse.includes('_')) {
    const [start, end] = timelapse.split('_');
    return (
      dayjs(start, 'YYYY-MM-DD', true).isValid() &&
      dayjs(end, 'YYYY-MM-DD', true).isValid()
    );
  }
  return false;
}

function getType(timelapse: Timelapse) {
  const info = getInfos(timelapse);
  return info.type;
}

function buildCustom(date1: IsoDay, date2: IsoDay) {
  const parsed1 = dayjs(date1, 'YYYY-MM-DD', true);
  const parsed2 = dayjs(date2, 'YYYY-MM-DD', true);
  if (parsed1.isSame(parsed2, 'day')) return date1;

  const start = parsed1.isBefore(parsed2, 'day') ? parsed1 : parsed2;
  const end = parsed1.isBefore(parsed2, 'day') ? parsed2 : parsed1;

  if (start.isSame(end, 'year')) {
    if (
      start.isSame(start.startOf('year'), 'day') &&
      end.isSame(end.endOf('year'), 'day')
    ) {
      return start.format('YYYY');
    }
  }

  if (start.isSame(end, 'month')) {
    if (
      start.isSame(start.startOf('month'), 'day') &&
      end.isSame(end.endOf('month'), 'day')
    ) {
      return start.format('YYYY-MM');
    }
  }

  return [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')].join('_');
}

function getPrevious(timelapse: Timelapse): Timelapse {
  const info = getInfos(timelapse);
  if (info.type === 'year') {
    return info.startParsed.subtract(1, 'year').format(YEAR);
  } else if (info.type === 'month') {
    return info.startParsed.subtract(1, 'month').format(MONTH);
  } else if (info.type === 'day') {
    return info.parsed.subtract(1, 'day').format(DAY);
  } else {
    throw new Error('No previous');
  }
}

function getNext(timelapse: Timelapse): Timelapse {
  const info = getInfos(timelapse);
  if (info.type === 'year') {
    return info.startParsed.add(1, 'year').format(YEAR);
  } else if (info.type === 'month') {
    return info.startParsed.add(1, 'month').format(MONTH);
  } else if (info.type === 'day') {
    return info.parsed.add(1, 'day').format(DAY);
  } else {
    throw new Error('No previous');
  }
}

function formatT(timelapse: Timelapse, transition: boolean = true): string {
  const info = getInfos(timelapse);
  if (info.type === 'year') {
    let f = `année ${info.startParsed.format('YYYY')}`;
    if (transition) f = `sur l'${f}`;
    return f;
  } else if (info.type === 'month') {
    let f = `mois de ${info.startParsed.format('MMMM YYYY')}`;
    if (transition) f = `sur le ${f}`;
    return f;
  } else if (info.type === 'day') {
    let f = `${info.parsed.format('LL')}`;
    if (transition) f = `le ${f}`;
    return f;
  } else if (info.type === 'custom') {
    const sameYear = info.startParsed.year() === info.endParsed.year();
    const sameMonth = info.startParsed.month() === info.endParsed.month();
    const sameDay = info.startParsed.date() === info.endParsed.date();
    if (sameYear) {
      if (sameMonth) {
        if (sameDay) {
          let f = `${info.startParsed.format('LL')}`;
          if (transition) f = `le ${f}`;
          return f;
        } else {
          return `du ${info.startParsed.format('D')} au ${info.endParsed.format(
            'D MMMM YYYY',
          )}`;
        }
      } else {
        return `du ${info.startParsed.format(
          'D MMMM',
        )} au ${info.endParsed.format('D MMMM YYYY')}`;
      }
    } else {
      return `du ${info.startParsed.format(
        'D MMMM YYYY',
      )} au ${info.endParsed.format('D MMMM YYYY')}`;
    }
  } else {
    throw new Error('Formatint not handled');
  }
}

function getBoundaries(timelapse: Timelapse): TimelapseBoudaries {
  const parsedAsDay = parse(timelapse, 'yyyy-MM-dd', new Date());
  const parsedAsMonth = parse(timelapse, 'yyyy-MM', new Date());
  const parsedAsYear = dayjs(timelapse, 'YYYY');
  const includesSpace = timelapse.includes('_');

  if (timelapse === 'all') {
    return {
      range: 'custom',
      start: '2022-01-01',
      end: format(new Date(), 'yyyy-MM-dd'),
    };
  } else if (isValid(parsedAsDay)) {
    return {
      range: 'day',
      start: timelapse,
      end: timelapse,
    };
  } else if (isValid(parsedAsMonth)) {
    return {
      range: 'month',
      start: format(startOfMonth(parsedAsMonth), 'yyyy-MM-dd'),
      end: format(endOfMonth(parsedAsMonth), 'yyyy-MM-dd'),
    };
  } else if (parsedAsYear.isValid()) {
    return {
      range: 'year',
      start: dayjs(parsedAsYear).startOf('year').format('YYYY-MM-DD'),
      end: dayjs(parsedAsYear).endOf('year').format('YYYY-MM-DD'),
    };
  } else if (includesSpace) {
    const [start, end] = timelapse.split('_');
    return {
      range: 'custom',
      start,
      end,
    };
  } else {
    throw new Error('Invalid timelapse');
  }
}

function stringifyBoundaries(boundaries: TimelapseBoudaries) {
  const startDate = parseDay(boundaries.start);
  if (boundaries.range === 'year') {
    return Time.format(startDate, 'yyyy');
  } else if (boundaries.range === 'month') {
    return Time.format(startDate, 'yyyy-MM');
  } else if (boundaries.range === 'day') {
    return Time.format(startDate, 'yyyy-MM-dd');
  } else {
    const endDate = parseDay(boundaries.start);
    return `${Time.format(startDate, 'yyyy-MM-dd')}_${Time.format(
      endDate,
      'yyyy-MM-dd',
    )} `;
  }
}

/**
 *
 * @deprecated Use Time.parseDay instead
 */
function parseDay(timelapse: string): Date {
  return Time.parseDay(timelapse);
}

function includes(timelaspe: Timelapse, day: IsoDay): boolean {
  const boudaries = getBoundaries(timelaspe);
  const startTs = Time.parseDay(boudaries.start).valueOf();
  const endTs = Time.parseDay(boudaries.end).valueOf();
  const dayTs = Time.parseDay(day).valueOf();
  return startTs <= dayTs && endTs >= dayTs;
}

function getDays(timelapse: Timelapse) {
  const dates: Array<IsoDay> = [];
  const boundaries = getBoundaries(timelapse);
  let cursor = Time.parseDay(boundaries.start);
  const end = Time.parseDay(boundaries.end);
  while (Time.isBefore(cursor, end) || Time.isSameDay(cursor, end)) {
    dates.push(Time.format(cursor, dayFormat));
    cursor = Time.add(cursor, { days: 1 });
  }
  return dates;
}

function useDays(timelapse: Timelapse) {
  return useMemo(() => getDays(timelapse), [timelapse]);
}

function useDayDate(day: IsoDay) {
  return useMemo(() => parseDay(day), [day]);
}

function fromNow(duration: Duration) {
  const end = dayjs();
  const start = end.subtract(duration);
  return `${start.format('YYYY-MM-DD')}_${end.format('YYYY-MM-DD')}`;
}

function mask(reference: Timelapse, mask: Timelapse) {
  const ref = getInfos(reference);
  const m = getInfos(mask);
  const start = m.startParsed.isAfter(ref.startParsed)
    ? m.startParsed
    : ref.startParsed;
  const end = m.endParsed.isBefore(ref.endParsed) ? m.endParsed : ref.endParsed;
  if (start.isAfter(end)) return null;
  return buildCustom(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
}

function getOverlapRatio(t1: Timelapse, t2: Timelapse) {
  const infos1 = getInfos(t1);
  const infos2 = getInfos(t2);
  const s1 = infos1.startParsed;
  const e1 = infos1.endParsed;
  const s2 = infos2.startParsed;
  const e2 = infos2.endParsed;
  const lastStart = s1.isSameOrAfter(s2) ? s1 : s2;
  const firstEnd = e1.isSameOrBefore(e2) ? e1 : e2;
  if (firstEnd.isBefore(lastStart)) return 0;
  else {
    const firstStart = s1.isSameOrBefore(s2) ? s1 : s2;
    const lastEnd = e1.isSameOrAfter(e2) ? e1 : e2;
    const totalDays = lastEnd.diff(firstStart, 'days') + 1;
    const overlapDays = firstEnd.diff(lastStart, 'days') + 1;
    return overlapDays / totalDays;
  }
}

const dayFormat = 'yyyy-MM-dd';

const Timelapses = {
  getBoundaries,
  parseDay,
  includes,
  stringifyBoundaries,
  getDays,
  useDays,
  dayFormat,
  useDayDate,
  is,
  parse: getInfos,
  getPrevious,
  getNext,
  format: formatT,
  fromNow,
  getType,
  buildCustom,
  getOverlapRatio,
  mask,
};

export default Timelapses;
