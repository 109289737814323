import Model, { TopicPayload } from 'src/utilities/Model';
import useSubmit from 'src/utilities/useSubmit';
import { Required } from '../Field/FieldValidation';
import Form from '../Field/Form';
import useColorField from '../Field/useColorField';
import useTextField from '../Field/useTextField';

type TopicFormProps = {
  topic: Model.Topic | null;
  onSubmit: (topic: TopicPayload) => any;
  onDelete?: () => any;
};

export default function TopicForm(props: TopicFormProps) {
  const { topic, onSubmit, onDelete } = props;

  const labelField = useTextField({
    label: 'Libélle',
    initialValue: topic ? topic.label : null,
    validation: (v) => Required(v),
  });

  const colorField = useColorField({
    label: 'Couleur',
    initialValue: topic ? topic.color : null,
    colors: [
      '#FFCDD2',
      '#F8BBD0',
      '#E1BEE7',
      '#D1C4E9',
      '#C5CAE9',
      '#BBDEFB',
      '#B3E5FC',
      '#B2EBF2',
      '#B2DFDB',
      '#DCEDC8',
      '#F0F4C3',
      '#FFF9C4',
      '#FFECB3',
      '#FFE0B2',
      '#FFCCBC',
      '#D7CCC8',
    ],
  });

  const onSubmitForm = useSubmit(() => {
    const label = labelField.validate();
    const color = colorField.validate();
    onSubmit({
      label,
      color,
    });
  }, []);

  return (
    <Form onSubmit={onSubmitForm} onRemove={onDelete}>
      {labelField.render()}
      {colorField.render()}
    </Form>
  );
}
