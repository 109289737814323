import { AnyError } from './Errors';

type LoadingAsync = { loading: true };
type ResolvedAsync<Data> = { loading: false; ok: true; value: Data };
type RejectedAsync = { loading: false; ok: false; error: AnyError };
export type AsyncValue<Data> =
  | LoadingAsync
  | ResolvedAsync<Data>
  | RejectedAsync;

export type MaybeAsyncValue<Data> = Data | AsyncValue<Data>;

function getValue<TValue>(
  input: AsyncValue<TValue> | null,
  def: TValue,
): TValue {
  if (input === null) return def;
  if (input.loading) return def;
  if (!input.ok) return def;
  return input.value;
}

const AsyncValues = {
  getValue,
  isResolved,
};

export default AsyncValues;

// /**
//  * Système d'état lié à un traitement asynchrone
//  */

// // Générateurs

// function loading (): LoadingAsync {
//   return { loading: true }
// }
// function resolved<TValue> (v: TValue): ResolvedAsync<TValue> {
//   return { loading: false, ok: true, value: v }
// }

// function rejected (error: AnyError): RejectedAsync {
//   return { loading: false, ok: false, error }
// }

// // Type guards

// function isLoading<TValue> (state: Async<TValue>): state is LoadingAsync {
//   return state.loading === true
// }

function isEnded<TValue>(
  state: AsyncValue<TValue>,
): state is ResolvedAsync<TValue> | RejectedAsync {
  return state.loading === false;
}

function isResolved<TValue>(
  state: AsyncValue<TValue>,
): state is ResolvedAsync<TValue> {
  return state.loading === false && state.ok === true;
}

// function isRejected (state: Async<any>): state is RejectedAsync {
//   return state.loading === false && state.ok === false
// }

// /**
//  * Initialise un état pouvant être résolu de manière asyncrhone
//  */
// function useState<TValue> (initialValue?: TValue) {
//   const [state, setState] = useReactState<Async<TValue>>(
//     initialValue ? resolved(initialValue) : loading()
//   )
//   const id = useRef<string>(nanoid())

//   const start = useReactCallback(() => {
//     const loadingId = nanoid()
//     id.current = loadingId
//     setState(loading())

//     const resolve = (v: TValue) => {
//       if (id.current !== loadingId) return
//       setState(resolved(v))
//     }

//     const reject = (err: AnyError) => {
//       if (id.current !== loadingId) return
//       setState(rejected(err))
//     }

//     const listen = (promise: Promise<TValue>) => {
//       promise.then(resolve, reject)
//     }

//     return { resolve, reject, listen }
//   }, [])

//   return [state, start] as const
// }

// /**
//  * @deprecated Prochainement retiré
//  */
// function useEffect<TValue> (
//   method: () => Promise<TValue>,
//   deps: DependencyList = []
// ) {
//   const [state, load] = useState<TValue>()
//   useReactEffect(() => {
//     load().listen(method())
//   }, deps)
//   return [state]
// }

// function useResolveEffect<TValue> (
//   state: Async<TValue>,
//   method: (value: TValue) => any,
//   deps: DependencyList = []
// ) {
//   useReactEffect(() => {
//     if (isResolved(state)) method(state.value)
//   }, [...deps, state])
// }

// function useCallback<TValue> (
//   method: AsyncCallback<TValue>,
//   deps: DependencyList = []
// ) {
//   const [running, setRunning] = useBooleanState(false)
//   const [state, load] = useState<TValue | null>()
//   const cb = useReactCallback(async () => {
//     setRunning.toTrue()
//     const resolver = load()
//     try {
//       const result = await method()
//       resolver.resolve(result)
//     } catch (err) {
//       resolver.reject(err)
//     } finally {
//       setRunning.toFalse()
//     }
//   }, deps)
//   if (running || !state.loading) return [state, cb] as const
//   else return [null, cb] as const
// }

// type StatesValues<TStates extends Array<Async<any>>> = {
//   [Index in keyof TStates]: TStates[Index] extends Async<infer T> ? T : never;
// };

// /**
//  * Fusionne plusieurs AsyncState en un seul (equivalent de Promise.all)
//  */
// function useMergedStates<TStates extends Array<Async<any>>> (
//   ...states: TStates
// ): Async<StatesValues<TStates>> {
//   return useMemo(() => {
//     const loadingState = states.find(isLoading) as LoadingAsync
//     if (loadingState) return loading()
//     const rejectedState = states.find(isRejected) as RejectedAsync
//     if (rejectedState) return rejected(rejectedState.error)
//     const resolvedStates = states.filter(isResolved)
//     const values = resolvedStates.map((s) => s.value) as StatesValues<TStates>
//     return resolved(values)
//   }, states)
// }

// /**
//  * Assure la création d'un AsyncState à partir d'une valeur donnée
//  */
// function useWrapper<TValue> (input: MaybeAsync<TValue>): Async<TValue> {
//   if (typeof input === 'object' && 'loading' in input) return input
//   else return resolved(input)
// }

// /**
//  * Créé à un AsyncState dérivé
//  */
// function useDerivedState<TValue, TSelected> (
//   state: Async<TValue>,
//   selector: (s: TValue) => TSelected,
//   deps: DependencyList = []
// ): Async<TSelected> {
//   return useMemo(() => {
//     if (isResolved(state)) return resolved(selector(state.value))
//     else return state
//   }, [state, ...deps])
// }

// /**
//  * @deprecated Utiliser useDerivedState à la place
//  */
// const useSelector = useDerivedState

// /**
//  * Extrait le contenu d'un AsyncState résolu (rejete une erreur si l'état n'est pas résolu)
//  */
// function value<TData> (state: Async<TData>): TData {
//   if (isResolved(state)) return state.value
//   else throw new Error('State is not resolved')
// }

// /**
//  * Traduit les appels asynchrones en systèmes d'état
//  */
// const AsyncState = {
//   loading,
//   resolved,
//   rejected,
//   isEnded,
//   isResolved,
//   isRejected,
//   useState,
//   useCallback,
//   useMergedStates,
//   useEffect,
//   useWrapper,
//   useDerivedState,
//   useSelector,
//   useResolveEffect,
//   value
// }

// export default AsyncState
