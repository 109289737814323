/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { saveAs } from 'file-saver';
import { rgba } from 'polished';
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from 'src/components/Button';
import Services from 'src/utilities/Services';
import PrintBadge from './PrintBadge';
import PrintCenter from './PrintCenter';
import PrintContact from './PrintContact';
import PrintDivider from './PrintDivider';
import PrintHeader from './PrintHeader';
import PrintInfos, { PrintInfo } from './PrintInfos';
import PrintLines from './PrintLines';
import PrintLink from './PrintLink';
import PrintRight from './PrintRight';
import PrintSignature from './PrintSignature';
import PrintSpacer from './PrintSpacer';
import PrintTable from './PrintTable';
import PrintTitle from './PrintTitle';

const pageSizes = {
  A4: { width: '21cm', height: '29.7cm' },
};

type PrintProps = {
  children: ReactNode;
  size?: keyof typeof pageSizes;
  landscape?: boolean;
  margin?: string;
};

export default function Print(props: PrintProps) {
  const { children, size = 'A4', landscape = false, margin = '2cm' } = props;

  const { repository } = Services.use();
  const [innerHtml, setInnerHtml] = useState<string | null>(null);

  const html = useMemo(() => {
    const basicStyles = `
        html {
          font-family : Outfit ;
          font-size : 0.3cm ;
          color : black ;
        }
      `;

    return `<!DOCTYPE html>
    <html>
    <head>
      <style>
      @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Sofia&display=swap');
      ${basicStyles}
      ${PrintLines.style}
      ${PrintTable.style}
      ${PrintRight.style}
      ${PrintCenter.style}
      ${PrintTitle.style}
      ${PrintSpacer.style}
      ${PrintLink.style}
      ${PrintHeader.style}
      ${PrintContact.style}
      ${PrintInfos.style}
      ${PrintDivider.style}
      ${PrintBadge.style}
      ${PrintSignature.style}
      </style>
      </head>
    <body>${innerHtml}</body>
    </html>`;
  }, [innerHtml, size, landscape]);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    setInnerHtml(ref.current.innerHTML);
  }, []);

  const url = useMemo(() => {
    if (html === null) return null;
    var uint8array = new TextEncoder().encode(html);
    const dataUrl = URL.createObjectURL(
      new Blob([uint8array], { type: 'text/html' }),
    );
    return dataUrl;
  }, [html]);

  const download = useCallback(async () => {
    const response = await repository.axios.post(
      `/pdf`,
      {
        html,
        width: pageSizes[size].width,
        height: pageSizes[size].height,
        landscape,
        margin,
      },
      { responseType: 'blob' },
    );
    saveAs(response.data);
  }, [html, size, landscape, margin]);

  const pageCss = css({
    width: landscape ? pageSizes[size].height : pageSizes[size].width,
    height: landscape ? pageSizes[size].width : pageSizes[size].height,
    boxShadow: `0px 0px 100px ${rgba('black', 0.5)}`,
    background: 'white',
    margin: 'auto',
    display: 'block',
    marginBlock: 100,
    padding: margin,
  });

  return (
    <Fragment>
      <div css={css({ display: 'none' })} ref={ref}>
        {props.children}
      </div>
      {url ? (
        <Fragment>
          <iframe src={url} css={pageCss} title="Preview" />
          <Button onClick={download} label="Télécharger" />
        </Fragment>
      ) : null}
    </Fragment>
  );
}

Print.Header = PrintHeader;
Print.Table = PrintTable;
Print.Right = PrintRight;
Print.Center = PrintCenter;
Print.Lines = PrintLines;
Print.Title = PrintTitle;
Print.Spacer = PrintSpacer;
Print.Link = PrintLink;
Print.Contact = PrintContact;
Print.Infos = PrintInfos;
Print.Info = PrintInfo;
Print.Divider = PrintDivider;
Print.Badge = PrintBadge;
Print.Signature = PrintSignature;
