import Model, { ProjectPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import Button from '../Button';
import { Required } from '../Field/FieldValidation';
import Form from '../Field/Form';
import useFileField from '../Field/useFileField';
import useNumberField from '../Field/useNumberField';
import useTextField from '../Field/useTextField';

type ProjectFormProps = {
  project: Model.Project | null;
  onSubmit: (project: ProjectPayload) => any;
  onDelete?: () => any;
};

export default function ProjectForm(props: ProjectFormProps) {
  const { project, onSubmit, onDelete } = props;
  const { repository } = Services.use();

  const nameField = useTextField({
    label: 'Nom',
    initialValue: project ? project.name : null,
    validation: (v) => Required(v),
  });

  const hoursPerDayField = useNumberField({
    label: 'Heures / jour (par défaut)',
    initialValue: project ? project.default_hours_per_day : null,
    unit: (v) => (v > 1 ? ' heures par jour' : 'heure par jour'),
    decimals: 1,
    validation: (v) => Required(v),
  });

  const dailyPriceField = useNumberField({
    label: 'Taux journalier (par défaut)',
    initialValue: project ? project.default_daily_price : null,
    unit: (v) => ' € par jour',
    validation: (v) => Required(v),
  });

  const logoField = useFileField({
    label: 'Logo',
    initialValue: project ? project.icon : null,
    getUrl: (slot) => repository.createProjectLogo(slot),
    validation: (p) => Required(p),
  });

  const timesheetNotesField = useTextField({
    label: 'Notes apparaissant sur les timehseets',
    initialValue: project ? project.timesheet_notes : null,
  });

  const onSubmitForm = useSubmit(() => {
    const name = nameField.validate();
    const hoursPerDay = hoursPerDayField.validate();
    const logo = logoField.validate();
    const dailyPrice = dailyPriceField.validate();
    const timesheetNode = timesheetNotesField.validate();
    onSubmit({
      name,
      default_hours_per_day: hoursPerDay,
      default_daily_price: dailyPrice,
      icon: logo,
      timesheet_notes: timesheetNode,
    });
  }, []);

  return (
    <Form onSubmit={onSubmitForm}>
      {nameField.render()}
      {logoField.render()}
      {dailyPriceField.render()}
      {hoursPerDayField.render()}
      {timesheetNotesField.render()}
      <Button submit>Valider</Button>
      {onDelete ? <Button onClick={onDelete}>Supprimer</Button> : null}
    </Form>
  );
}
