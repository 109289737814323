/** @jsxImportSource @emotion/react */
import React, { ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';

export type Reaction =
  | { submit: true; form?: string }
  | { onClick: () => any }
  | { href: string; target?: string }
  | { to: string }
  | {};

export type ClickableProps = {
  className?: string;
  children?: ReactNode;
} & Reaction;

export function doesReact(reaction: Reaction) {
  return (
    'submit' in reaction ||
    'onClick' in reaction ||
    'href' in reaction ||
    'to' in reaction
  );
}

export default function Clickable(props: ClickableProps) {
  const { className } = props;

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if ('onClick' in props) props.onClick();
    },
    ['onClick' in props ? props.onClick : null],
  );

  if ('to' in props) {
    return (
      <Link to={props.to} className={className}>
        {props.children}
      </Link>
    );
  } else if ('href' in props) {
    return (
      <a href={props.href} target={props.target} className={className}>
        {props.children}
      </a>
    );
  } else if ('submit' in props) {
    return (
      <button
        type="submit"
        form={props.form}
        className={className}
        onClick={onClick}>
        {props.children}
      </button>
    );
  } else {
    return (
      <button type={'button'} className={className} onClick={onClick}>
        {props.children}
      </button>
    );
  }
}
