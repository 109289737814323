import { rgba } from 'polished';

function useTheme() {
  return { color: '#004D40', alpha: 0.1 };
}

function useColor(alpha: number) {
  return rgba('#004D40', 0.1 * alpha);
}

function useMainColor() {
  return '#004D40';
}

function useNeutralColor() {
  return '#000000';
}

const Theme = { use: useTheme, useColor, useMainColor, useNeutralColor };

export default Theme;
