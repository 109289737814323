/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from 'react';

export default function Right(props: PropsWithChildren<{}>) {
  return <div className="right">{props.children}</div>;
}

Right.style = `
    .right{
      text-align : right ;
    }
  `;
