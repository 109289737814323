/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { rgba } from 'polished';
import { useCallback } from 'react';
import { ColorResult } from 'react-color';
import Css from 'src/utilities/Css';
import { Dropdown } from 'src/utilities/Dropdown';
import useMemoState from 'src/utilities/useMemoState';
import Clickable from '../Clickable';
import FieldContainer from './FieldContainer';
import FieldDropdown from './FieldDropdown';
import FieldInput from './FieldInput';
import fieldStyles from './fieldStyles';
import { useGetFn, useValidateFn, ValidationFn } from './FieldValidation';

type ColorFieldConfig<TValidated extends string | null> = {
  initialValue: string | null | undefined;
  label: string;
  colors?: Array<string>;
  validation?: ValidationFn<string | null, TValidated>;
};

export default function useColorField<TValidated extends string | null>(
  config: ColorFieldConfig<TValidated>,
) {
  const { initialValue, label, colors, validation } = config;

  const [value, setValue] = useMemoState(() => {
    if (initialValue === undefined) return null;
    else if (initialValue === null) return null;
    const regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
    const match = regex.exec(initialValue);
    if (match === null) return null;
    else return initialValue;
  }, [initialValue]);

  const [dropdown, showDropdown, hideDropdown] = Dropdown.useSpeaker();

  const onChange = useCallback((color: ColorResult) => {
    setValue(color.hex);
    hideDropdown();
  }, []);

  const onChangeColor = useCallback((color: string | null) => {
    setValue(color);
    hideDropdown();
  }, []);

  const colorsCss = css({
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  });

  const colorCss = useCallback((c: string) => {
    return css(Css.buttonReset, {
      background: c,
      width: 28,
      height: 28,
      border: `1px solid ${rgba('black', 0.5)}`,
      margin: 5,
      borderRadius: 14,
      transition: 'box-shadow 100ms',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: `0px 0px 3px 3px ${c}`,
      },
    });
  }, []);

  const emptyColorCss = css(Css.buttonReset, {
    background: rgba('black', 0),
    width: 28,
    height: 28,
    border: `1px dashed ${rgba('black', 0.5)}`,
    margin: 5,
    borderRadius: 14,
    transition: 'background 100ms',
    cursor: 'pointer',
    '&:hover': {
      background: rgba('black', 0.1),
    },
  });

  const inputCss = css(fieldStyles.inputCss, {
    color: value ? value : undefined,
  });

  const render = () => (
    <FieldContainer label={label} stopPropagation>
      <FieldInput
        type="text"
        css={inputCss}
        value={value ? `⬤ ${value}` : ''}
        readOnly
        onFocus={showDropdown}
      />
      <FieldDropdown visible={dropdown}>
        <div css={colorsCss}>
          {colors
            ? colors.map((c) => (
                <Clickable
                  key={c}
                  onClick={() => onChangeColor(c)}
                  css={colorCss(c)}
                />
              ))
            : []}
          {
            <Clickable
              onClick={() => onChangeColor(null)}
              css={emptyColorCss}
            />
          }
        </div>
      </FieldDropdown>
      {dropdown}
    </FieldContainer>
  );

  const get = useGetFn(value);
  const validate = useValidateFn(value, validation);

  return { get, set: setValue, render, validate };
}
