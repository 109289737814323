/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Icon from '@mdi/react';
import { rgba } from 'polished';
import { PropsWithChildren } from 'react';
import Css from 'src/utilities/Css';
import Clickable, { Reaction } from '../Clickable';
import Spacer from '../Spacer';
import Typo from '../Typo';

type MenuProps = PropsWithChildren<{}>;

export default function Menu(props: MenuProps) {
  const { children } = props;

  const containerCss = css({
    marginInline: 6,
  });

  const listCss = css({
    padding: 0,
    border: `1px solid ${rgba('black', 0.25)}`,
    borderRadius: 4,
    background: rgba('black', 0.02),
    display: 'flex',
    flexDirection: 'column',
  });

  return (
    <div css={containerCss}>
      <div css={listCss}>{children}</div>
    </div>
  );
}

Menu.Item = MenuItem;

type MenuItemProps = PropsWithChildren<
  {
    icon: string;
    label: string;
  } & Reaction
>;

function MenuItem(props: MenuItemProps) {
  const { icon, label, ...reaction } = props;

  const containerCss = css(Css.buttonReset, {
    display: 'flex',
    alignItems: 'center',
    paddingInline: 12,
    paddingBlock: 8,
  });
  return (
    <Clickable {...reaction} css={containerCss}>
      <Icon path={icon} size="24px" />
      <Spacer />
      <Typo>{label}</Typo>
    </Clickable>
  );
}
