import Updater from './Updater';

const storageKey = 'timesheet-token';

export default class Me {
  private token: string | null = null;
  private updater = new Updater();

  constructor() {
    this.init();
  }

  init() {
    const stored = localStorage.getItem(storageKey);
    this.setToken(stored);
  }

  setToken(token: string | null) {
    this.token = token;
    if (token) localStorage.setItem(storageKey, token);
    else localStorage.removeItem(storageKey);
    this.updater.update();
  }

  getToken() {
    return this.token;
  }

  useHasToken() {
    return this.updater.useValue(() => !!this.token);
  }
}
