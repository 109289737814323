/** @jsxImportSource @emotion/react */
import dayjs from 'dayjs';
import { groupBy, sum } from 'lodash';
import { Fragment, useMemo } from 'react';
import DelayedView from 'src/components/DelayedView';
import Print from 'src/components/Print';
import AppTimelapse from 'src/utilities/AppTimelapse';
import DataSet from 'src/utilities/DataSet';
import Currency from 'src/utilities/Format';
import Services from 'src/utilities/Services';
import Timelapses from 'src/utilities/Timelapses';

export default function MovesExport() {
  const { repository } = Services.use();

  const timelapse = AppTimelapse.use();

  const movesPromise = repository.useData(
    () => repository.getMoves(timelapse),
    [timelapse],
  );

  const vehiclesPromise = repository.useData(async () => {
    return repository.getVehicles();
  }, []);

  const tables = useMemo(async () => {
    const moves = await movesPromise;
    const vehicles = await vehiclesPromise;

    const movesPerVehicle = groupBy(moves, (m) => m.ref_vehicle);

    const pairs = Object.values(movesPerVehicle).map((moves) => {
      const vehicleRef = moves[0].ref_vehicle;
      const vehicle = DataSet.find(vehicles, vehicleRef);
      const lines = moves.map((move) => {
        return {
          Date: dayjs(move.date).format('LL'),
          Déplacement: `${move.from} ➜ ${move.to}`,
          Parcours: `${Currency.kilometers(move.distance)}${
            move.round_trip ? ' A/R' : ''
          }`,
          Véhicule: `${vehicle?.label}`,
          Barème: move.grid,
          Distance: (
            <Print.Right>
              {Currency.kilometers(move.distance * (move.round_trip ? 2 : 1))}
            </Print.Right>
          ),
        };
      });

      const totalKms = sum(
        moves.map((move) => {
          const distance = move.distance * (move.round_trip ? 2 : 1);
          return distance;
        }),
      );
      const footer = {
        Distance: <Print.Right>{Currency.kilometers(totalKms)}</Print.Right>,
      };
      return [vehicle, lines, footer] as const;
    });

    return pairs;
  }, [movesPromise, vehiclesPromise]);

  // const movesTable = useMemo(async () => {
  //   const [mvs, vhcs] = await Promise.all([moves, vehicles]);
  //   const sortedMoves = sortBy(mvs, (m) => dayjs(m.date).valueOf());
  //   return sortedMoves.map((move) => {
  //     const vehicle = vhcs.find((v) => v.id === move.ref_vehicle);
  //     if (!vehicle) throw new Error('No vehicle');
  //     return {
  //       Date: dayjs(move.date).format('LL'),
  //       Déplacement: `${move.from} ➜ ${move.to}`,
  //       Parcours: `${Currency.kilometers(move.distance)}${
  //         move.round_trip ? ' A/R' : ''
  //       }`,
  //       Véhicule: `${vehicle?.label}`,
  //       Barème: move.grid,
  //       Distance: (
  //         <Print.Right>
  //           {Currency.kilometers(move.distance * (move.round_trip ? 2 : 1))}
  //         </Print.Right>
  //       ),
  //     };
  //   });
  // }, [moves, vehicles]);

  // const vehiclesTable = useMemo(async () => {
  //   const [mvs, vhcs] = await Promise.all([moves, vehicles]);
  //   const concernedVehicles = uniq(mvs.map((m) => m.ref_vehicle)).map((id) => {
  //     const vehicle = vhcs.find((v) => v.id === id);
  //     if (!vehicle) throw new Error('No vehicle');
  //     return vehicle;
  //   });

  //   return concernedVehicles.map((v) => {

  //     return {
  //       Vehicule: v.label,
  //       'Type de véhicule': category,
  //       'Carte grise': (
  //         <Print.Link href={v.registrationDocument || 'https://www.google.com'}>
  //           Carte grise
  //         </Print.Link>
  //       ),
  //     };
  //   });
  // }, [moves, vehicles]);

  // const total = useMemo(async () => {
  //   return sumBy(await moves, (m) => {
  //     const km = m.distance * (m.round_trip ? 2 : 1) ;
  //     // const value = All
  //   });
  // }, []);

  // const allTables = useMemo(
  //   () => Promise.all([movesTable, vehiclesTable, total]),
  //   [],
  // );

  return (
    <DelayedView promise={tables}>
      {(tables) => (
        <Print landscape margin="1cm">
          <Print.Header
            mainTitle={Timelapses.format(timelapse, false)}
            preTitle="Déplacements"
          />
          <Print.Spacer scale={2} />
          {tables.map(([vehicle, data, footer]) => {
            let category: string;
            if (vehicle.category.type === 'car')
              category = `Voiture (${vehicle.category.horsepower}CV)`;
            else if (vehicle.category.type === 'motorcycle')
              category = `Moto (${vehicle.category.horsepower}CV)`;
            else category = `Scooter`;

            return (
              <Fragment>
                <Print.Title>{`${vehicle.label} - ${category}`}</Print.Title>
                <Print.Table
                  data={data}
                  headers={{ Indemnité: <Print.Right>Indemnité</Print.Right> }}
                  footers={footer}
                />
                <Print.Spacer scale={2} />
              </Fragment>
            );
          })}
        </Print>
      )}
    </DelayedView>
  );
}
