import * as fns from 'date-fns';
import fr from 'date-fns/locale/fr';

export type IsoYear = string; // "yyyy"
export type IsoMonth = string; // "yyyy-MM"
export type IsoDay = string; // "yyyy-MM-DD"

function parse(date: string, format: string) {
  return fns.parse(date, format, new Date());
}

function parseDay(day: string): Date {
  const parsed = parse(day, 'yyyy-MM-dd');
  return parsed;
}

function format(date: Date, format: string) {
  return fns.format(date, format, { locale: fr });
}

const Time = { ...fns, format, parse, parseDay };

export default Time;
