/** @jsxImportSource @emotion/react */

import dayjs from 'dayjs';

// Table

type SignatureProps = {};

export default function Signature(props: SignatureProps) {
  return (
    <div id="signature">
      <div className="content">
        <div className="date">Le {dayjs().format('LL')}</div>
        <div className="frame"></div>
      </div>
    </div>
  );
}

Signature.style = `
    #signature{
      display : flex ;
      justify-content : flex-end ;
    }

    #signature .content{
      width : 6cm ;
    }
    #signature .frame{
      height : 3cm ;
      border : 1px solid rgba(0,0,0,0.1) ;
    }
  `;
