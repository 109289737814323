/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { groupBy } from 'lodash';
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import DelayedView from 'src/components/DelayedView';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Model from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import { IsoDay } from 'src/utilities/Time';
import Timelapses from 'src/utilities/Timelapses';
import { useAllPromises } from 'src/utilities/useAllPromises';
import DayCell from './Cells/DayCell';
import DayPerf from './Cells/DayPerf';
import EmptyBottomLeftCell from './Cells/EmptyBottomLeftCell';
import EmptyTopRightCell from './Cells/EmptyTopRightCell';
import ProjectCell from './Cells/ProjectCell';
import ProjectPerfCell from './Cells/ProjectPerfCell';
import TimelapseCell from './Cells/TimelapseCell';
import TotalCell from './Cells/TotalCell';
import WorkCell from './Cells/WorkCell';
import Editor from './Editor';
import TasksService, {
  TimesheetTableControllerContext,
  useTimesheetTableController,
} from './TimesheetTableController';

export default function TimesheetTableZone() {
  const { api } = Services.use();
  const timelapse = AppTimelapse.use();

  const controller = useMemo(async () => {
    const controller = new TasksService(api, timelapse);
    await controller.init();
    return controller;
  }, []);

  return (
    <DelayedView promise={controller}>
      {(controller) => (
        <TimesheetTableControllerContext.Provider value={controller}>
          <TimesheetTable />
        </TimesheetTableControllerContext.Provider>
      )}
    </DelayedView>
  );
}

function TimesheetTable() {
  const { repository } = Services.use();

  const controller = useTimesheetTableController();

  const timelapse = AppTimelapse.use();
  const clients = repository.useData(() => repository.getClients(), []);
  const projects = repository.useData(() => repository.getProjects(), []);
  const topics = repository.useData(() => repository.getTopics(), []);
  const extras = repository.useData(
    () => repository.getExtras({ timelapse }),
    [timelapse],
  );

  const all = useAllPromises(clients, projects, topics, extras);

  return (
    <DelayedView promise={all}>
      {([clients, projects, topics, extras]) => (
        <TypingGrid
          clients={clients}
          projects={projects}
          topics={topics}
          extras={extras}
        />
      )}
    </DelayedView>
  );
}

export type Edited = { project: Model.Project; day: IsoDay };

type TypingGridProps = {
  clients: Array<Model.Client>;
  projects: Array<Model.Project>;
  topics: Array<Model.Topic>;
  extras: Array<Model.Extra>;
};

function TypingGrid(props: TypingGridProps) {
  const { clients, projects, topics, extras } = props;
  const controller = useTimesheetTableController();
  const timelapse = AppTimelapse.use();
  const days = Timelapses.useDays(timelapse);

  const tasks = controller.useTasks();

  const containerCss = css({
    padding: 20,
    height: '100vh',
    width: '100%',
    display: 'flex',
  });

  const gridCss = css({
    height: '100%',
    border: `1px solid black`,
    overflow: 'hidden',
    borderRadius: 4,
    flex: 1,
    flexShrink: 1,
    minWidth: 1,
  });

  const editorCss = css({
    height: '100%',
    border: `1px solid black`,
    borderRadius: 4,
    flexShrink: 0,
    minWidth: 1,
    marginLeft: 20,
    background: 'white',
    overflow: 'auto',
  });

  const perCellTasks = useMemo(() => {
    return groupBy(tasks, (t) => `${t.ref_project}-${t.day}`);
  }, [tasks]);

  const perCellExtras = useMemo(() => {
    return groupBy(extras, (t) => `${t.ref_project}-${t.day}`);
  }, [extras]);

  const perDayTasks = useMemo(() => {
    return groupBy(tasks, (t) => t.day);
  }, [tasks]);

  const perDayExtras = useMemo(() => {
    return groupBy(extras, (t) => t.day);
  }, [extras]);

  const perProjectTasks = useMemo(() => {
    return groupBy(tasks, (t) => t.ref_project);
  }, [tasks]);

  const perProjectExtras = useMemo(() => {
    return groupBy(extras, (t) => t.ref_project);
  }, [extras]);

  const perProjectTopics = useMemo(() => {
    return groupBy(topics, (t) => t.ref_project);
  }, [topics]);

  const [edited, setEdited] = useState<Edited | null>(null);

  const renderCell = (day: IsoDay, project: Model.Project) => {
    return (
      <WorkCell
        key={day + project.id}
        day={day}
        project={project}
        topics={topics}
        tasks={perCellTasks[`${project.id}-${day}`] || []}
        extras={perCellExtras[`${project.id}-${day}`] || []}
        onClick={() => setEdited({ project, day })}
      />
    );
  };

  const renderHeader = (project: Model.Project) => {
    const client = clients.find((c) => project.ref_client === c.id);
    if (!client) throw new Error('No client');
    return <ProjectCell client={client} project={project} key={project.id} />;
  };

  const renderFooter = (project: Model.Project) => {
    return (
      <ProjectPerfCell
        project={project}
        key={`footer-${project.id}`}
        tasks={perProjectTasks[`${project.id}`] || []}
        extras={perProjectExtras[`${project.id}`] || []}
      />
    );
  };

  const renderLefter = (day: IsoDay) => {
    return <DayCell day={day} key={day} />;
  };

  const renderRighter = (day: IsoDay) => {
    return (
      <DayPerf
        day={day}
        key={`righter${day}`}
        tasks={perDayTasks[`${day}`] || []}
        extras={perDayExtras[`${day}`] || []}
        projects={projects}
      />
    );
  };

  return (
    <div css={containerCss}>
      <div css={gridCss}>
        <Grid2
          rows={days}
          columns={projects}
          renderCell={renderCell}
          renderHeader={renderHeader}
          renderFooter={renderFooter}
          renderLefter={renderLefter}
          renderRighter={renderRighter}
          renderTopLeftCell={() => <TimelapseCell key={'timelapse'} />}
          renderBottomRightCell={() => (
            <TotalCell
              key="total"
              tasks={tasks}
              extras={extras}
              projects={projects}
            />
          )}
          renderTopRightCell={() => <EmptyTopRightCell />}
          renderBottomLeftCell={() => <EmptyBottomLeftCell />}
          lefterWidth="100px"
          righterWidth="100px"
          getColumnWidth={(c) => (c.width ? `${c.width}px` : null)}
        />
      </div>
      {edited ? (
        <div css={editorCss}>
          <Editor
            key={edited.project.id + edited.day}
            project={edited.project}
            day={edited.day}
            extras={perCellExtras[`${edited.project.id}-${edited.day}`]}
            topics={perProjectTopics[edited.project.id]}
            onClose={() => setEdited(null)}
          />
        </div>
      ) : null}
    </div>
  );
}

type Grid2Props<R, C> = {
  rows: Array<R>;
  columns: Array<C>;
  renderCell: (row: R, column: C) => ReactElement;
  renderHeader?: (column: C) => ReactElement;
  renderFooter?: (column: C) => ReactElement;
  renderLefter?: (row: R) => ReactElement;
  renderRighter?: (row: R) => ReactElement;
  renderTopLeftCell?: () => ReactElement;
  renderTopRightCell?: () => ReactElement;
  renderBottomLeftCell?: () => ReactElement;
  renderBottomRightCell?: () => ReactElement;
  lefterWidth?: string;
  righterWidth?: string;
  getColumnWidth?: (column: C) => string | null;
};

function Grid2<R, C>(props: Grid2Props<R, C>) {
  const {
    rows,
    columns,
    renderCell,
    renderHeader,
    renderFooter,
    renderLefter,
    renderRighter,
    renderTopLeftCell,
    renderTopRightCell,
    renderBottomLeftCell,
    renderBottomRightCell,
    getColumnWidth,
    lefterWidth,
    righterWidth,
  } = props;

  const columnsNumber =
    columns.length + (renderLefter ? 1 : 0) + (renderRighter ? 1 : 0);

  const templaceColumns = useMemo(() => {
    const output: Array<string> = [];
    if (renderLefter) output.push(lefterWidth || '1fr');
    columns.forEach((c) => {
      const width = getColumnWidth ? getColumnWidth(c) : null;
      output.push(width || '1fr');
    });
    if (renderRighter) output.push(righterWidth || '1fr');
    return output;
  }, []);
  console.log(templaceColumns.join(' '));

  let gridCss = css({
    display: 'grid',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    gridTemplateColumns: templaceColumns.join(' '),
    gridTemplateRows: Array(rows.length).fill('auto').join(' '),
    '& > *': {
      zIndex: 1,
    },
  });

  if (renderHeader) {
    gridCss = css(gridCss, {
      [`& > *:nth-of-type(-n+${columnsNumber})`]: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
      },
    });
  }

  if (renderFooter) {
    gridCss = css(gridCss, {
      [`& > *:nth-last-of-type(-n+${columnsNumber})`]: {
        position: 'sticky',
        bottom: 0,
        zIndex: 2,
      },
    });
  }

  if (renderLefter) {
    gridCss = css(gridCss, {
      [`& > *:nth-of-type(${columnsNumber}n+1)`]: {
        position: 'sticky',
        left: 0,
        zIndex: 2,
      },
    });
  }

  if (renderRighter) {
    gridCss = css(gridCss, {
      [`& > *:nth-of-type(${columnsNumber}n+${columnsNumber})`]: {
        position: 'sticky',
        right: 0,
        zIndex: 2,
      },
    });
  }

  if (renderTopLeftCell) {
    gridCss = css(gridCss, {
      [`& > *:nth-of-type(1)`]: {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 3,
      },
    });
  }

  if (renderBottomRightCell) {
    gridCss = css(gridCss, {
      [`& > *:nth-last-of-type(1)`]: {
        position: 'sticky',
        bottom: 0,
        right: 0,
        zIndex: 3,
      },
    });
  }

  if (renderTopRightCell) {
    gridCss = css(gridCss, {
      [`& > *:nth-of-type(${columnsNumber})`]: {
        position: 'sticky',
        top: 0,
        right: 0,
        zIndex: 3,
      },
    });
  }

  if (renderBottomLeftCell) {
    gridCss = css(gridCss, {
      [`& > *:nth-last-of-type(${columnsNumber})`]: {
        position: 'sticky',
        bottom: 0,
        left: 0,
        zIndex: 3,
      },
    });
  }

  let nodes: Array<ReactNode> = [];

  if (renderHeader) {
    // TopLeft cell
    if (renderTopLeftCell) {
      nodes.push(renderTopLeftCell());
    } else if (renderLefter) {
      nodes.push(<div key="TopLeftCell" />);
    }

    columns.forEach((c) => {
      nodes.push(renderHeader(c));
    });

    // TopLeft cell
    if (renderTopRightCell) {
      nodes.push(renderTopRightCell());
    } else if (renderRighter) {
      nodes.push(<div key="TopRightCell" />);
    }
  }

  rows.forEach((row) => {
    if (renderLefter) {
      nodes.push(renderLefter(row));
    }
    columns.forEach((column) => {
      const el = renderCell(row, column);
      nodes.push(el);
    });
    if (renderRighter) {
      nodes.push(renderRighter(row));
    }
  });

  if (renderFooter) {
    // BottomLeft cell
    if (renderBottomLeftCell) {
      nodes.push(renderBottomLeftCell());
    } else if (renderLefter) {
      nodes.push(<div key={'BottomLeftCell'} />);
    }

    columns.forEach((c) => {
      nodes.push(renderFooter(c));
    });

    // BottomLeft cell
    if (renderBottomRightCell) {
      nodes.push(renderBottomRightCell());
    } else if (renderRighter) {
      nodes.push(<div key={'BottomRightCell'} />);
    }
  }

  return <div css={gridCss}>{nodes}</div>;
}
