/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { useCallback } from 'react';
import Clickable from 'src/components/Clickable';
import Typo from 'src/components/Typo';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Css from 'src/utilities/Css';
import overlapColors from 'src/utilities/overlapColors';
import Theme from 'src/utilities/Theme';
import Timelapses from 'src/utilities/Timelapses';

type TimelapseCellProps = {};

export default function TimelapseCell(props: TimelapseCellProps) {
  const timelapse = AppTimelapse.use();

  const containerCss = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: overlapColors('white', Theme.useColor(2)),
    borderBottom: `1px solid ${Theme.useColor(2)}`,
    borderRight: `1px solid ${Theme.useColor(2)}`,
  });

  const goToPrevious = useCallback(() => {
    AppTimelapse.set(Timelapses.getPrevious(timelapse));
  }, [timelapse]);

  const goToNext = useCallback(() => {
    AppTimelapse.set(Timelapses.getNext(timelapse));
  }, [timelapse]);

  return (
    <div css={containerCss}>
      <Clickable css={Css.buttonReset} onClick={goToPrevious}>
        <Icon path={mdiChevronLeft} size={1} />
      </Clickable>
      <Typo>{Timelapses.format(timelapse)}</Typo>
      <Clickable css={Css.buttonReset} onClick={goToNext}>
        <Icon path={mdiChevronRight} size={1} />
      </Clickable>
    </div>
  );
}
