/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { deburr, includes } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import Css from 'src/utilities/Css';
import Defered, { useDefered } from 'src/utilities/Defered';
import { Dropdown } from 'src/utilities/Dropdown';
import Model from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import { IsoDay } from 'src/utilities/Time';
import useBooleanState from 'src/utilities/useBooleanState';
import useMemoState from 'src/utilities/useMemoState';
import { useDebounce } from 'use-debounce';
import Clickable from '../Clickable';
import DelayedView from '../DelayedView';
import ListItem from '../Panel/ListItem';
import FieldContainer from './FieldContainer';
import FieldDropdown from './FieldDropdown';
import FieldInput from './FieldInput';
import { ValidationFn, useValidateFn } from './FieldValidation';
import fieldStyles from './fieldStyles';

type PlaceFieldConfig<TValidated extends string | null> = {
  initialValue: string | null | undefined;
  label: string;
  validation?: ValidationFn<string | null, TValidated>;
  placeholder?: string;
};

export default function usePlaceField<TValidated extends IsoDay | null>(
  config: PlaceFieldConfig<TValidated>,
) {
  const { repository } = Services.use();
  const { initialValue, label, validation, placeholder } = config;

  const [value, setValue] = useMemoState(() => {
    if (initialValue === undefined) return null;
    else if (initialValue === null) return null;
    else return initialValue;
  }, [initialValue]);

  const placesPromise = repository.useData(() => {
    return repository.getPlaces();
  }, []);

  const placePromise = useMemo(async () => {
    if (value === null) return null;
    const places = await placesPromise;
    const place = places.find((o) => o.id === value);
    return place;
  }, [value, placesPromise]);

  const placeDefered = useDefered(placePromise);

  const place = useMemo(() => {
    if (value === null) return null;
    if (!Defered.isResolved(placeDefered)) return undefined;
    const option = placeDefered.value;
    if (option === null) return null;
    if (option === undefined) return undefined;
    if (option.id !== value) return undefined;
    else return option;
  }, [placeDefered, value]);

  const [dropdown, showDropdown, hideDropdown] = Dropdown.useSpeaker();

  const change = useCallback((option: Model.Place | null) => {
    setValue(option ? option.id : null);
    hideDropdown();
  }, []);

  const containerCss = css({
    display: 'flex',
    alignItems: 'center',
  });

  const fieldCss = css({
    flexGrow: 1,
  });

  const [focused, setFocused] = useBooleanState(false);

  const onFocus = useCallback(() => {
    setFocused.toTrue();
    showDropdown();
  }, []);

  const [input, setInput] = useState<string>('');
  const [inputDebounced] = useDebounce(input, 500);

  const googleMapsPlaces = repository.useData(
    () =>
      repository.axios.get<Array<GoogleMapsPlace>>('/google-maps/places', {
        params: { query: inputDebounced },
      }),
    [inputDebounced],
  );

  const displayedPlaces = useMemo(async () => {
    const places = await placesPromise;
    if (!input) return places;
    return places.filter((p) => search(input, p.label));
  }, [placesPromise, input]);

  const onSelectGoogleMapsPlace = useCallback(async (p: GoogleMapsPlace) => {
    const place = await repository.createPlace(p.label, p.id);
    setValue(place.id);
    setInput('');
    hideDropdown();
  }, []);

  const displayedValue = useMemo(() => {
    if (focused) return input;
    if (place === null) return '';
    else if (place === undefined) return '';
    else return place.label;
  }, [place, focused, input]);

  const render = () => (
    <FieldContainer label={label} stopPropagation>
      <div css={containerCss}>
        <div css={fieldCss}>
          <FieldInput
            type="text"
            css={fieldStyles.inputCss}
            value={displayedValue}
            onChange={(e) => setInput(e.target.value)}
            onFocus={onFocus}
            onBlur={setFocused.toFalse}
            placeholder={placeholder}
          />
        </div>
        {value ? (
          <Clickable css={Css.buttonReset} onClick={() => change(null)}>
            <Icon path={mdiClose} size={'20px'} />
          </Clickable>
        ) : null}
      </div>
      <FieldDropdown visible={dropdown} height={300}>
        <DelayedView promise={displayedPlaces}>
          {(options) => {
            return options.map((o) => {
              const key = o.id;
              const node = <ListItem label={o.label} />;
              return (
                <div key={key} onClick={() => change(o)}>
                  {node}
                </div>
              );
            });
          }}
        </DelayedView>
        <DelayedView promise={googleMapsPlaces}>
          {(googleMapsPlaces) => {
            return googleMapsPlaces.map((o) => {
              const key = o.id;
              const node = <ListItem label={o.label} helper={o.address} />;
              return (
                <div key={key} onClick={() => onSelectGoogleMapsPlace(o)}>
                  {node}
                </div>
              );
            });
          }}
        </DelayedView>
      </FieldDropdown>
    </FieldContainer>
  );

  const validate = useValidateFn(value, validation);

  return { value, place, render, validate };
}

export type GoogleMapsPlace = {
  id: string;
  label: string;
  address: string;
};

function purify(s: string) {
  return deburr(s).toUpperCase();
}

function search(search: string, target: string) {
  return includes(purify(target), purify(search));
}

function useNow() {
  const [date, setDate] = useState(() => new Date());
  const render = useCallback(() => setDate(new Date()), []);
  return [date, render] as const;
}
