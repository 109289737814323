import dayjs from 'dayjs';
import Currency from 'src/utilities/Format';
import Model from 'src/utilities/Model';
import ListItem from './Panel/ListItem';

type TransactionListItemProps = {
  transaction: Model.Transaction;
  bankAccount?: Model.BankAccount;
};
export default function TransactionListItem(props: TransactionListItemProps) {
  const { label, amount, operation_date } = props.transaction;
  const bankAccount = props.bankAccount;

  let helper = dayjs(operation_date, 'YYYY-MM-DD', true).format('LL');
  if (bankAccount) helper += ' - ' + bankAccount.label;

  return (
    <ListItem
      label={label}
      rightText={Currency.format(amount)}
      helper={helper}
    />
  );
}
