/** @jsxImportSource @emotion/react */
import { round } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import useMemoState from 'src/utilities/useMemoState';
import FieldContainer from './FieldContainer';
import FieldInput from './FieldInput';
import fieldStyles from './fieldStyles';
import { useValidateFn, ValidationFn } from './FieldValidation';

type NumberFieldConfig<TValidated extends number | null> = {
  initialValue: number | null | undefined;
  label: string | null;
  placeholder?: string;
  decimals?: number;
  unit?: (v: number) => string;
  validation?: ValidationFn<number | null, TValidated>;
  minimum?: number;
  maximum?: number;
};

export default function useNumberField<TValidated extends number | null>(
  config: NumberFieldConfig<TValidated>,
) {
  const {
    initialValue,
    label,
    decimals = 0,
    placeholder,
    unit,
    validation,
    minimum,
    maximum,
  } = config;

  const applyConstrants = useCallback(
    (value: number | null) => {
      if (value === null) return null;
      let out = round(value, decimals);
      if (typeof minimum === 'number' && out < minimum) out = minimum;
      if (typeof maximum === 'number' && out > maximum) out = maximum;
      return out;
    },
    [minimum, decimals],
  );

  const format = useCallback(
    (value: number | null) => {
      if (value === null) return '';
      return value.toFixed(decimals);
    },
    [decimals],
  );

  const [typed, setTyped] = useMemoState<string>(() => {
    if (initialValue === undefined) return '';
    else if (initialValue === null) return '';
    else return format(initialValue);
  }, [initialValue]);

  const [value, setValue] = useMemoState(() => {
    if (initialValue === undefined) return null;
    else if (initialValue === null) return null;
    else return initialValue;
  }, [initialValue]);

  useEffect(() => {
    if (value !== null && minimum !== undefined && value < minimum)
      setValue(minimum);
  }, [value]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      setTyped(v);
      if (v === '') setValue(null);
      const parsed = parseFloat(v);
      if (isNaN(parsed)) {
        setValue(null);
      } else {
        setValue(applyConstrants(parsed));
      }
    },
    [decimals],
  );

  const onBlur = useCallback(() => {
    setTyped(format(value));
  }, [value]);

  const preciser = unit && value !== null ? `${typed} ${unit(value)}` : '';

  const render = () => (
    <FieldContainer label={label}>
      <FieldInput
        type="text"
        css={fieldStyles.inputCss}
        value={typed}
        onChange={onChange}
        onBlur={onBlur}
        preciser={preciser}
        placeholder={placeholder}
      />
    </FieldContainer>
  );

  const set = useCallback((input: number | null) => {
    const value = applyConstrants(input);
    setValue(value);
    setTyped(format(value));
  }, []);
  const validate = useValidateFn(value, validation);

  return { value, set, render, validate };
}
