import dayjs from 'dayjs';
import { Fragment } from 'react';
import { ExtraPayload, TaskPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import { IsoDay } from 'src/utilities/Time';
import { useAllPromises } from 'src/utilities/useAllPromises';
import useRandomKey from 'src/utilities/useRandomKey';
import useSubmit from 'src/utilities/useSubmit';
import DelayedView from '../DelayedView';
import { Required } from '../Field/FieldValidation';
import useDateField from '../Field/useDateField';
import useSelectField from '../Field/useSelectField';
import Panel from '../Panel';
import Spacer from '../Spacer';
import ProjectItem from '../items/ProjectItem';
import DoneForm from './DoneForm';
import ExtraForm from './ExtraForm';
import TaskEditor from './TaskForm';

type ActivityFormProps = {};

export default function ActivityForm(props: ActivityFormProps) {
  const { repository } = Services.use();

  const projects = repository.useData(() => repository.getProjects(), []);

  const projectField = useSelectField({
    label: 'Projet',
    initialValue: null,
    options: projects,
    keyExtractor: (p) => p.id,
    labelExtractor: (p) => p.name,
    itemExtractor: (p) => <ProjectItem project={p} />,
    validation: (p) => Required(p),
  });

  const dayField = useDateField({
    label: 'Jour',
    initialValue: dayjs().format('YYYY-MM-DD'),
    validation: (p) => Required(p),
  });

  const [creationKey, changeCreationKey] = useRandomKey();

  const onSubmitTask = useSubmit(async (t: TaskPayload) => {
    const project = projectField.validate();
    const day = dayField.validate();
    await repository.axios.post(`/tasks`, { ...t, ref_project: project, day });
    repository.invalidate(repository.storage.searchKeys('tasks'));
    changeCreationKey();
  }, []);

  const onSubmitExtra = useSubmit(async (e: ExtraPayload) => {
    const project = projectField.validate();
    const day = dayField.validate();
    await repository.axios.post(`/extras`, { ...e, ref_project: project, day });
    repository.invalidate(repository.storage.searchKeys('extras'));
    changeCreationKey();
  }, []);

  return (
    <Fragment>
      {dayField.render()}
      {projectField.render()}
      <Spacer />
      {projectField.value && dayField.value ? (
        <DoneList project={projectField.value} day={dayField.value} />
      ) : null}
      {projectField.value ? (
        <DoneForm
          key={creationKey}
          project={projectField.value}
          onSubmitTask={onSubmitTask}
          onSubmitExtra={onSubmitExtra}
        />
      ) : null}
    </Fragment>
  );
}

type DoneListProps = {
  project: string;
  day: IsoDay;
};

function DoneList(props: DoneListProps) {
  const { project, day } = props;
  const { repository } = Services.use();
  const tasks = repository.useData(
    () => repository.getTasks({ timelapse: day, projects: [project] }),
    [project, day],
  );
  const extras = repository.useData(
    () => repository.getExtras({ timelapse: day, projects: [project] }),
    [project, day],
  );
  const topics = repository.useData(
    () => repository.getProjectTopics(project),
    [project],
  );
  const promise = useAllPromises(tasks, extras, topics);

  const onSubmitTask = useSubmit(async (id: string, p: TaskPayload) => {
    await repository.axios.patch(`/tasks/${id}`, p);
    repository.invalidate(repository.storage.searchKeys('tasks'));
  }, []);

  const onDeleteTask = useSubmit(
    async (id: string) => {
      await repository.axios.delete(`/tasks/${id}`);
      repository.invalidate(repository.storage.searchKeys('tasks'));
    },
    { confirmation: 'Confirmer ?' },
    [],
  );

  const onSubmitExtra = useSubmit(async (id: string, p: ExtraPayload) => {
    await repository.axios.patch(`/extras/${id}`, p);
    repository.invalidate(repository.storage.searchKeys('extras'));
  }, []);

  const onDeleteExtra = useSubmit(
    async (id: string) => {
      await repository.axios.delete(`/extras/${id}`);
      repository.invalidate(repository.storage.searchKeys('extras'));
    },
    { confirmation: 'Confirmer ?' },
    [],
  );

  return (
    <DelayedView promise={promise}>
      {([tasks, extras, topics]) => {
        return (
          <Fragment>
            <Panel.List
              items={tasks}
              renderItem={(t) => (
                <TaskEditor
                  task={t}
                  onSubmit={(p) => onSubmitTask(t.id, p)}
                  onRemove={() => onDeleteTask(t.id)}
                  contracts={[]}
                  topics={topics}
                />
              )}
              getKey={(t) => t.id}
            />
            <Panel.List
              items={extras}
              renderItem={(e) => (
                <ExtraForm
                  extra={e}
                  onSubmit={(p) => onSubmitExtra(e.id, p)}
                  onRemove={() => onDeleteExtra(e.id)}
                  topics={topics}
                />
              )}
              getKey={(e) => e.id}
            />
          </Fragment>
        );
      }}
    </DelayedView>
  );
}
