/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { rgba } from 'polished';
import Typo from '../Typo';

type PanelIndicatorProps = {
  label: string;
  value: string;
};

export default function PanelIndicator(props: PanelIndicatorProps) {
  const { label, value } = props;

  const containerCss = css({
    marginInline: 6,
    border: `1px solid ${rgba('black', 0.25)}`,
    padding: 12,
    background: rgba('black', 0.02),
    borderRadius: 4,
  });

  return (
    <div css={containerCss}>
      <Typo typo="minor">{label}</Typo>
      <Typo typo="heading">{value}</Typo>
    </div>
  );
}
