/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactNode } from 'react';
import Typos, { TypoName } from '../utilities/Typos';

type TypoProps = {
  children: ReactNode;
  typo?: TypoName;
  center?: boolean;
  opacity?: number;
  oneLine?: boolean;
  inline?: boolean;
};

export default function Typo(props: TypoProps) {
  const {
    center,
    typo = 'body',
    opacity = 1,
    oneLine = false,
    inline = false,
  } = props;

  let containerCss = css`
    ${Typos[typo]}
    ${center ? 'text-align : center ;' : ''}
    opacity : ${opacity};
    white-space: normal;
  `;

  if (oneLine) {
    containerCss = css`
      ${containerCss};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `;
  }

  const Component = inline ? 'span' : 'p';

  return <Component css={containerCss}>{props.children}</Component>;
}
