import Model, { ClientPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import Button from '../Button';
import { Required } from '../Field/FieldValidation';
import Form from '../Field/Form';
import useFileField from '../Field/useFileField';
import useTextField from '../Field/useTextField';

type ClientFormProps = {
  client: Model.Client | null;
  onSubmit: (client: ClientPayload) => any;
  onDelete?: () => any;
};

export default function ClientForm(props: ClientFormProps) {
  const { client, onSubmit, onDelete } = props;
  const { repository } = Services.use();

  const nameField = useTextField({
    label: 'Nom',
    initialValue: client ? client.name : null,
    validation: (v) => Required(v),
  });

  const logoField = useFileField({
    label: 'Logo',
    initialValue: client ? client.icon : null,
    getUrl: (slot) => repository.createClientLogo(slot),
    validation: (p) => Required(p),
  });

  const onSubmitForm = useSubmit(() => {
    const name = nameField.validate();
    const logo = logoField.validate();
    onSubmit({
      name,
      icon: logo,
    });
  }, []);

  return (
    <Form onSubmit={onSubmitForm}>
      {nameField.render()}
      {logoField.render()}
      <Button submit>Valider</Button>
      {onDelete ? <Button onClick={onDelete}>Supprimer</Button> : null}
    </Form>
  );
}
