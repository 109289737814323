/** @jsxImportSource @emotion/react */

import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import DelayedView from 'src/components/DelayedView';
import Panel from 'src/components/Panel';
import ListItem from 'src/components/Panel/ListItem';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Currency from 'src/utilities/Format';
import Services from 'src/utilities/Services';
import { useAllPromises } from 'src/utilities/useAllPromises';

export default function Expenses() {
  const { repository } = Services.use();
  const timelapse = AppTimelapse.use();

  const expenses = repository.useData(() => {
    return repository.getExpenses(timelapse);
  }, [timelapse]);

  const categories = repository.useData(() => {
    return repository.getCategories();
  }, [timelapse]);

  const promise = useAllPromises(expenses, categories);

  return (
    <DelayedView promise={promise}>
      {([expenses, categories]) => {
        console.log(expenses);
        return (
          <Panel.Container>
            <Panel.Header title="Dépenses" />
            {categories.map((cat) => {
              const catExpenses = expenses.filter(
                (e) => e.ref_category === cat.id,
              );
              return (
                <Panel.List
                  items={catExpenses}
                  label={`${cat.emoji} ${cat.label}`}
                  total={Currency.format(sumBy(catExpenses, (e) => e.amount))}
                  renderItem={(e) => (
                    <ListItem
                      label={e.label}
                      rightText={Currency.format(e.amount)}
                      helper={dayjs(e.operation_date).format('LL')}
                    />
                  )}
                  getKey={(e) => e.id}
                />
              );
            })}
          </Panel.Container>
        );
      }}
    </DelayedView>
  );
}
