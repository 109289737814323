/** @jsxImportSource @emotion/react */
import { flatten, uniq } from 'lodash';
import { ReactNode, useMemo } from 'react';

// Table

type Data = {
  [index: string]: ReactNode;
  _background?: string;
};

type TableProps<TData extends Data> = {
  data: Array<TData>;
  headers?: Record<string, ReactNode>;
  footers?: Record<string, ReactNode>;
};

export default function Table<TData extends Data>(props: TableProps<TData>) {
  const { data, headers = {}, footers } = props;

  const columns = useMemo(() => {
    return uniq(flatten(data.map((d) => Object.keys(d)))).filter(
      (c) => !c.startsWith('_'),
    ) as Array<keyof TData & string>;
  }, [data]);

  let footersNode: ReactNode;
  if (footers && Object.keys(footers).length) {
    footersNode = (
      <tfoot>
        <tr>
          {columns.map((c) => (
            <th key={c}>{footers[c] ? footers[c] : ''}</th>
          ))}
        </tr>
      </tfoot>
    );
  }

  return (
    <table>
      <thead>
        <tr>
          {columns.map((c) => (
            <th key={c}>{headers[c] ? headers[c] : c}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((d, i) => {
          return (
            <tr key={i} style={{ backgroundColor: d._background || undefined }}>
              {columns.map((c) => (
                <td key={c}>{d[c]}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
      {footersNode}
    </table>
  );
}

Table.style = `
    table{
      width : 100% ;
      border-collapse : collapse ;
    }
    
  
    thead tr{
      border-bottom : 1px solid rgba(0,0,0,1) ;
      text-align : left ;
    }
    
    tbody tr{
      border-bottom : 1px solid rgba(0,0,0,0.1) ;
    }

    tfoot{
      display : table-row-group ;
    }
  
    th, td{
      padding : 0.2cm ;
      text-align : left ;
    }
  `;
