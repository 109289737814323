/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from 'react';

export default function Center(props: PropsWithChildren<{}>) {
  return <div className="center">{props.children}</div>;
}

Center.style = `
    .center{
      text-align : center ;
    }
  `;
