import { DependencyList, useEffect, useRef, useState } from 'react';

export default class R {
  private callbacks: Array<() => void> = [];

  notify() {
    this.callbacks.forEach((cb) => cb());
  }

  onNotified(cb: () => void): () => void {
    this.callbacks.push(cb);
    return () => {
      const callbacks: Array<() => void> = [];
      this.callbacks.forEach((c) => {
        if (cb !== c) callbacks.push(c);
      });
      this.callbacks = callbacks;
    };
  }

  useSelector<TValue>(
    getter: (current: TValue | undefined) => TValue,
    deps: DependencyList = [],
  ): TValue {
    const [value, setValue] = useState(() => getter(undefined));
    const mounted = useRef(false);
    useEffect(() => {
      if (mounted.current) setValue(getter);
      const cb = () => setValue(getter);
      const unsub = this.onNotified(cb);
      mounted.current = true;
      return () => unsub();
    }, deps);
    return value;
  }

  useReaction(reaction: () => void, deps: DependencyList = []) {
    useEffect(() => {
      const unsub = this.onNotified(reaction);
      return () => unsub();
    }, deps);
  }

  useNow(deps: DependencyList = []) {
    return this.useSelector(() => new Date(), deps);
  }
}
