/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Icon from '@mdi/react';
import { rgba } from 'polished';
import Css from 'src/utilities/Css';
import Clickable, { ClickableProps } from '../Clickable';

type PanelMainActionProps = {
  icon: string;
} & ClickableProps;

export default function PanelMainAction(props: PanelMainActionProps) {
  const { icon, ...clickableProps } = props;

  const containerCss = css(Css.buttonReset, {
    position: 'absolute',
    bottom: 20,
    right: 20,
    width: 56,
    height: 56,
    borderRadius: 56 / 2,
    background: rgba('black', 0.1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  return (
    <Clickable css={containerCss} {...clickableProps}>
      <Icon path={icon} size={'32px'} />
    </Clickable>
  );
}
