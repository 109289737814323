import { useCallback } from 'react';
import ProjectForm from 'src/components/forms/ProjectForm';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Model, { ProjectPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import ProjectEditionPanel from './ProjectEditionPanel';

type ProjectCreationPanelProps = {
  ref_client: string;
};

export default function ProjectCreationPanel(props: ProjectCreationPanelProps) {
  const ref_client = props.ref_client;
  const { repository } = Services.use();
  const panels = usePanels();

  const onSubmit = useCallback(async (project: ProjectPayload) => {
    const p = await repository.axios.post<Model.Project>('/projects', {
      ...project,
      ref_client,
      width: 300,
    });
    repository.invalidate(repository.storage.searchKeys('projects'));
    panels.replace(ProjectEditionPanel, { project: p.data.id });
  }, []);

  return (
    <Panel.Container>
      <Panel.Header title="Nouveau projet" />
      <ProjectForm project={null} onSubmit={onSubmit} />
    </Panel.Container>
  );
}
