import { useMemo, useState } from 'react';
import Model, { ProjectionPayload } from 'src/utilities/Model';
import { DaysPurpose } from 'src/utilities/Purposes';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import { Required } from '../Field/FieldValidation';
import Form from '../Field/Form';
import useSelectField from '../Field/useSelectField';
import useTimelapseField from '../Field/useTimelapseField';
import ProjectItem from '../items/ProjectItem';
import PanelTabs from '../Panel/Tabs';
import DaysPurposeForm from './DaysPurposeForm';
import ExtraPurposeForm from './ExtraPurposeForm';

type ProjectionFormProps = {
  projection: Model.Projection | null;
  onSubmit: (projection: ProjectionPayload) => any;
  onDelete?: () => any;
};

export default function ProjectionForm(props: ProjectionFormProps) {
  const { projection, onSubmit, onDelete } = props;
  const { repository } = Services.use();

  const projects = repository.useData(() => repository.getProjects(), []);

  const timelapseField = useTimelapseField({
    label: 'Période',
    initialValue: projection ? projection.timelapse : null,
    validation: (p) => Required(p),
  });

  const projectField = useSelectField({
    label: 'Projet',
    initialValue: projection ? projection.ref_project : null,
    options: projects,
    keyExtractor: (p) => p.id,
    labelExtractor: (p) => p.name,
    itemExtractor: (p) => <ProjectItem project={p} />,
    validation: (p) => Required(p),
  });

  const tabs = useMemo(
    () =>
      [
        { key: 'days', label: 'Jours' },
        { key: 'extra', label: 'Extra' },
      ] as const,
    [],
  );
  const [purposeType, setPurposeType] = useState<typeof tabs[number]['key']>(
    projection && projection.purpose ? projection.purpose.type : 'days',
  );

  const project = projectField.option;

  const [purpose, setPurpose] = useState<Model.Purpose | null>(null);

  const defaultDaysPurpose = useMemo<Partial<DaysPurpose>>(() => {
    if (!project) return {};
    else
      return {
        hours_per_day: project.default_hours_per_day,
        daily_price: project.default_daily_price,
      };
  }, [project]);

  const onSubmitForm = useSubmit(() => {
    const timelapse = timelapseField.validate();
    const ref_project = projectField.validate();
    if (!purpose) throw new Error('Boum');
    const payload: ProjectionPayload = {
      timelapse,
      ref_project,
      purpose,
    };
    onSubmit(payload);
  }, [purposeType]);

  return (
    <Form onSubmit={onSubmitForm} onRemove={onDelete}>
      {timelapseField.render()}
      {projectField.render()}
      <PanelTabs
        tabs={tabs}
        keyExtractor={(t) => t.key}
        labelExtractor={(t) => t.label}
        value={purposeType}
        onChange={setPurposeType}
      />
      {purposeType === 'days' ? (
        <DaysPurposeForm purpose={defaultDaysPurpose} onSubmit={setPurpose} />
      ) : (
        <ExtraPurposeForm purpose={{}} onSubmit={setPurpose} />
      )}
    </Form>
  );
}
