/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiCheck, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import { nanoid } from 'nanoid';
import React, {
  createContext,
  Fragment,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';
import Css from 'src/utilities/Css';
import Clickable from '../Clickable';
import Spacer from '../Spacer';

type FormProps = PropsWithChildren<{
  hideSubmit?: boolean;
  onSubmit: () => any;
  onRemove?: () => any;
}>;

export default function Form(props: FormProps) {
  const { onSubmit, onRemove, hideSubmit = false, children } = props;
  const id = useMemo(() => `form-${nanoid()}`, []);
  const onSubmitForm = useCallback(
    (e: React.FormEvent) => {
      console.log('SUBMIT');
      e.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  const controlsCss = css({
    display: 'flex',
    paddingInline: 6,
  });

  let submitCss = css(Css.buttonReset, {});
  if (hideSubmit)
    submitCss = css(submitCss, {
      position: 'fixed',
      top: -10000,
      left: -10000,
      opacity: 0.1,
      pointerEvents: 'none',
    });

  return (
    <Fragment>
      <form onSubmit={onSubmitForm} id={id} />
      <FormContext.Provider value={id}>
        {children}
        <div css={controlsCss}>
          {onRemove ? (
            <Clickable onClick={onRemove} css={Css.buttonReset}>
              <Icon path={mdiDelete} size="24px" />
            </Clickable>
          ) : null}
          <Spacer grow />
          <Clickable submit css={submitCss} form={id}>
            <Icon path={mdiCheck} size="24px" />
          </Clickable>
        </div>
      </FormContext.Provider>
    </Fragment>
  );
}

export const FormContext = createContext<string | undefined>(undefined);
