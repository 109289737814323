import { DependencyList, useEffect, useState } from 'react';

export default function useAsyncEffect(
  fn: () => Promise<(() => void) | void>,
  deps: DependencyList,
) {
  const [ready, setReady] = useState<boolean>(false);
  const [onUnmount, setOnUnmount] = useState<(() => void) | void>();

  useEffect(() => {
    fn().then((unmountFn) => {
      setOnUnmount(unmountFn);
      setReady(true);
    });
  }, deps);

  useEffect(() => {
    return () => {
      if (onUnmount) onUnmount();
    };
  }, [onUnmount]);

  return ready;
}
