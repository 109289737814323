import { Fragment, useMemo, useState } from 'react';
import { ExtraPayload, TaskPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import DelayedView from '../DelayedView';
import Panel from '../Panel';
import ExtraForm from './ExtraForm';
import TaskEditor from './TaskForm';

type PurposeFormProps = {
  project: string;
  onSubmitTask: (p: TaskPayload) => any;
  onSubmitExtra: (e: ExtraPayload) => any;
};

export default function PurposeForm(props: PurposeFormProps) {
  const { onSubmitTask, onSubmitExtra, project } = props;

  const { api, repository } = Services.use();
  const topics = repository.useData(
    () => repository.getProjectTopics(project),
    [project],
  );

  const [type, setType] = useState<'task' | 'extra'>('task');

  const tabs = useMemo(() => {
    return [
      { type: 'task' as const, label: 'Temps' },
      { type: 'extra' as const, label: 'Extra' },
    ];
  }, []);

  return (
    <Fragment>
      <Panel.Tabs
        tabs={tabs}
        value={type}
        onChange={setType}
        keyExtractor={(t) => t.type}
        labelExtractor={(t) => t.label}
      />
      <DelayedView promise={topics}>
        {(topics) => {
          return type === 'task' ? (
            <TaskEditor
              task={null}
              onSubmit={onSubmitTask}
              topics={topics}
              contracts={[]}
            />
          ) : (
            <ExtraForm extra={null} onSubmit={onSubmitExtra} topics={topics} />
          );
        }}
      </DelayedView>
    </Fragment>
  );
}
