/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import dayjs from 'dayjs';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Css from 'src/utilities/Css';
import { Dropdown } from 'src/utilities/Dropdown';
import Timelapses, { Timelapse, TimelapseType } from 'src/utilities/Timelapses';
import useMemoState from 'src/utilities/useMemoState';
import Clickable from '../Clickable';
import Typo from '../Typo';
import FieldContainer from './FieldContainer';
import FieldDropdown from './FieldDropdown';
import FieldInput from './FieldInput';
import fieldStyles from './fieldStyles';
import { useValidateFn, ValidationFn } from './FieldValidation';

type TimelapseFieldConfig<TValidated extends string | null> = {
  initialValue: Timelapse | null | undefined;
  label: string | null;
  placeholder?: string;
  validation?: ValidationFn<string | null, TValidated>;
};

export default function useTimelapseField<TValidated extends string | null>(
  config: TimelapseFieldConfig<TValidated>,
) {
  const { initialValue, label, validation, placeholder } = config;

  const [value, setValue] = useMemoState(() => {
    if (initialValue === undefined) return null;
    else if (initialValue === null) return null;
    else if (Timelapses.is(initialValue)) return initialValue;
    else return null;
  }, [initialValue]);

  const info = useMemo(() => (value ? Timelapses.parse(value) : null), [value]);

  const [dropdown, showDropdown, hideDropdown] = Dropdown.useSpeaker();

  const change = useCallback((value: Timelapse | null) => {
    setValue(value);
    hideDropdown();
  }, []);

  const displayedValue = useMemo(() => {
    if (value === null) return '';
    else return Timelapses.format(value);
  }, [value]);

  const containerCss = css({
    display: 'flex',
    alignItems: 'center',
  });

  const fieldCss = css({
    flexGrow: 1,
  });

  const dropdownContainerCss = css({
    display: 'flex',
    width: '100%',
    minWidth: 300,
  });

  const modesCss = css({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  });

  const modeCss = css(Css.buttonReset, {
    padding: 6,
    borderBottom: `1px solid lightgrey`,
    borderRight: `1px solid lightgrey`,
    '&:last-child': {
      borderBottom: 'none',
    },
    background: '#EEEEEE',
  });

  const modeSelectedCss = css(modeCss, {
    background: 'transparent',
    borderRight: `1px solid transparent`,
  });

  const pickerCss = css({
    flex: 1,
  });

  const [mode, setMode] = useState<TimelapseType>('year');

  const onClickDayOnCustomMode = useCallback(
    (d: Date) => {
      if (value === null) {
        setValue(dayjs(d).format('YYYY-MM-DD'));
      } else {
        const info = Timelapses.parse(value);
        if (info.type === 'day') {
          setValue(
            Timelapses.buildCustom(
              info.timelapse,
              dayjs(d).format('YYYY-MM-DD'),
            ),
          );
        } else {
          setValue(dayjs(d).format('YYYY-MM-DD'));
        }
      }
    },
    [value],
  );

  let pickerNode: ReactNode;
  if (mode === 'year') {
    let calendarValue: Date | null = null;
    if (info) {
      if (info.type === 'year') {
        calendarValue = info.startParsed.toDate();
      }
    }
    pickerNode = (
      <Calendar
        maxDetail="decade"
        minDetail="century"
        onClickYear={(v) => setValue(dayjs(v).format('YYYY'))}
        value={calendarValue}
      />
    );
  } else if (mode === 'month') {
    let calendarValue: Date | null = null;
    if (info) {
      if (info.type === 'month') {
        calendarValue = info.startParsed.toDate();
      }
    }
    pickerNode = (
      <Calendar
        maxDetail="year"
        minDetail="century"
        onClickMonth={(v) => setValue(dayjs(v).format('YYYY-MM'))}
        value={calendarValue}
      />
    );
  } else if (mode === 'day') {
    let calendarValue: Date | null = null;
    if (info) {
      if (info.type === 'day') {
        calendarValue = info.parsed.toDate();
      }
    }
    pickerNode = (
      <Calendar
        maxDetail="month"
        minDetail="century"
        onClickDay={(v) => setValue(dayjs(v).format('YYYY-MM-DD'))}
        value={calendarValue}
      />
    );
  } else if (mode === 'custom') {
    let calendarValue: Date | [Date, Date] | null = null;
    if (info) {
      if (info.type === 'day') {
        calendarValue = info.parsed.toDate();
      } else if (info.type === 'month') {
        calendarValue = [info.startParsed.toDate(), info.endParsed.toDate()];
      } else if (info.type === 'year') {
        calendarValue = [info.startParsed.toDate(), info.endParsed.toDate()];
      } else if (info.type === 'custom') {
        calendarValue = [info.startParsed.toDate(), info.endParsed.toDate()];
      }
    }
    pickerNode = (
      <Calendar
        maxDetail="month"
        minDetail="century"
        onClickDay={onClickDayOnCustomMode}
        value={calendarValue}
      />
    );
  }

  const render = () => (
    <FieldContainer label={label} stopPropagation>
      <div css={containerCss}>
        <div css={fieldCss}>
          <FieldInput
            type="text"
            css={fieldStyles.inputCss}
            value={displayedValue}
            readOnly
            onFocus={showDropdown}
            placeholder={placeholder}
          />
        </div>
        {value ? (
          <Clickable css={Css.buttonReset} onClick={() => change(null)}>
            <Icon path={mdiClose} size={'20px'} />
          </Clickable>
        ) : null}
      </div>
      <FieldDropdown visible={dropdown} height={300}>
        <div css={dropdownContainerCss}>
          <div css={modesCss}>
            <Clickable
              css={mode === 'year' ? modeSelectedCss : modeCss}
              onClick={() => setMode('year')}>
              <Typo>Année</Typo>
            </Clickable>
            <Clickable
              css={mode === 'month' ? modeSelectedCss : modeCss}
              onClick={() => setMode('month')}>
              <Typo>Mois</Typo>
            </Clickable>
            <Clickable
              css={mode === 'day' ? modeSelectedCss : modeCss}
              onClick={() => setMode('day')}>
              <Typo>Jour</Typo>
            </Clickable>
            <Clickable
              css={mode === 'custom' ? modeSelectedCss : modeCss}
              onClick={() => setMode('custom')}>
              <Typo>Période</Typo>
            </Clickable>
          </div>
          <div css={pickerCss}>{pickerNode}</div>
        </div>
      </FieldDropdown>
    </FieldContainer>
  );

  const validate = useValidateFn(value, validation);

  return { value, render, validate };
}
