/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { without } from 'lodash';
import { useState } from 'react';
import Panel from 'src/components/Panel';
import Spacer, { SpacerDouble } from 'src/components/Spacer';
import Typo from 'src/components/Typo';
import ExtraForm from 'src/components/forms/ExtraForm';
import TaskEditor from 'src/components/forms/TaskForm';
import DataSet from 'src/utilities/DataSet';
import Model, { ExtraPayload, TaskPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import { IsoDay } from 'src/utilities/Time';
import useRandomKey from 'src/utilities/useRandomKey';
import useSubmit from 'src/utilities/useSubmit';
import { useTimesheetTableController } from './TimesheetTableController';

type EditorFormProps = {
  day: IsoDay;
  project: Model.Project;
  topics: Array<Model.Topic>;
  extras: Array<Model.Extra>;
  onClose: () => any;
};

export default function EditorForm(props: EditorFormProps) {
  const { day, project, topics = [] } = props;
  const controller = useTimesheetTableController();

  const tasks = controller.useTasksByDayAndProject(day, project.id);
  const contracts = controller.useContractsByClient(project.ref_client);

  const { api, repository } = Services.use();
  const [newTaskKey, changeNewTaskKey] = useRandomKey();

  const container = css({
    width: 400,
    background: 'white',
    padding: 6,
  });

  const onCreateTask = useSubmit(
    async (payload: TaskPayload) => {
      const response = await repository.axios.post<Model.Task>('/tasks', {
        day,
        ref_project: project.id,
        ...payload,
      });
      controller.updateTasks([response.data]);
      changeNewTaskKey();
      repository.invalidate(repository.storage.searchKeys('tasks'));
    },
    [day, project.id],
  );

  const onUpdateTask = useSubmit(
    async (task: Model.Task, payload: TaskPayload) => {
      const response = await repository.axios.patch<Model.Task>(
        `/tasks/${task.id}`,
        payload,
      );
      controller.updateTasks([response.data]);
      repository.invalidate(repository.storage.searchKeys('tasks'));
    },
    [],
  );

  const onDeleteTask = useSubmit(
    async (task: Model.Task) => {
      await api.deleteTask(task.id);
      controller.removeTasks([task.id]);
      repository.invalidate(repository.storage.searchKeys('tasks'));
    },
    { confirmation: 'Supprimer la tâche ?' },
    [],
  );

  const [newExtraKey, changeNewExtraKey] = useRandomKey();
  const [extras, setExtras] = useState(() => props.extras || []);

  const onCreateExtra = useSubmit(
    async (payload: ExtraPayload) => {
      const response = await repository.axios.post<Model.Extra>('/extras', {
        day,
        ref_project: project.id,
        ...payload,
      });
      setExtras((t) => [...t, response.data]);
      changeNewExtraKey();
      repository.invalidate(repository.storage.searchKeys('extras'));
    },
    [day, project.id],
  );

  const onUpdateExtra = useSubmit(
    async (task: Model.Extra, payload: ExtraPayload) => {
      const response = await repository.axios.patch<Model.Extra>(
        `/extras/${task.id}`,
        payload,
      );
      setExtras((t) => DataSet.replace(t, response.data));
      repository.invalidate(repository.storage.searchKeys('extras'));
    },
    [],
  );

  const onDeleteExtra = useSubmit(
    async (task: Model.Extra) => {
      await api.deleteExtra(task.id);
      setExtras((t) => without(t, task));
      repository.invalidate(repository.storage.searchKeys('extras'));
    },
    { confirmation: "Supprimer l'extra ?" },
    [],
  );

  return (
    <div css={container}>
      <Spacer />
      <Typo typo="heading" center>
        {project.name}
      </Typo>
      <Typo typo="subheading" center>
        {dayjs(day).format('dddd LL')}
      </Typo>
      <SpacerDouble />
      <Panel.List
        label="Tâches"
        items={tasks}
        renderItem={(task) => (
          <TaskEditor
            task={task}
            topics={topics}
            onSubmit={(payload) => onUpdateTask(task, payload)}
            contracts={contracts}
            onRemove={() => onDeleteTask(task)}
          />
        )}
        getKey={(t) => t.id}
        extraItem={
          <TaskEditor
            key={newTaskKey}
            task={null}
            topics={topics}
            contracts={contracts}
            onSubmit={(payload) => onCreateTask(payload)}
          />
        }
      />
      <SpacerDouble />
      <Panel.List
        label="Extras"
        items={extras}
        renderItem={(extra) => (
          <ExtraForm
            extra={extra}
            topics={topics}
            onSubmit={(payload) => onUpdateExtra(extra, payload)}
            onRemove={() => onDeleteExtra(extra)}
          />
        )}
        getKey={(t) => t.id}
        extraItem={
          <ExtraForm
            key={newExtraKey}
            extra={null}
            topics={topics}
            onSubmit={(payload) => onCreateExtra(payload)}
          />
        }
      />
      <Spacer />
    </div>
  );
}
