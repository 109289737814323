import axios, { AxiosRequestConfig } from 'axios';
import {
  AxiosCacheInstance,
  buildStorage,
  CacheAxiosResponse,
  CacheRequestConfig,
  NotEmptyStorageValue,
  setupCache,
} from 'axios-cache-interceptor';
import mergeConfig from 'axios/lib/core/mergeConfig';
import { intersection } from 'lodash';
import { DependencyList, useEffect, useMemo, useState } from 'react';
import { SlotConfig, UrlPair } from 'src/components/Field/useFileField';
import Me from './Me';
import Model, { InvoicePayload, MovePayload } from './Model';
import { Timelapse } from './Timelapses';
import Updater from './Updater';

export default class Repository {
  readonly axios: AxiosCacheInstance;
  private config: AxiosRequestConfig;
  readonly storage = {
    set: (key: string, value: NotEmptyStorageValue) => {
      this.store[key] = { value: value, tags: [] };
    },
    find: (key: string) => {
      return this.store[key] ? this.store[key]['value'] : undefined;
    },
    remove: (key: string) => {
      delete this.store[key];
    },
    setTags: (key: string, tags: Array<string>) => {
      if (this.store[key]) this.store[key].tags = tags;
    },
    searchKeys: (...tags: Array<string>) => {
      const entries = Object.entries(this.store);
      const out: Array<string> = entries
        .filter(([key, value]) => intersection(value.tags, tags).length >= 1)
        .map(([key]) => key);
      return out;
    },
  };

  private store: Record<
    string,
    { value: NotEmptyStorageValue; tags: Array<string> }
  > = {};

  constructor(private origin: string, readonly me: Me) {
    this.config = { baseURL: this.origin };

    this.axios = setupCache(axios.create(this.config), {
      storage: buildStorage(this.storage),
    });

    this.axios.interceptors.request.use((config) => {
      const token = this.me.getToken();
      if (token) {
        config.headers = { ...config.headers, authorization: token };
      }
      return config;
    });
  }

  getKey(request: CacheRequestConfig): CacheRequestConfig {
    const config = mergeConfig(this.config, request);
    console.log(config);
    return config;
  }

  // Clients

  async getClients() {
    const response = await this.axios.get<Array<Model.Client>>('/clients');
    this.storage.setTags(response.id, ['clients']);
    return response;
  }

  async getClient(id: string) {
    const response = await this.axios.get<Model.Client>(`/clients/${id}`);
    this.storage.setTags(response.id, ['client', id]);
    return response;
  }

  async createClientLogo(slot: SlotConfig) {
    const result = await this.axios.post<UrlPair>(`/client-logos`, slot);
    return result.data;
  }

  // Projects

  async getProjects() {
    const response = await this.axios.get<Array<Model.Project>>('/projects');
    this.storage.setTags(response.id, ['projects']);
    return response;
  }

  async getClientProjects(client: string) {
    const response = await this.axios.get<Array<Model.Project>>(
      `/clients/${client}/projects`,
    );
    this.storage.setTags(response.id, ['projects']);
    return response;
  }

  async getProject(project: string) {
    const response = await this.axios.get<Model.Project>(
      `/projects/${project}`,
    );
    this.storage.setTags(response.id, ['project', project]);
    return response;
  }

  async createProjectLogo(slot: SlotConfig) {
    const result = await this.axios.post<UrlPair>(`/project-logos`, slot);
    return result.data;
  }

  // Contracts

  async getContracts(timelapse: Timelapse) {
    const response = await this.axios.get<Array<Model.Contract>>(`/contracts`, {
      params: { timelapse },
    });
    this.storage.setTags(response.id, ['contracts']);
    return response;
  }

  // async getClientContracts(client: string) {
  //   const response = await this.axios.get<Array<Model.Contract>>(
  //     `/clients/${client}/contracts`,
  //   );
  //   this.storage.setTags(response.id, ['contracts']);
  //   return response;
  // }

  async getContract(id: string) {
    const response = await this.axios.get<Model.Contract>(`/contracts/${id}`);
    this.storage.setTags(response.id, ['contract', id]);
    return response;
  }

  // Tasks

  async getTasks(config: { timelapse?: Timelapse; projects?: Array<string> }) {
    const response = await this.axios.get<Array<Model.Task>>(`/tasks`, {
      params: config,
    });
    this.storage.setTags(response.id, ['tasks']);
    return response;
  }

  // Extras

  async getExtras(config: { timelapse?: Timelapse; projects?: Array<string> }) {
    const response = await this.axios.get<Array<Model.Extra>>(`/extras`, {
      params: config,
    });
    this.storage.setTags(response.id, ['extras']);
    return response;
  }

  // Expenses

  async getExpenses(timelapse: Timelapse) {
    const response = await this.axios.get<Array<Model.Expense>>(`/expenses`, {
      params: { timelapse },
    });
    this.storage.setTags(response.id, ['expenses']);
    return response;
  }

  // Categories

  async getCategories() {
    const response = await this.axios.get<Array<Model.Category>>(`/categories`);
    this.storage.setTags(response.id, ['categories']);
    return response;
  }

  // Projections

  async getProjections(timelapse: Timelapse) {
    const response = await this.axios.get<Array<Model.Projection>>(
      `/projections`,
      {
        params: { timelapse },
      },
    );
    this.storage.setTags(response.id, ['projections']);
    return response;
  }

  async getProjection(projection: string) {
    const response = await this.axios.get<Model.Projection>(
      `/projections/${projection}`,
    );
    this.storage.setTags(response.id, ['projection', projection]);
    return response;
  }

  // Topics

  async getTopics() {
    const response = await this.axios.get<Array<Model.Topic>>(`/topics`);
    this.storage.setTags(response.id, ['topics']);
    return response;
  }

  async getProjectTopics(project: string) {
    const response = await this.axios.get<Array<Model.Topic>>(
      `/projects/${project}/topics`,
    );
    this.storage.setTags(response.id, ['topics']);
    return response;
  }

  async getTopic(topic: string) {
    const response = await this.axios.get<Model.Topic>(`/topics/${topic}`);
    this.storage.setTags(response.id, ['topic', topic]);
    return response;
  }

  // Invoices

  async getInvoices(timelapse: Timelapse) {
    const response = await this.axios.get<Array<Model.Invoice>>('/invoices', {
      params: { timelapse },
    });
    this.storage.setTags(response.id, ['invoices']);
    return response;
  }

  async getInvoice(id: string) {
    const response = await this.axios.get<Model.Invoice>(`/invoices/${id}`, {});
    this.storage.setTags(response.id, ['invoice', id]);
    return response;
  }

  async createInvoice(data: InvoicePayload) {
    const result = await this.axios.post<Model.Invoice>('/invoices', data);
    return result.data;
  }

  async updateInvoice(id: string, data: InvoicePayload) {
    const result = await this.axios.put<Model.Invoice>(`/invoices/${id}`, data);
    return result.data;
  }

  async deleteInvoice(id: string) {
    const result = await this.axios.delete<void>(`/invoices/${id}`);
    return result.data;
  }

  async createInvoiceFile(slot: SlotConfig) {
    const result = await this.axios.post<UrlPair>(`/invoice-files`, slot);
    return result.data;
  }

  // Payments

  async getPayments(timelapse: Timelapse) {
    const response = await this.axios.get<Array<Model.Payment>>('/payments', {
      params: { timelapse },
    });
    this.storage.setTags(response.id, ['payments']);
    return response;
  }

  async getPayment(id: string) {
    const response = await this.axios.get<Model.Payment>(`/payments/${id}`);
    this.storage.setTags(response.id, ['payment', id]);
    return response;
  }

  async getInvoicePayments(invoice: string) {
    const response = await this.axios.get<Array<Model.Payment>>(
      `/invoices/${invoice}/payments`,
    );
    this.storage.setTags(response.id, ['payments']);
    return response;
  }

  async getInvoicesPayments(invoices: Array<string>) {
    const response = await this.axios.get<Array<Model.Payment>>(`/payments`, {
      params: { invoices },
    });
    this.storage.setTags(response.id, ['payments']);
    return response;
  }

  // Transactions

  async getTransactions(timelapse: Timelapse, direction: 'debit' | 'credit') {
    return await this.axios.get<Array<Model.Transaction>>('/transactions', {
      params: { timelapse, direction },
    });
  }

  // Bank accounts
  async getBankAccounts() {
    return await this.axios.get<Array<Model.BankAccount>>('/bank-accounts', {});
  }

  // Moves

  async getMoves(timelapse: Timelapse) {
    const response = await this.axios.get<Array<Model.MoveInfo>>('/moves', {
      params: { timelapse },
    });
    this.storage.setTags(response.id, ['moves']);
    return response;
  }

  async getMove(id: string) {
    const response = await this.axios.get<Model.Move>(`/moves/${id}`);
    this.storage.setTags(response.id, ['move', id]);
    return response;
  }

  async createMove(payload: MovePayload) {
    const response = await this.axios.post<Model.Move>('/moves', payload);
    this.invalidate(this.storage.searchKeys('moves'));
    return response;
  }

  async updateMove(id: string, payload: MovePayload) {
    const response = await this.axios.patch<Model.Move>(
      `/moves/${id}`,
      payload,
    );
    this.invalidate(this.storage.searchKeys(id, 'moves'));
    return response;
  }

  async deleteMove(id: string) {
    const response = await this.axios.delete(`/moves/${id}`);
    this.invalidate(this.storage.searchKeys(id, 'moves'));
    return response;
  }

  // Moves

  async getPlaces() {
    const response = await this.axios.get<Array<Model.Place>>('/places', {});
    this.storage.setTags(response.id, ['places']);
    return response;
  }

  async createPlace(label: string, ref_google: string) {
    const newPlace = await this.axios.post<Model.Place>('/places', {
      ref_google,
      label,
    });
    this.invalidate(this.storage.searchKeys('places'));
    return newPlace.data;
  }

  // Vehicles

  async getVehicles() {
    const response = await this.axios.get<Array<Model.Vehicle>>(
      '/vehicles',
      {},
    );
    this.storage.setTags(response.id, ['vehicles']);
    return response;
  }

  private updater = new Updater();
  private lastsUpdates: Record<string, Date> = {};

  useData<TData>(
    fn: () => Promise<CacheAxiosResponse<TData>>,
    deps: DependencyList = [],
  ) {
    const [cacheKey, setCacheKey] = useState<string | null>(null);

    const lastUpdate = this.updater.useValue(() => {
      if (cacheKey === null) return null;
      return this.lastsUpdates[cacheKey] || null;
    }, [cacheKey]);

    const responsePromise = useMemo(() => {
      return fn();
    }, [...deps, lastUpdate]);

    const outputPromise = useMemo(async () => {
      return (await responsePromise).data;
    }, [responsePromise]);

    useEffect(() => {
      responsePromise.then((response) => {
        setCacheKey(response.id);
      });
    }, [responsePromise]);

    return outputPromise;
  }

  getCacheKeys(filter: (key: string) => boolean) {
    return Object.keys(this.store).filter(filter);
  }

  invalidate(input: string | Array<string>) {
    const keys = typeof input === 'string' ? [input] : input;
    for (let key of keys) {
      this.axios.storage.remove(key);
      this.lastsUpdates[key] = new Date();
    }
    this.updater.update();
  }
}
