import { Fragment } from 'react';
import Button from 'src/components/Button';
import DelayedView from 'src/components/DelayedView';
import PaymentForm from 'src/components/forms/PaymentForm';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Currency from 'src/utilities/Format';
import Model, { Payload, PaymentPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import InvoiceEditionPanel from '../Invoices/InvoiceEditionPanel';

type PaymentEditionPanelProps = {
  payment: string;
};

export default function PaymentEditionPanel(props: PaymentEditionPanelProps) {
  const id = props.payment;
  const { repository } = Services.use();
  const panels = usePanels();

  const payment = repository.useData(() => repository.getPayment(id), [id]);
  const invoice = repository.useData(async () => {
    const p = await payment;
    return repository.getInvoice(p.ref_invoice);
  }, [payment]);

  const onSubmit = useSubmit(
    async (data: Payload<PaymentPayload>) => {
      await repository.axios.patch<Model.Payment>(`/payments/${id}`, data);
      repository.invalidate(repository.storage.searchKeys(id, 'payments'));
    },
    [id],
  );

  const onDelete = useSubmit(async () => {
    await repository.axios.delete(`/payments/${id}`);
    panels.close();
    repository.invalidate(repository.storage.searchKeys(id, 'payments'));
  }, [id]);

  return (
    <Panel.Container>
      <DelayedView promise={payment}>
        {(payment) => (
          <Fragment>
            <Panel.Header
              title={`Paiement de ${Currency.format(payment.amount)}`}
            />
            <PaymentForm
              payment={payment}
              onSubmit={onSubmit}
              onDelete={onDelete}
            />
            <DelayedView promise={invoice}>
              {(invoice) => (
                <Button
                  onClick={() =>
                    panels.push(InvoiceEditionPanel, { invoice: invoice.id })
                  }>
                  Voir la facture
                </Button>
              )}
            </DelayedView>
          </Fragment>
        )}
      </DelayedView>
    </Panel.Container>
  );
}
