import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import Model, { PaymentPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import Timelapses from 'src/utilities/Timelapses';
import useSubmit from 'src/utilities/useSubmit';
import { Required } from '../Field/FieldValidation';
import Form from '../Field/Form';
import useDateField from '../Field/useDateField';
import useNumberField from '../Field/useNumberField';
import useSelectField from '../Field/useSelectField';
import InvoiceItem from '../items/InvoiceItem';
import TransactionListItem from '../TransactionListItem';

type PaymentFormProps = {
  payment: Model.Payment | null;
  onSubmit: (data: PaymentPayload) => any;
  onDelete?: () => any;
};

export default function PaymentForm(props: PaymentFormProps) {
  const { payment, onSubmit, onDelete } = props;

  const { repository } = Services.use();

  const timelapse = useMemo(
    () => Timelapses.fromNow(dayjs.duration(18, 'months')),
    [],
  );

  const invoices = repository.useData(
    () => repository.getInvoices(timelapse),
    [timelapse],
  );
  const transactions = repository.useData(
    () => repository.getTransactions(timelapse, 'credit'),
    [timelapse],
  );
  const bankAccounts = repository.useData(
    () => repository.getBankAccounts(),
    [],
  );

  const transactionOptions = useMemo(async () => {
    const [trans, acc] = await Promise.all([transactions, bankAccounts]);
    return trans.map((t) => {
      const a = acc.find((a) => a.id === t.ref_bank_account);
      return { transaction: t, bank_account: a };
    });
  }, [transactions, bankAccounts]);

  const amountField = useNumberField({
    label: 'Montant',
    initialValue: payment ? payment.amount : null,
    unit: (v) => ' € TTC',
    validation: (s) => Required(s),
    decimals: 2,
  });

  const paidAtField = useDateField({
    label: 'Date de paiement',
    initialValue: payment ? payment.paid_at : null,
    validation: (s) => Required(s),
  });

  const invoiceField = useSelectField({
    label: 'Facture de rattachement',
    options: invoices,
    initialValue: payment ? payment.ref_invoice : null,
    keyExtractor: (i) => i.id,
    labelExtractor: (i) => i.label,
    itemExtractor: (i) => <InvoiceItem invoice={i} taxes />,
    validation: (s) => Required(s),
  });

  const transactionField = useSelectField({
    label: 'Transaction',
    options: transactionOptions,
    initialValue: payment ? payment.ref_transaction : null,
    keyExtractor: (i) => i.transaction.id,
    labelExtractor: (i) => i.transaction.label,
    itemExtractor: (i) => (
      <TransactionListItem
        transaction={i.transaction}
        bankAccount={i.bank_account}
      />
    ),
    validation: (s) => Required(s),
  });

  useEffect(() => {
    if (!transactionField.option) return;
    if (amountField.value === null)
      amountField.set(transactionField.option.transaction.amount);
    if (paidAtField.value === null)
      paidAtField.set(transactionField.option.transaction.operation_date);
  }, [transactionField.option, paidAtField.value]);

  const onSubmitForm = useSubmit(() => {
    const amount = amountField.validate();
    const paidAt = paidAtField.validate();
    const transaction = transactionField.validate();
    const invoice = invoiceField.validate();
    onSubmit({
      amount,
      paid_at: paidAt,
      ref_transaction: transaction,
      ref_invoice: invoice,
    });
  }, []);

  return (
    <Form onSubmit={onSubmitForm} onRemove={onDelete}>
      {transactionField.render()}
      {amountField.render()}
      {paidAtField.render()}
      {invoiceField.render()}
    </Form>
  );
}
