/** @jsxImportSource @emotion/react */

type LinkProps = {
  href: string;
  children: string;
};

export default function Link(props: LinkProps) {
  return <a href={props.href}>{props.children}</a>;
}

Link.style = `
a {
  text-decoration : underline ;
  color : inherit ;
}`;
