import dayjs from 'dayjs';
import Currency from 'src/utilities/Format';
import Model from 'src/utilities/Model';
import { Reaction } from '../Clickable';
import ListItem from '../Panel/ListItem';

type MoveInfoItemProps = {
  move: Model.MoveInfo;
} & Reaction;

export default function MoveInfoItem(props: MoveInfoItemProps) {
  const { move, ...reaction } = props;

  return (
    <ListItem
      label={`${move.from} ➜ ${move.to}`}
      helper={`${Currency.kilometers(move.distance)} ${
        move.round_trip ? 'A/R' : ''
      } le ${dayjs(move.date).format('DD MMMM YYYY')}`}
      rightText={move.cost === null ? '-' : Currency.format(move.cost)}
      {...reaction}
    />
  );
}
