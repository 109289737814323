/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { ReactNode, useCallback, useMemo } from 'react';
import Css from 'src/utilities/Css';
import Defered, { useDefered } from 'src/utilities/Defered';
import { Dropdown } from 'src/utilities/Dropdown';
import useMemoState from 'src/utilities/useMemoState';
import Clickable from '../Clickable';
import DelayedView from '../DelayedView';
import ListItem from '../Panel/ListItem';
import FieldContainer from './FieldContainer';
import FieldDropdown from './FieldDropdown';
import FieldInput from './FieldInput';
import { ValidationFn, useValidateFn } from './FieldValidation';
import fieldStyles from './fieldStyles';

type MaybePromise<TValue> = TValue | Promise<TValue>;

type SelectFieldConfig<TOption extends {}, TValidated extends string | null> = {
  initialValue: TOption | string | null | undefined;
  label: string | null;
  placeholder?: string;
  options: MaybePromise<Array<TOption>>;
  keyExtractor: (options: TOption) => string;
  labelExtractor: (option: TOption) => string;
  itemExtractor?: (option: TOption) => ReactNode;
  validation?: ValidationFn<string | null, TValidated>;
};

export default function useSelectField<
  TOption extends {},
  TValidated extends string | null,
>(config: SelectFieldConfig<TOption, TValidated>) {
  const {
    initialValue,
    label,
    options,
    validation,
    keyExtractor,
    itemExtractor,
    labelExtractor,
    placeholder,
  } = config;

  const [value, setValue] = useMemoState(() => {
    if (initialValue === undefined) return null;
    else if (initialValue === null) return null;
    else if (typeof initialValue === 'string') return initialValue;
    else return keyExtractor(initialValue);
  }, [initialValue]);

  const optionsPromise = useMemo(() => {
    if (options instanceof Promise) return options;
    else return Promise.resolve(options);
  }, [options]);

  const optionPromise = useMemo(async () => {
    if (value === null) return null;
    const options = await optionsPromise;
    const option = options.find((o) => keyExtractor(o) === value);
    return option;
  }, [value, optionsPromise]);

  const optionDefered = useDefered(optionPromise);

  const option = useMemo(() => {
    if (value === null) return null;
    if (!Defered.isResolved(optionDefered)) return undefined;
    const option = optionDefered.value;
    if (option === null) return null;
    if (option === undefined) return undefined;
    if (keyExtractor(option) !== value) return undefined;
    else return option;
  }, [optionDefered, value]);

  const [dropdown, showDropdown, hideDropdown] = Dropdown.useSpeaker();

  const change = useCallback((option: TOption | null) => {
    setValue(option ? keyExtractor(option) : null);
    hideDropdown();
  }, []);

  const displayedValue = useMemo(() => {
    if (option === null) return '';
    else if (option === undefined) return '';
    else return labelExtractor(option);
  }, [option]);

  const containerCss = css({
    display: 'flex',
    alignItems: 'center',
  });

  const fieldCss = css({
    flexGrow: 1,
  });

  const render = () => (
    <FieldContainer label={label} stopPropagation>
      <div css={containerCss}>
        <div css={fieldCss}>
          <FieldInput
            type="text"
            css={fieldStyles.inputCss}
            value={displayedValue}
            readOnly
            onFocus={showDropdown}
            placeholder={placeholder}
          />
        </div>
        {value ? (
          <Clickable css={Css.buttonReset} onClick={() => change(null)}>
            <Icon path={mdiClose} size={'20px'} />
          </Clickable>
        ) : null}
      </div>
      <FieldDropdown visible={dropdown} height={300}>
        <DelayedView promise={optionsPromise}>
          {(options) => {
            return options.map((o) => {
              const key = keyExtractor(o);
              const node = itemExtractor ? (
                itemExtractor(o)
              ) : (
                <ListItem label={labelExtractor(o)} />
              );
              return (
                <div key={key} onClick={() => change(o)}>
                  {node}
                </div>
              );
            });
          }}
        </DelayedView>
      </FieldDropdown>
    </FieldContainer>
  );

  const validate = useValidateFn(value, validation);

  return { value, option, render, validate };
}
