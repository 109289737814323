import Model from 'src/utilities/Model';
import { Reaction } from '../Clickable';
import ListItem from '../Panel/ListItem';

type ClientItemProps = {
  client: Model.Client;
} & Reaction;

export default function ClientItem(props: ClientItemProps) {
  const { client, ...reaction } = props;

  return (
    <ListItem
      label={client.name}
      image={
        client.icon ||
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPc8x8AAn0BvalaEbQAAAAASUVORK5CYII='
      }
      {...reaction}
    />
  );
}
