import { css } from '@emotion/react';

const outfit = css`
  font-family: 'Outfit', sans-serif;
  line-height: 1.2;
`;

const page = css`
  ${outfit};
  font-weight: 200;
  font-size: 3rem;
`;

const heading = css`
  ${outfit};
  font-size: 1.5rem;
`;

const subheading = css`
  ${outfit};
  font-size: 1.25rem;
`;

const body = css`
  ${outfit};
  font-size: 1rem;
  line-height: 1.2;
`;

const minor = css`
  ${outfit};
  font-size: 0.8rem;
`;

const button = css`
  ${outfit};
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
`;

const printMinor = css`
  ${outfit};
  font-size: 0.28cm;
  font-weight: 400;
`;

const printBody = css`
  ${outfit};
  font-size: 0.32cm;
  font-weight: 500;
`;

const printSubtitle = css`
  ${outfit};
  font-size: 0.42cm;
  text-transform: uppercase;
  font-weight: 900;
`;

const printTitle = css`
  ${outfit};
  font-size: 0.5cm;
  text-transform: uppercase;
  font-weight: 900;
`;

const Typos = {
  page,
  heading,
  subheading,
  body,
  minor,
  button,
  printTitle,
  printBody,
  printMinor,
  printSubtitle,
};

export type TypoName = keyof typeof Typos;

export default Typos;
