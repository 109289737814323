import axios from 'axios';
import {
  AxiosCacheInstance,
  buildStorage,
  NotEmptyStorageValue,
  setupCache,
} from 'axios-cache-interceptor';
import Me from './Me';
import Model, { InvoicePayload } from './Model';
import Timelaspses, { Timelapse } from './Timelapses';

type ProjectUpdate = Pick<Model.Project, 'name' | 'width' | 'timesheet_notes'>;

type TopicUpdate = Partial<Pick<Model.Topic, 'label' | 'color'>>;

export type TaskUpdate = Partial<
  Pick<Model.Task, 'label' | 'quantity' | 'ref_topic'>
>;
export type ExtraUpdate = Partial<
  Pick<Model.Extra, 'label' | 'price' | 'ref_topic'>
>;

export default class Api {
  readonly axios: AxiosCacheInstance;
  private store: Record<string, NotEmptyStorageValue> = {};

  constructor(private origin: string, readonly me: Me) {
    this.axios = setupCache(axios.create({ baseURL: this.origin }), {
      storage: buildStorage({
        set: (key: string, value: NotEmptyStorageValue) => {
          this.store[key] = value;
        },
        find: (key: string) => {
          return this.store[key] || undefined;
        },
        remove: (key: string) => {
          delete this.store[key];
        },
      }),
    });
    this.axios.interceptors.request.use((config) => {
      const token = this.me.getToken();
      if (token) {
        config.headers = { ...config.headers, authorization: token };
      }
      return config;
    });
  }

  async getMe() {
    const result = await this.axios.get<Model.User>('/me');
    return result.data;
  }

  async getClients() {
    const result = await this.axios.get<Array<Model.Client>>('/clients');
    return result.data;
  }

  // Project

  async getProjects() {
    const result = await this.axios.get<Array<Model.Project>>('/projects');
    return result.data;
  }

  async updateProject(id: string, payload: Partial<ProjectUpdate>) {
    const result = await this.axios.put<Model.Project>(
      `/projects/${id}`,
      payload,
    );
    return result.data;
  }

  // Topics

  async getTopics() {
    const result = await this.axios.get<Array<Model.Topic>>('/topics');
    return result.data;
  }

  async createTopic(ref_project: string) {
    const result = await this.axios.post<Model.Topic>('/topics', {
      ref_project,
    });
    return result.data;
  }

  async updateTopicLabel(id: string, update: TopicUpdate) {
    const result = await this.axios.put<Model.Topic>(`/topics/${id}`, update);
    return result.data;
  }

  // Tasks

  async getTasks(projects: Array<string> | null, timelapse: Timelapse | null) {
    const params = new URLSearchParams();
    if (projects !== null)
      projects.forEach((p) => params.append('projects', p));
    if (timelapse !== null) params.append('timelapse', timelapse);
    const result = await this.axios.get<Array<Model.Task>>('/tasks', {
      params,
    });
    return result.data;
  }

  async createTask(day: string, ref_project: string) {
    const result = await this.axios.post<Model.Task>(`/tasks`, {
      day,
      ref_project,
      label: '',
      hours: null,
    });
    return result.data;
  }

  async updateTask(id: string, update: TaskUpdate) {
    const result = await this.axios.put<Model.Task>(`/tasks/${id}`, update);
    return result.data;
  }

  async deleteTask(id: string) {
    const result = await this.axios.delete<null>(`/tasks/${id}`);
    return result.data;
  }

  // Extras

  async getExtras(projects: Array<string> | null, timelapse: Timelapse | null) {
    const params = new URLSearchParams();
    if (projects !== null)
      projects.forEach((p) => params.append('projects', p));
    if (timelapse !== null) {
      const boudaries = Timelaspses.getBoundaries(timelapse);
      params.append('from', boudaries.start);
      params.append('to', boudaries.end);
    }
    const result = await this.axios.get<Array<Model.Extra>>('/extras', {
      params,
    });
    return result.data;
  }

  async createExtra(day: string, ref_project: string) {
    const result = await this.axios.post<Model.Extra>(`/extras`, {
      day,
      ref_project,
    });
    return result.data;
  }

  async updateExtra(id: string, update: ExtraUpdate) {
    const result = await this.axios.put<Model.Extra>(`/extras/${id}`, update);
    return result.data;
  }

  async deleteExtra(id: string) {
    const result = await this.axios.delete<null>(`/extras/${id}`);
    return result.data;
  }

  // Contracts

  async getContracts(timelapse: Timelapse) {
    const response = await this.axios.get<Array<Model.Contract>>(`/contracts`, {
      params: { timelapse },
    });
    return response.data;
  }

  // Invoices

  async getInvoices(timelapse: Timelapse) {
    const response = await this.axios.get<Array<Model.Invoice>>('/invoices', {
      params: { timelapse },
    });
    return response.data;
  }

  async createInvoice(data: InvoicePayload) {
    const result = await this.axios.post<Model.Invoice>('/invoices', data);
    return result.data;
  }

  async updateInvoice(id: string, data: InvoicePayload) {
    const result = await this.axios.put<Model.Invoice>(`/invoices/${id}`, data);
    return result.data;
  }

  async deleteInvoice(id: string) {
    const result = await this.axios.delete<void>(`/invoices/${id}`);
    return result.data;
  }

  // Projections

  async getProjections(year: string) {
    const params = new URLSearchParams();
    params.set('year', year);
    const result = await this.axios.get<Array<Model.Projection>>(
      '/projections',
      { params },
    );
    return result.data;
  }

  async createProjection(year: string, ref_project: string) {
    const result = await this.axios.post<Model.Projection>('/projections', {
      year,
      ref_project,
    });
    return result.data;
  }

  async updateProjection(
    id: string,
    label: string,
    quantity: number,
    unit_price: number,
  ) {
    const result = await this.axios.put<Model.Projection>(
      `/projections/${id}`,
      {
        label,
        quantity,
        unit_price,
      },
    );
    return result.data;
  }

  async deleteProjection(id: string) {
    const result = await this.axios.delete<void>(`/projections/${id}`);
    return result.data;
  }

  // Performance

  async getReporting(timelapse: Timelapse, project: string | null) {
    const params = new URLSearchParams();
    params.set('timelapse', timelapse);
    if (project) params.set('project', project);
    const results = await this.axios.get<Model.Reporting>(`/reportings`, {
      params,
      cache: false,
    });
    console.log(results.data);
    return results.data;
  }
}
