/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { last } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useResponsive from 'src/utilities/useResponsive';
import PanelsDisplayer, { PanelsController } from '../PanelsDisplayer';

type PanelsProps = {
  main: React.ComponentType<{}>;
};

export default function Panels(props: PanelsProps) {
  const Component = useResponsive({
    1200: PanelsMobile,
    bigger: PanelsDesktop,
  });
  return <Component {...props} />;
}

function PanelsDesktop(props: PanelsProps) {
  const { main } = props;

  const controller = useMemo(() => new PanelsController(), []);
  const panels = controller.updater.useValue(() => controller.getPanels(), []);

  const panelsCss = css({
    display: 'flex',
    height: 'calc(100vh - 70px)',
    overflow: 'auto',
  });

  const ref = useRef<HTMLDivElement>(null);

  const onReady = useCallback((c: PanelsController) => {
    c.set(0, main, {});
  }, []);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollTo({ left: 1000000, behavior: 'smooth' });
  }, [panels.length]);

  return (
    <PanelsDisplayer onReady={onReady}>
      {(panels) => {
        return (
          <div css={panelsCss} ref={ref}>
            {panels}
          </div>
        );
      }}
    </PanelsDisplayer>
  );
}

function PanelsMobile(props: PanelsProps) {
  const { main } = props;

  const controller = useMemo(() => new PanelsController(), []);
  const panels = controller.updater.useValue(() => controller.getPanels(), []);

  const panelsCss = css({
    display: 'flex',
    height: '100%',
  });

  const ref = useRef<HTMLDivElement>(null);

  const onReady = useCallback((c: PanelsController) => {
    c.set(0, main, {});
  }, []);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollTo({ left: 1000000, behavior: 'smooth' });
  }, [panels.length]);

  return (
    <PanelsDisplayer onReady={onReady}>
      {(panels) => {
        const main = last(panels);
        return (
          <div css={panelsCss} ref={ref}>
            {main}
          </div>
        );
      }}
    </PanelsDisplayer>
  );
}
