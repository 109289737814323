/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { darken, linearGradient, rgba } from 'polished';
import Theme from 'src/utilities/Theme';
import Css from '../utilities/Css';
import Layout from '../utilities/Layout';
import Typos from '../utilities/Typos';
import Clickable, { ClickableProps } from './Clickable';

type ButtonProps = {
  label?: string;
} & ClickableProps;

export default function Button(props: ButtonProps) {
  const containerCss = css`
    ${Css.buttonReset}
    ${Typos.button}
    background: ${linearGradient({
      colorStops: [Theme.use().color, darken(0.1, Theme.use().color)],
    })}
    color: white;
    border-radius: 24px;
    padding-left: ${Layout.S}px;
    padding-right: ${Layout.S}px;
    padding-top: ${Layout.S / 2}px;
    padding-bottom: ${Layout.S / 2}px;
    align-self: center;
    transition: outline 100ms;
    outline: ${rgba(Theme.use().color, 0)} solid 0px;
    cursor: pointer;
    &:hover {
      outline: ${rgba(Theme.use().color, 0.2)} solid 3px;
      outline-offset: 1px;
    }
  `;

  const children = props.label || props.children;

  return (
    <Clickable {...props} css={containerCss}>
      {children}
    </Clickable>
  );
}
