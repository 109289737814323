/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import Css from 'src/utilities/Css';
import Clickable from '../Clickable';
import { usePanels } from '../PanelsDisplayer';
import Spacer from '../Spacer';
import Typo from '../Typo';

type PanelHeaderProps<TItem> = {
  title: string;
};

export default function PanelHeader<TItem>(props: PanelHeaderProps<TItem>) {
  const { title } = props;

  const panels = usePanels();

  const headerCss = css({
    padding: 20,
    display: 'flex',
  });

  return (
    <div css={headerCss}>
      <Typo typo="heading">{title}</Typo>
      <Spacer grow />
      <Clickable onClick={() => panels.close()} css={Css.buttonReset}>
        <Icon path={mdiClose} size={'24px'} />
      </Clickable>
    </div>
  );
}
