/** @jsxImportSource @emotion/react */

// Table

type ContactProps = {};

export default function Contact(props: ContactProps) {
  return (
    <div id="contact">
      <div className="company">Valentin Consulting SARL</div>
      <div className="info">
        Société au capital de 1000€ immatriculée au RCS de Saint Denis de la
        Réunion sous le SIRET 834 705 642, code NAF 6202A
      </div>
      <div className="info">
        <a href="tel:+33681672122">+33 6 81 67 21 22</a>
        {' - '}
        <a href="mailto:valentin@valentin.consulting">
          valentin@valentin.consulting
        </a>
        {' - '}
        <a href="https://www.valentin.consulting">
          https://www.valentin.consulting
        </a>
      </div>
      <div className="info">
        3 rue Daly - Porte 4 - 97422 La Saline - Île de la Réunion
      </div>
    </div>
  );
}

Contact.style = `
    #contact{
      text-align : center ;
    }

    #contact .company{
      font-size : 0.3cm ;
      font-weight : 900 ;
      text-transform : uppercase ;
    }

    #contact .info{
      font-size :0.25cm ;
    }
    
  `;
