import { DependencyList, useCallback } from 'react';

type UseSubmitHookOptions = {
  confirmation?: string;
};

export default function useSubmit<TArgs extends Array<any>>(
  fn: (...args: TArgs) => any,
  deps: DependencyList,
): (...args: TArgs) => Promise<void>;
export default function useSubmit<TArgs extends Array<any>>(
  fn: (...args: TArgs) => any,
  options: UseSubmitHookOptions,
  deps: DependencyList,
): (...args: TArgs) => Promise<void>;
export default function useSubmit<TArgs extends Array<any>>(
  fn: (...args: TArgs) => any,
  arg2: DependencyList | UseSubmitHookOptions,
  arg3: DependencyList = [],
): (...args: TArgs) => Promise<void> {
  let options: UseSubmitHookOptions;
  let deps: DependencyList;
  if (Array.isArray(arg2)) {
    deps = arg2;
    options = {};
  } else {
    deps = arg3;
    options = arg2 as UseSubmitHookOptions;
  }

  return useCallback(async (...args: TArgs) => {
    try {
      if (options.confirmation) {
        const confirmation = window.confirm(options.confirmation);
        if (!confirmation) return;
      }
      await fn(...args);
    } catch (err) {
      alert(err);
    }
  }, deps);
}
