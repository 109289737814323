/** @jsxImportSource @emotion/react */
import dayjs from 'dayjs';
import { capitalize, sortBy, uniq } from 'lodash';
import { rgba } from 'polished';
import { Fragment, useMemo } from 'react';
import DelayedView from 'src/components/DelayedView';
import Print from 'src/components/Print';
import AppTimelapse from 'src/utilities/AppTimelapse';
import DataSet from 'src/utilities/DataSet';
import Currency from 'src/utilities/Format';
import Holidays from 'src/utilities/Holidays';
import Model from 'src/utilities/Model';
import { Routing } from 'src/utilities/Routes';
import Services from 'src/utilities/Services';
import Timelapses, { Timelapse } from 'src/utilities/Timelapses';
import { useAllPromises } from 'src/utilities/useAllPromises';
import usePerf, { getPerf } from 'src/utilities/usePerf';

export default function TimesheetExport() {
  const { repository } = Services.use();
  const projectId = Routing.useQueryParam('project') as string;
  const timelapse = AppTimelapse.use();

  const project = repository.useData(
    () => repository.getProject(projectId),
    [projectId],
  );

  const client = repository.useData(async () => {
    return repository.getClient((await project).ref_client);
  }, [project]);

  const topics = repository.useData(async () => {
    return repository.getProjectTopics(projectId);
  }, [project]);

  // const contracts = repository.useData(async () => {
  //   return repository.getClientContracts(projectId);
  // }, [project]);

  const tasks = repository.useData(
    () => repository.getTasks({ timelapse, projects: [projectId] }),
    [timelapse, projectId],
  );

  const extras = repository.useData(
    () => repository.getExtras({ timelapse, projects: [projectId] }),
    [timelapse, projectId],
  );

  const allTasks = repository.useData(
    () => repository.getTasks({ projects: [projectId] }),
    [timelapse, projectId],
  );

  const allExtras = repository.useData(
    () => repository.getExtras({ projects: [projectId] }),
    [timelapse, projectId],
  );

  const promise = useAllPromises(
    client,
    project,
    topics,
    tasks,
    extras,
    allTasks,
    allExtras,
  );

  return (
    <DelayedView promise={promise}>
      {([client, project, topics, tasks, extras, allTasks, allExtras]) => {
        return (
          <Print margin="1cm">
            <Print.Header
              mainTitle={capitalize(Timelapses.format(timelapse, false))}
              preTitle="Relevé d'activité"
            />
            <Print.Spacer />
            <Print.Infos>
              <Print.Info
                label="Client final"
                value={client.name}
                image={client.icon || undefined}
              />
              <Print.Info
                label="Projet"
                value={project.name}
                image={project.icon || undefined}
              />
            </Print.Infos>
            <Print.Spacer />
            <Print.Divider />
            <Print.Spacer />
            <DaysTable2
              client={client}
              project={project}
              topics={topics}
              tasks={tasks}
              allTasks={allTasks}
              extras={extras}
              allExtras={allExtras}
              timelapse={timelapse}
            />
            <Print.Spacer />
            <PerTopic2
              client={client}
              project={project}
              topics={topics}
              tasks={tasks}
              allTasks={allTasks}
              extras={extras}
              allExtras={allExtras}
              timelapse={timelapse}
            />
            <Print.Spacer />
            <BillingInfos
              client={client}
              project={project}
              topics={topics}
              tasks={tasks}
              allTasks={allTasks}
              extras={extras}
              allExtras={allExtras}
              timelapse={timelapse}
            />
            <Print.Spacer />
            <Print.Signature />
            <Print.Spacer scale={2} />
            <Print.Contact />
          </Print>
        );
      }}
    </DelayedView>
  );
}

type TimesheetExportViewProps = {
  timelapse: Timelapse;
  client: Model.Client;
  // contracts: Array<Model.Contract>;
  project: Model.Project;
  topics: Array<Model.Topic>;
  tasks: Array<Model.Task>;
  allTasks: Array<Model.Task>;
  extras: Array<Model.Extra>;
  allExtras: Array<Model.Extra>;
};

function DaysTable2(props: TimesheetExportViewProps) {
  const { timelapse, project, tasks, extras, topics } = props;

  const perf = usePerf({ projects: [project], extras, tasks });

  const days = Timelapses.useDays(timelapse);

  const data = useMemo(() => {
    return days.map((d) => {
      const dayTasks = tasks.filter((t) => t.day === d);
      const dayExtras = extras.filter((t) => t.day === d);
      const dayItems = [...dayTasks, ...dayExtras];
      return {
        Date: dayjs(d).format('ddd D MMM'),
        Tâches: (
          <Print.Lines gap="0.1cm">
            {dayItems.map((t) => {
              const topic = topics.find((topic) => topic.id === t.ref_topic);
              const badge = topic ? (
                <Fragment>
                  <Print.Badge
                    color={topic.color || 'rgba(0,0,0,0.1)'}
                    label={topic.label}
                  />
                  <Print.Spacer scale={0.25} />
                </Fragment>
              ) : null;
              return (
                <Fragment>
                  {badge}
                  {t.label}
                </Fragment>
              );
            })}
          </Print.Lines>
        ),
        Temps: (
          <Print.Lines gap="0.1cm">
            {dayItems.map((t) => {
              return (
                <Print.Center>
                  {'quantity' in t ? t.quantity + 'h' : ''}
                </Print.Center>
              );
            })}
          </Print.Lines>
        ),
        Extras: (
          <Print.Lines gap="0.1cm">
            {dayItems.map((t) => {
              return (
                <Print.Center>
                  {'quantity' in t ? '' : Currency.format(t.price)}
                </Print.Center>
              );
            })}
          </Print.Lines>
        ),
        _background: Holidays.isHolyday(dayjs(d).toDate())
          ? rgba(0, 0, 0, 0.05)
          : undefined,
      };
    });
  }, [days, tasks]);

  const footer = {
    Temps: <Print.Center>{`${perf.hours}h soit ${perf.days}j`}</Print.Center>,
    Extras: (
      <Print.Center>{`${Currency.format(perf.extrasPerf)}`}</Print.Center>
    ),
  };

  const header = {
    Temps: <Print.Center>Temps</Print.Center>,
    Extras: <Print.Center>Extras</Print.Center>,
  };

  return (
    <Fragment>
      <Print.Title>Tâches par jour</Print.Title>
      <Print.Spacer block scale={0.5} />
      <Print.Table data={data} footers={footer} headers={header} />
    </Fragment>
  );
}

function PerTopic2(props: TimesheetExportViewProps) {
  const { tasks, topics, extras, project, allExtras, allTasks } = props;

  const shownTopics = useMemo(() => {
    let output: Array<Model.Topic | null> = [];
    tasks.forEach((t) => {
      if (t.ref_topic) output.push(DataSet.find(topics, t.ref_topic));
      else output.push(null);
    });
    extras.forEach((t) => {
      if (t.ref_topic) output.push(DataSet.find(topics, t.ref_topic));
      else output.push(null);
    });
    return sortBy(
      uniq(output),
      (t) => (t ? 1 : 0),
      (t) => (t ? t.color : ''),
      (t) => (t ? t.label : ''),
    );
  }, [tasks, extras, topics]);

  const data = shownTopics.map((topic) => {
    const topicTasks = tasks.filter(
      (t) => t.ref_topic === (topic ? topic.id : null),
    );
    const topicExtras = extras.filter(
      (t) => t.ref_topic === (topic ? topic.id : null),
    );

    const perf = getPerf({
      tasks: topicTasks,
      extras: topicExtras,
      projects: [project],
    });

    const topicAllTasks = allTasks.filter(
      (t) => t.ref_topic === (topic ? topic.id : null),
    );
    const topicAllExtras = allExtras.filter(
      (t) => t.ref_topic === (topic ? topic.id : null),
    );

    const allPerf = getPerf({
      tasks: topicAllTasks,
      extras: topicAllExtras,
      projects: [project],
    });
    return {
      Sujet: topic ? (
        <Print.Badge
          color={topic.color || rgba(0, 0, 0, 0.1)}
          label={topic.label}
        />
      ) : (
        'Non catégorisé'
      ),
      Heures: <Print.Center>{`${perf.hours}h`}</Print.Center>,
      Jours: <Print.Center>{`${perf.days}j`}</Print.Center>,
      Extras: (
        <Print.Center>{`${
          perf.extrasPerf ? Currency.format(perf.extrasPerf) : '-'
        }`}</Print.Center>
      ),
      // 'Sur la période': (
      //   <Print.Center>{`${Currency.format(perf.perf)}`}</Print.Center>
      // ),
      // 'Depuis le début': (
      //   <Print.Center>{`${Currency.format(allPerf.perf)}`}</Print.Center>
      // ),
    };
  });

  const footerPerf = usePerf({ tasks, extras, projects: [project] });

  const footers = {
    Heures: <Print.Center>{`${footerPerf.hours}h`}</Print.Center>,
    Jours: <Print.Center>{`${footerPerf.days}j`}</Print.Center>,
    Extras: (
      <Print.Center>{`${Currency.format(footerPerf.extrasPerf)}`}</Print.Center>
    ),
    // 'Sur la période': (
    //   <Print.Center>{`${Currency.format(footerPerf.perf)}`}</Print.Center>
    // ),
  };

  const headers = {
    Heures: <Print.Center>Heures</Print.Center>,
    Jours: <Print.Center>Jours</Print.Center>,
    Extras: <Print.Center>Extras</Print.Center>,
    // 'Sur la période': <Print.Center>Sur la période</Print.Center>,
    // 'Depuis le début': <Print.Center>Depuis le début</Print.Center>,
  };

  return (
    <Fragment>
      <Print.Title>Détail par sujet</Print.Title>
      <Print.Spacer block scale={0.5} />
      <Print.Table data={data} footers={footers} headers={headers} />
    </Fragment>
  );
}

function BillingInfos(props: TimesheetExportViewProps) {
  const { project } = props;
  const infos = project.timesheet_notes;

  if (!infos) return null;

  return (
    <div>
      <Print.Title>Instructions de facturation</Print.Title>
      <Print.Spacer block scale={0.5} />
      {infos}
    </div>
  );
}
