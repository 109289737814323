/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Button from 'src/components/Button';
import Typo from 'src/components/Typo';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Model from 'src/utilities/Model';
import overlapColors from 'src/utilities/overlapColors';
import Zones, { Routing } from 'src/utilities/Routes';
import Theme from 'src/utilities/Theme';

type ProjectProps = {
  client: Model.Client;
  project: Model.Project;
};

export default function ProjectCell(props: ProjectProps) {
  const timelapse = AppTimelapse.use();
  const { project, client } = props;

  const containerCss = css({
    padding: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 100,
    background: overlapColors('white', Theme.useColor(1)),
    borderRight: `1px solid ${Theme.useColor(2)}`,
    borderBottom: `1px solid ${Theme.useColor(2)}`,
  });

  return (
    <div css={containerCss}>
      <Typo center typo="minor" opacity={0.7} oneLine>
        {client ? client.name : ''}
      </Typo>
      <Typo center oneLine>
        {project.name}
      </Typo>
      <Button
        to={Routing.getPath(
          Zones.TimesheetExport,
          { timelapse },
          { project: project.id },
        )}>
        Exporter
      </Button>
    </div>
  );
}
