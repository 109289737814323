/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import Css from 'src/utilities/Css';
import Typos from 'src/utilities/Typos';
import { FormContext } from './Form';

type FieldInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  preciser?: string;
};

export default function FieldInput(props: FieldInputProps) {
  const { preciser, ...inputProps } = props;

  const containerCss = css({
    position: 'relative',
  });

  const preciserCss = css(Css.absoluteFill, {
    position: 'absolute',
    padding: 4,
    pointerEvents: 'none',
    opacity: 0.5,
  });

  const form = useContext(FormContext);
  const inputCss = css(Css.inputReset, Typos.body, { padding: 4 });

  return (
    <div css={containerCss}>
      {preciser ? <div css={preciserCss}>{preciser}</div> : null}
      <input {...inputProps} css={inputCss} form={form} />
    </div>
  );
}
