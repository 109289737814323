import { useMemo } from 'react';
import {
  generatePath,
  matchPath,
  useLocation,
  useMatch,
  useParams,
  useSearchParams,
} from 'react-router-dom';

export type Zone = {
  pattern: string;
  parent: Zone | null;
};

const Home: Zone = {
  pattern: '/',
  parent: null,
};

const Depenses: Zone = {
  pattern: 'expenses',
  parent: Home,
};

const Contracts: Zone = {
  pattern: 'contracts',
  parent: Home,
};

const Clients: Zone = {
  pattern: 'clients',
  parent: Home,
};

const Projections: Zone = {
  pattern: 'projections',
  parent: Home,
};

const YearProjections: Zone = {
  pattern: ':year',
  parent: Projections,
};

const Timesheet: Zone = {
  pattern: 'timesheet',
  parent: Home,
};

const TimesheetExport: Zone = {
  pattern: 'export',
  parent: Timesheet,
};

const Performances: Zone = {
  pattern: 'performances',
  parent: Home,
};

const YearPerformances: Zone = {
  pattern: ':timelapse',
  parent: Performances,
};

const Invoices: Zone = {
  pattern: 'invoices',
  parent: Home,
};

const InvoiceCreation: Zone = {
  pattern: 'new',
  parent: Invoices,
};

const Invoice: Zone = {
  pattern: ':id',
  parent: Invoices,
};

const InvoicesExport: Zone = {
  pattern: 'invoices-export',
  parent: Home,
};

const Payments: Zone = {
  pattern: 'payments',
  parent: Home,
};

const Moves: Zone = {
  pattern: 'moves',
  parent: Home,
};

const MoveCreation: Zone = {
  pattern: 'new',
  parent: Moves,
};

const MovesExport: Zone = {
  pattern: 'export',
  parent: Moves,
};

const MoveEdition: Zone = {
  pattern: ':move',
  parent: Moves,
};

const Zones = {
  Home,
  Contracts,
  Depenses,
  Clients,
  Projections,
  YearProjections,
  Timesheet,
  TimesheetExport,
  Performances,
  YearPerformances,
  Invoices,
  InvoiceCreation,
  Invoice,
  InvoicesExport,
  Payments,
  Moves,
  MoveCreation,
  MoveEdition,
  MovesExport,
};

export default Zones;

function getZones(zone: Zone) {
  const output: Array<Zone> = [];
  let current: Zone | null = zone;
  while (current !== null) {
    output.unshift(current);
    current = current.parent;
  }
  return output;
}

function getPattern(zone: Zone) {
  const zones = getZones(zone);
  return zones
    .map((r) => `/${r.pattern}`)
    .join('')
    .replace(/[/]+/g, '/');
}

function getZonePattern(zone: Zone) {
  return zone.pattern;
}

function getPath(
  zone: Zone,
  params: Record<string, string> = {},
  query?: Record<string, string>,
) {
  let path = generatePath(getPattern(zone), params);
  if (query) path += `?${new URLSearchParams(query).toString()}`;
  return path;
}

function useParam(name: string): string;
function useParam<TDef>(name: string, def: TDef): string | TDef;
function useParam<TDef>(name: string, defaultValue?: TDef) {
  const pararms = useParams();
  let value: string | TDef | undefined = pararms[name];
  if (value === undefined) value = defaultValue;
  if (value === undefined) throw new Error(`Param ${name} not found`);
  return value;
}

function useQueryParam(name: string): string;
function useQueryParam<TDef>(name: string, def: TDef): string | TDef;
function useQueryParam<TDef>(name: string, defaultValue?: TDef) {
  const [pararms] = useSearchParams();
  let value: string | TDef | undefined = pararms.has(name)
    ? (pararms.get(name) as string)
    : undefined;
  if (value === undefined) value = defaultValue;
  if (value === undefined) throw new Error(`Query param ${name} not found`);
  return value;
}

function useIsInZone(zone: Zone) {
  const pattern = getPattern(zone);
  const match = useMatch(pattern);
  return match;
}

function useIsUnderZone(zone: Zone) {
  const pathname = useLocation().pathname;
  const zones = useMemo(() => getDescendings(zone), [zone]);
  const matchingZone = zones.find((z) => matchPath(getPattern(z), pathname));
  return matchingZone !== undefined;
}

function getDescendings(zone: Zone) {
  const descendings: Array<Zone> = [zone];
  const zones = Object.values(Zones);
  let runAgain = true;
  while (runAgain) {
    runAgain = false;
    const parents = [...descendings];
    for (let parent of parents) {
      for (let child of zones) {
        if (child.parent === parent) {
          descendings.push(child);
          runAgain = false;
        }
      }
    }
  }
  return descendings;
}

export const Routing = {
  getPattern,
  getZonePattern,
  getPath,
  useParam,
  useIsInZone,
  useIsUnderZone,
  useQueryParam,
  getDescendings,
};
