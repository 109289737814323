import Model from 'src/utilities/Model';
import { Reaction } from '../Clickable';
import ListItem from '../Panel/ListItem';

type ProjectItemProps = {
  project: Model.Project;
} & Reaction;

export default function ProjectItem(props: ProjectItemProps) {
  const { project, ...reaction } = props;

  return (
    <ListItem
      label={project.name}
      image={
        project.icon ||
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPc8x8AAn0BvalaEbQAAAAASUVORK5CYII='
      }
      {...reaction}
    />
  );
}
