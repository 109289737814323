import { Infer, literal, number, object, string, union } from 'superstruct';

// Days

const DaysPurposeSchema = object({
  type: literal('days'),
  days: number(),
  daily_price: number(),
});

export type DaysPurpose = Infer<typeof DaysPurposeSchema>;

// Extra

const ExtraPurposeSchema = object({
  type: literal('extra'),
  price: number(),
  label: string(),
  quantity: number(),
});

export type ExtraPurpose = Infer<typeof ExtraPurposeSchema>;

// Merge

export type Purpose = DaysPurpose | ExtraPurpose;
export type PurposeType = Purpose['type'];
export type PurposeData<P extends Purpose> = Omit<P, 'type'>;

function getAmount(p: Purpose) {
  if (p.type === 'days') return p.days * p.daily_price;
  else if (p.type === 'extra') return p.price * p.quantity;
  else throw new Error('Unknowned purpose');
}

const Purposes = {
  getAmount,
  schema: {
    days: DaysPurposeSchema,
    extra: ExtraPurposeSchema,
    all: union([DaysPurposeSchema, ExtraPurposeSchema]),
  },
};

export default Purposes;
