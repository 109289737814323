import { DependencyList, useEffect, useMemo, useState } from 'react';

type RepsonsiveHookParam<TValue> = Record<number | 'bigger', TValue>;

function computeWidth() {
  return window.screen.width;
}

export default function useResponsive<TValue>(
  params: RepsonsiveHookParam<TValue>,
  deps: DependencyList = [],
) {
  const [width, setWidth] = useState(computeWidth);

  const breakpoints = useMemo(
    () =>
      Object.keys(params)
        .filter((k) => k !== 'bigger')
        .map((v) => parseInt(v))
        .sort(),
    deps,
  );

  useEffect(() => {
    const handleWindowResize = () => setWidth(computeWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const output = useMemo(() => {
    let out: TValue | null = null;
    for (const breakpoint of breakpoints) {
      if (width < breakpoint) {
        out = params[breakpoint];
        break;
      }
    }
    if (out === null && params.bigger) out = params.bigger;
    if (!out === null) {
      throw new Error('Cannot determine responsive value');
    }
    return out as TValue;
  }, [breakpoints, width]);

  return output;
}
