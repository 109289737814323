/** @jsxImportSource @emotion/react */
import { nanoid } from 'nanoid';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import Updater from 'src/utilities/Updater';

type Panel<P = any> = [string, React.ComponentType<P>, P];

export class PanelsController {
  readonly updater = new Updater();
  private panels: Array<Panel> = [];

  getPanels() {
    return this.panels;
  }

  private createPanel<P>(
    component: React.ComponentType<P>,
    props: P,
  ): Panel<P> {
    return [nanoid(), component, props];
  }

  set<P>(index: number, component: React.ComponentType<P>, props: P) {
    this.panels[index] = this.createPanel(component, props);
    this.panels = this.panels.slice(0, index + 1);
    this.updater.update();
  }

  remove(index: number) {
    this.panels = this.panels.slice(0, index);
    this.updater.update();
  }
}

class PanelController {
  constructor(private panels: PanelsController, private index: number) {}

  push<P>(component: React.ComponentType<P>, props: P) {
    this.panels.set(this.index + 1, component, props);
  }

  replace<P>(component: React.ComponentType<P>, props: P) {
    this.panels.set(this.index, component, props);
  }

  close() {
    this.panels.remove(this.index);
  }
}

export const PanelsContext = createContext<PanelsController>(
  new PanelsController(),
);
export const PanelIndexContext = createContext<number>(0);

export function usePanels() {
  const panels = useContext(PanelsContext);
  const index = useContext(PanelIndexContext);
  return useMemo(() => new PanelController(panels, index), [panels, index]);
}

type PanelsDisplayerProps = {
  controller?: PanelsController;
  onReady: (controller: PanelsController) => void;
  children: (content: Array<ReactNode>) => ReactNode;
};

export default function PanelsDisplayer(props: PanelsDisplayerProps) {
  const { onReady, children } = props;

  const controller = useMemo(
    () => props.controller || new PanelsController(),
    [props.controller],
  );

  useEffect(() => {
    onReady(controller);
  }, [controller]);

  const panels = controller.updater.useValue(() => controller.getPanels(), []);

  return (
    <PanelsContext.Provider value={controller}>
      {children(
        panels.map((input, i) => {
          const Component = input[1];
          const props = input[2];
          return (
            <PanelIndexContext.Provider value={i} key={input[0]}>
              <Component {...props} />
            </PanelIndexContext.Provider>
          );
        }),
      )}
    </PanelsContext.Provider>
  );
}
