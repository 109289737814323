import { mdiPlus } from '@mdi/js';
import { useCallback } from 'react';
import DelayedView from 'src/components/DelayedView';
import ContractItem from 'src/components/items/ContractItem';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Model from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import ContractCreationPanel from './ContractCreationPanel';
import ContractEditionPanel from './ContractEditionPanel';

export default function ContractsListPanel() {
  const { repository } = Services.use();
  const panels = usePanels();
  const timelapse = AppTimelapse.use();
  const contracts = repository.useData(
    () => repository.getContracts(timelapse),
    [timelapse],
  );

  const onCreateContract = useCallback(() => {
    panels.push(ContractCreationPanel, {});
  }, []);

  const onUpdateContract = useCallback((contract: Model.Contract) => {
    panels.push(ContractEditionPanel, { contract: contract.id });
  }, []);

  return (
    <Panel.Container>
      <Panel.Header title="Contrats"></Panel.Header>
      <DelayedView promise={contracts}>
        {(contracts) => {
          return (
            <Panel.List
              items={contracts}
              getKey={(c) => c.id}
              renderItem={(contract) => (
                <ContractItem
                  contract={contract}
                  onClick={() => onUpdateContract(contract)}
                />
              )}
            />
          );
        }}
      </DelayedView>
      <Panel.MainAction icon={mdiPlus} onClick={onCreateContract} />
    </Panel.Container>
  );
}
