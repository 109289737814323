/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { rgba } from 'polished';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';
import Theme from 'src/utilities/Theme';

export const FieldContext = createContext<string>('');

type FieldContainerProps = PropsWithChildren<{
  label: string | null;
  stopPropagation?: boolean;
}>;

export default function FieldContainer(props: FieldContainerProps) {
  const id = useMemo(() => `field${Date.now()}`, []);
  const { label, children, stopPropagation } = props;

  const theme = Theme.use();

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (stopPropagation) e.stopPropagation();
    },
    [stopPropagation],
  );

  const containerCss = css({
    border: `1px solid ${rgba('black', 0.2)}`,
    margin: 6,
    position: 'relative',
    borderRadius: 4,
  });

  const labelCss = css({
    display: 'block',
    opacity: 0.5,
    padding: 4,
    paddingBottom: 0,
  });

  return (
    <FieldContext.Provider value={id}>
      <div css={containerCss} onClick={onClick}>
        {label ? (
          <label htmlFor={id} css={labelCss}>
            {label}
          </label>
        ) : null}
        {children}
      </div>
    </FieldContext.Provider>
  );
}
