import { PanelContainer } from './Container';
import PanelHeader from './Header';
import PanelIndicator from './Indicator';
import PanelList from './List';
import PanelMainAction from './MainAction';
import Menu from './Menu';
import PanelTabs from './Tabs';

const Panel = {
  Container: PanelContainer,
  List: PanelList,
  Indicator: PanelIndicator,
  Header: PanelHeader,
  MainAction: PanelMainAction,
  Tabs: PanelTabs,
  Menu: Menu,
};

export default Panel;
