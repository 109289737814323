import { useMemo } from 'react';
import Currency from 'src/utilities/Format';
import Model from 'src/utilities/Model';
import Purposes from 'src/utilities/Purposes';
import ListItem from '../Panel/ListItem';

type PurposeItemProps = {
  purpose: Model.Purpose;
  onDelete?: () => any;
};

export default function PurposeItem(props: PurposeItemProps) {
  const { purpose, onDelete } = props;
  const label = useMemo(() => {
    if (purpose.type === 'days') {
      return `${purpose.days} jour(s) à ${Currency.format(
        purpose.daily_price,
      )} par jour`;
    } else {
      return `${purpose.quantity} x ${purpose.label}`;
    }
  }, [purpose]);

  return (
    <ListItem
      label={label}
      rightText={Currency.format(Purposes.getAmount(purpose))}
      onDelete={onDelete}
    />
  );
}
