/** @jsxImportSource @emotion/react */

type BadgeProps = {
  color: string;
  label: string;
};

export default function Badge(props: BadgeProps) {
  const { color, label } = props;
  return (
    <span
      style={{
        display: 'inline-block',
        background: color,
        paddingTop: '0.1cm',
        paddingBottom: '0.1cm',
        paddingLeft: '0.2cm',
        paddingRight: '0.2cm',
        borderRadius: '100cm',
      }}>
      {label}
    </span>
  );
}

Badge.style = ``;
