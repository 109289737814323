import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import DelayedView from 'src/components/DelayedView';
import Print from 'src/components/Print';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Currency from 'src/utilities/Format';
import Services from 'src/utilities/Services';
import { useAllPromises } from 'src/utilities/useAllPromises';

export default function InvoicesExport() {
  const { repository } = Services.use();

  const timelsape = AppTimelapse.use();

  const invoices = repository.useData(
    () => repository.getInvoices(timelsape),
    [timelsape],
  );

  const payments = repository.useData(async () => {
    const list = await invoices;
    return repository.getInvoicesPayments(list.map((i) => i.id));
  }, [invoices]);

  const all = useAllPromises(invoices, payments);

  return (
    <DelayedView promise={all}>
      {([invoices, payments]) => {
        const data = invoices.map((i) => {
          const ps = payments.filter((p) => p.ref_invoice === i.id);
          const paid = sumBy(ps, (p) => p.amount_without_tax);
          return {
            'Date de facture': dayjs(i.billed_at).format('LL'),
            Libellé: i.label,
            Référence: i.reference,
            Montant: Currency.format(i.amount),
            '% paiement': Currency.percentage(paid / i.amount),
            Paiements: (
              <Print.Lines>
                {ps.map(
                  (p) =>
                    `${Currency.format(p.amount)} TTC le ${dayjs(
                      p.paid_at,
                    ).format('LL')}`,
                )}
              </Print.Lines>
            ),
          };
        });

        const footer = {
          Montant: Currency.format(sumBy(invoices, (i) => i.amount)),
          Paiements: Currency.format(sumBy(payments, (i) => i.amount)) + ' TTC',
        };

        return (
          <Print landscape margin="1cm">
            <Print.Table data={data} footers={footer} />
          </Print>
        );
      }}
    </DelayedView>
  );
}
