/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  mdiArrowUpBold,
  mdiCheck,
  mdiLoading,
  mdiOpenInNew,
  mdiPlus,
  mdiSync,
  mdiTrashCan,
} from '@mdi/js';
import Icon from '@mdi/react';
import axios from 'axios';
import { rgba } from 'polished';
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Css from 'src/utilities/Css';
import useAsyncEffect from 'src/utilities/useAsyncEffect';
import useMemoState from 'src/utilities/useMemoState';
import { useFilePicker } from 'use-file-picker';
import Clickable from '../Clickable';
import Typo from '../Typo';
import FieldContainer from './FieldContainer';
import { useValidateFn, ValidationFn } from './FieldValidation';

type FileData = string;
export type UrlPair = { write: string; read: string };
export type SlotConfig = { type: string; size: number };

type Preview =
  | { type: 'image'; url: string }
  | { type: 'pdf'; url: string }
  | { type: 'unknown'; url: string };

const ContentTypes = {
  image: ['image/jpeg'],
};

type FileFieldConfig<TValidated extends FileData | null> = {
  initialValue: FileData | null | undefined;
  label: string;
  validation?: ValidationFn<FileData | null, TValidated>;
  getUrl: (config: SlotConfig) => Promise<UrlPair>;
};

export default function useFileField<TValidated extends FileData | null>(
  config: FileFieldConfig<TValidated>,
) {
  const { initialValue, label, validation, getUrl } = config;

  const [openPicker, picker] = useFilePicker({ multiple: false });

  const [value, setValue] = useMemoState(() => {
    if (!initialValue) return null;
    return initialValue;
  }, [initialValue]);

  const [preview, setPreview] = useState<Preview | null>(null);

  useAsyncEffect(async () => {
    if (!value) return;
    const response = await axios(value, { responseType: 'blob' });
    const contentType = response.headers['content-type'];
    if (contentType.startsWith('image/')) {
      const url = URL.createObjectURL(response.data);
      setPreview({ type: 'image', url });
    } else if (contentType === 'application/pdf') {
      const url = URL.createObjectURL(response.data);
      setPreview({ type: 'pdf', url });
    } else {
      setPreview({ type: 'unknown', url: value });
    }
  }, [value]);

  const file = useMemo(() => {
    const content = picker.filesContent[0];
    const plain = picker.plainFiles[0];
    if (!content || !plain) return null;
    else return { content, plain };
  }, [picker.plainFiles]);

  const [urls, setUrls] = useState<UrlPair | null>(null);

  useEffect(() => {
    if (!file) return;
    getUrl({ size: file.plain.size, type: file.plain.type }).then(setUrls);
  }, [file]);

  useEffect(() => {
    if (!file || !urls) return;
    axios(urls.write, {
      method: 'PUT',
      data: file.plain,
      headers: { 'Content-Type': file.plain.type },
      onUploadProgress: (e) => {
        console.log(e.loaded / e.total);
      },
    }).then(() => {
      setValue(urls.read);
    });
  }, [file, urls]);

  const onSelectFile = useCallback(() => {
    clear();
    openPicker();
  }, []);

  const clear = useCallback(() => {
    setValue(null);
    setUrls(null);
    setPreview(null);
    picker.clear();
  }, []);

  const containerCss = css(Css.buttonReset, {
    height: 200,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: rgba('black', 0.5),
  });

  const imageCss = css({
    margin: 6,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    flex: 1,
    alignSelf: 'stretch',
  });

  const buttonsCss = css({
    display: 'flex',
    justifyContent: 'flex-end',
    color: rgba('black', 0.5),
  });

  let content: ReactNode;

  if (value) {
    const buttons = (
      <div css={buttonsCss}>
        <Clickable href={value} target="_blank" css={Css.buttonReset}>
          <Icon path={mdiOpenInNew} size={'24px'} />
        </Clickable>
        <Clickable onClick={onSelectFile} css={Css.buttonReset}>
          <Icon path={mdiSync} size={'24px'} />
        </Clickable>
        <Clickable onClick={clear} css={Css.buttonReset}>
          <Icon path={mdiTrashCan} size={'24px'} />
        </Clickable>
      </div>
    );

    if (preview) {
      if (preview.type === 'image') {
        content = (
          <Fragment>
            <div css={containerCss}>
              <div
                css={[
                  imageCss,
                  { backgroundImage: `url(${preview.url})` },
                ]}></div>
            </div>
            {buttons}
          </Fragment>
        );
      } else if (preview.type === 'pdf') {
        content = (
          <Fragment>
            <div css={containerCss}>
              <iframe src={preview.url} css={[imageCss]} title="Preview" />
            </div>
            {buttons}
          </Fragment>
        );
      } else {
        content = (
          <Clickable css={containerCss} href={preview.url}>
            <Icon path={mdiCheck} size={'48px'} />
            <Typo>Fichier chargé</Typo>
          </Clickable>
        );
      }
    } else {
      content = (
        <Clickable css={containerCss} onClick={onSelectFile}>
          <Icon path={mdiLoading} size={'48px'} />
          <Typo>Preview en cours...</Typo>
        </Clickable>
      );
    }
  } else if (!file) {
    content = (
      <Clickable css={containerCss} onClick={onSelectFile}>
        <Icon path={mdiPlus} size={'48px'} />
        <Typo>Sélectionnez un fichier</Typo>
      </Clickable>
    );
  } else if (!urls) {
    content = (
      <Clickable css={containerCss} onClick={onSelectFile}>
        <Icon path={mdiLoading} size={'48px'} />
        <Typo>Préparation de l'upload...</Typo>
      </Clickable>
    );
  } else {
    content = (
      <Clickable css={containerCss} onClick={onSelectFile}>
        <Icon path={mdiArrowUpBold} size={'48px'} />
        <Typo>Upload en cours...</Typo>
      </Clickable>
    );
  }

  const render = () => <FieldContainer label={label}>{content}</FieldContainer>;

  const validate = useValidateFn(value, validation);

  return { render, validate, value };
}
