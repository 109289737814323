/** @jsxImportSource @emotion/react */

import { rgba } from 'polished';
import { Fragment } from 'react';
import Divider from './PrintDivider';

// Table

type HeaderProps = {
  mainTitle: string;
  preTitle?: string;
};

export default function Header(props: HeaderProps) {
  const { mainTitle, preTitle } = props;

  return (
    <Fragment>
      <div id="header">
        <div className="logo"></div>
        <div className="texts">
          <h1>
            {preTitle ? <span className="side-title">{preTitle}</span> : null}
            {mainTitle}
          </h1>
        </div>
      </div>
      <Divider />
    </Fragment>
  );
}

Header.style = `
    #header{
      display : flex ;
      flex-direction : row ;
      padding-bottom : 0.5cm ;
    }
    #header .logo{
      width : 4cm ;
      background-image : url(${
        window.location.origin
      }/valentin-consulting.png) ;
      background-size : contain ;
      background-repeat : no-repeat ;
      background-position : left center ;
    }

    #header .texts{
      flex-grow : 1 ;
      padding-left : 1cm ;
      text-align : right ;
    }

    #header h1{
      padding : 0 ;
      margin : 0 ;
      font-size : 0.6cm ;
      text-transform : uppercase ;
      font-weight : 900 ;
    }

    #header h1 .side-title{
      display : block ;
      font-size : 0.5cm ;
      text-transform : none ;
      font-weight : 200 ;
      color : ${rgba('black', 0.7)} ;
    }
  `;
