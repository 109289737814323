import { useCallback, useEffect } from 'react';
import Model, { MovePayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import { Required } from '../Field/FieldValidation';
import Form from '../Field/Form';
import useCheckboxField from '../Field/useCheckboxField';
import useDateField from '../Field/useDateField';
import useNumberField from '../Field/useNumberField';
import usePlaceField from '../Field/usePlaceField';
import useSelectField from '../Field/useSelectField';

type MoveFormProps = {
  move: Model.Move | null;
  onSubmit: (move: MovePayload) => any;
  onDelete?: () => any;
};

export default function MoveForm(props: MoveFormProps) {
  const { move, onSubmit, onDelete } = props;
  const { repository } = Services.use();

  const vehicles = repository.useData(() => repository.getVehicles(), []);

  const dateField = useDateField({
    label: 'Date',
    initialValue: move ? move.date : null,
    validation: (v) => Required(v),
  });

  const fromField = usePlaceField({
    label: 'Départ',
    initialValue: move ? move.ref_from : null,
    validation: (v) => Required(v),
  });

  const toField = usePlaceField({
    label: 'Arrivée',
    initialValue: move ? move.ref_to : null,
    validation: (v) => Required(v),
  });

  const distanceField = useNumberField({
    label: 'Distance',
    initialValue: move ? move.distance : null,
    validation: (v) => Required(v),
    decimals: 2,
    unit: () => 'km',
  });

  const vehiclesField = useSelectField({
    label: 'Véhicule',
    initialValue: move ? move.ref_vehicle : null,
    validation: (v) => Required(v),
    options: vehicles,
    keyExtractor: (v) => v.id,
    labelExtractor: (v) => v.label,
  });

  const roundTripField = useCheckboxField({
    label: 'Aller retour',
    initialValue: move ? move.round_trip : null,
    validation: (v) => Required(v),
  });

  useEffect(() => {
    const from = fromField.place;
    const to = toField.place;
    if (!from || !to) return;
    changeDistance(from.ref_google, to.ref_google);
  }, [fromField.place, toField.place]);

  const changeDistance = useCallback(async (from: string, to: string) => {
    const res = await repository.axios.get<GoogleMapsRoute | null>(
      '/google-maps/route',
      {
        params: { from, to },
      },
    );
    if (res.data) {
      distanceField.set(res.data.distance / 1000);
    }
  }, []);

  const onSubmitForm = useSubmit(() => {
    const distance = distanceField.validate();
    const date = dateField.validate();
    const from = fromField.validate();
    const to = toField.validate();
    const vehicle = vehiclesField.validate();
    const roundTrip = roundTripField.validate();
    onSubmit({
      distance,
      date,
      ref_from: from,
      ref_to: to,
      ref_vehicle: vehicle,
      round_trip: roundTrip,
    });
  }, []);

  return (
    <Form onSubmit={onSubmitForm} onRemove={onDelete}>
      {dateField.render()}
      {fromField.render()}
      {toField.render()}
      {distanceField.render()}
      {vehiclesField.render()}
      {roundTripField.render()}
    </Form>
  );
}

export type GoogleMapsRoute = {
  distance: number;
};
