/** @jsxImportSource @emotion/react */

// Title

type TitleProps = {
  children: string;
};

export default function Title(props: TitleProps) {
  return <h2>• {props.children}</h2>;
}

Title.style = `
    h2 {
      font-size : 0.6cm ;
      font-weight : 700 ;
      margin : 0 ;
      padding : 0 ;
    }
  `;
