import dayjs from 'dayjs';
import { without } from 'lodash';
import objectHash from 'object-hash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import Model, { ContractPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import { Required } from '../Field/FieldValidation';
import Form from '../Field/Form';
import useDateField from '../Field/useDateField';
import useFileField, { UrlPair } from '../Field/useFileField';
import useNumberField from '../Field/useNumberField';
import useSelectField from '../Field/useSelectField';
import useTextField from '../Field/useTextField';
import Panel from '../Panel';
import ClientItem from '../items/ClientItem';
import PurposeItem from '../items/PurposeItem';
import DaysPurposeForm from './DaysPurposeForm';
import ExtraPurposeForm from './ExtraPurposeForm';

type ContractFormProps = {
  contract: Model.Contract | null;
  onSubmit: (contract: ContractPayload) => any;
  onDelete?: () => any;
};

export default function ContractForm(props: ContractFormProps) {
  const { contract, onSubmit, onDelete } = props;
  const { repository } = Services.use();

  const clients = repository.useData(() => repository.getClients(), []);

  const labelField = useTextField({
    label: 'Label',
    initialValue: contract ? contract.label : null,
    validation: (v) => Required(v),
  });

  const startField = useDateField({
    label: 'Début',
    initialValue: contract ? contract.start : null,
    validation: (v) => Required(v),
  });

  const endField = useDateField({
    label: 'Fin',
    initialValue: contract ? contract.end : null,
    validation: (v) => Required(v),
  });

  const signedField = useDateField({
    label: 'Date de signature',
    initialValue: contract ? contract.signed : dayjs().format('YYYY-MM-DD'),
    validation: (v) => Required(v),
  });

  const hourPriceField = useNumberField({
    label: 'Tarif horaire',
    initialValue: contract ? contract.hour_price : null,
    unit: (v) => '€/h',
    decimals: 2,
    validation: (p) => Required(p),
  });

  const fileField = useFileField({
    label: 'Fichier',
    initialValue: contract ? contract.file : null,
    getUrl: async (slot) =>
      (await repository.axios.post<UrlPair>('/contract-files', slot)).data,
    validation: (p) => Required(p),
  });

  const clientField = useSelectField({
    label: 'Client',
    initialValue: contract ? contract.ref_client : null,
    options: clients,
    keyExtractor: (c) => c.id,
    labelExtractor: (c) => c.name,
    itemExtractor: (c) => <ClientItem client={c} />,
    validation: (p) => Required(p),
  });

  const [purposes, setPurposes] = useState<Array<Model.Purpose>>(
    contract ? contract.purposes : [],
  );
  const purposeKey = useMemo(() => objectHash(purposes), [purposes]);
  const [purposeType, setPurposeType] = useState<Model.PurposeType>('days');
  const addPurpose = useCallback((p: Model.Purpose) => {
    setPurposes((ps) => [...ps, p]);
  }, []);
  const removePurpose = useCallback((p: Model.Purpose) => {
    setPurposes((ps) => without(ps, p));
  }, []);

  const purposeTabs = useMemo(() => {
    return [
      { type: 'days' as const, label: 'Jours' },
      { type: 'extra' as const, label: 'Extra' },
    ];
  }, []);

  const onSubmitForm = useSubmit(() => {
    const label = labelField.validate();
    const start = startField.validate();
    const end = endField.validate();
    const signed = signedField.validate();
    const ref_client = clientField.validate();
    const hour_price = hourPriceField.validate();
    const file = fileField.validate();
    console.log({
      label,
      start,
      end,
      signed,
      ref_client,
      purposes,
      file,
    });
    onSubmit({
      label,
      start,
      end,
      signed,
      ref_client,
      purposes,
      hour_price,
      file,
    });
  }, [purposes]);

  return (
    <Fragment>
      <Form onSubmit={onSubmitForm} onRemove={onDelete}>
        {labelField.render()}
        {clientField.render()}
        {startField.render()}
        {endField.render()}
        {hourPriceField.render()}
        {signedField.render()}
        {fileField.render()}
        <Panel.List
          items={purposes}
          getKey={(p) => objectHash(p)}
          renderItem={(p) => (
            <PurposeItem purpose={p} onDelete={() => removePurpose(p)} />
          )}
        />
      </Form>
      <Panel.Tabs
        tabs={purposeTabs}
        value={purposeType}
        onChange={setPurposeType}
        keyExtractor={(t) => t.type}
        labelExtractor={(t) => t.label}
      />
      {purposeType === 'days' ? (
        <DaysPurposeForm
          key={`days-${purposeKey}`}
          purpose={{}}
          onSubmit={addPurpose}
        />
      ) : (
        <ExtraPurposeForm
          key={`days-${purposeKey}`}
          purpose={{}}
          onSubmit={addPurpose}
        />
      )}
    </Fragment>
  );
}
