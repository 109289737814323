import { rgba } from 'polished';

const Layout = {
  datesColumnWidth: 200,
  projetColumnWidth: 100,
  dateLineHeight: 40,
  headerHeight: 60,
  footerHeight: 60,
  background1Cell: '#ffffff',
  background2Cells: '#f2f2f2',
  background3Cells: '#d9d9d9',
  timeSelector: ['white', 'green'],
  borderColor: rgba('black', 0.1),
  activeCell: rgba('darkgreen', 0.1),
  weekend: rgba('darkblue', 0.1),
  S: 10,
};

export default Layout;
