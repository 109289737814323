/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typo from 'src/components/Typo';
import Css from 'src/utilities/Css';
import Holidays from 'src/utilities/Holidays';
import overlapColors from 'src/utilities/overlapColors';
import Theme from 'src/utilities/Theme';
import Time, { IsoDay } from 'src/utilities/Time';
import Timelapses from 'src/utilities/Timelapses';

type DayCellProps = {
  day: IsoDay;
};

export default function DayCell(props: DayCellProps) {
  const { day } = props;
  const date = Timelapses.useDayDate(day);
  const isOnHolyday = Holidays.useIsHolyday(Time.parseDay(day));
  // const controller = useGridController<Model.Project, IsoDay>()
  //const onAxes = controller.useIsOnSelectionAxes(undefined, day);

  let background = overlapColors('white', Theme.useColor(1));
  // if (onAxes) background = overlapColors(background, Theme.useColor(1));
  if (isOnHolyday) background = overlapColors(background, Theme.useColor(1));

  const containerCss = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    &:before {
      content: ' ';
      ${Css.absoluteFill};
      z-index: -1;
      background-color: ${background};
      border-right: 1px solid ${Theme.useColor(2)};
      border-bottom: 1px solid ${Theme.useColor(2)};
    }
  `;

  return (
    <div css={containerCss}>
      <Typo typo="minor">{Time.format(date, 'EEEE')}</Typo>
      <Typo>{Time.format(date, 'd MMM')}</Typo>
    </div>
  );
}
