/** @jsxImportSource @emotion/react */

import { rgba } from 'polished';
import { PropsWithChildren } from 'react';

// Table

type InfosProps = PropsWithChildren<{}>;

export default function Infos(props: InfosProps) {
  const { children } = props;

  return <div className="infos">{children}</div>;
}

type InfoProps = { label: string; value: string; image?: string };

export function PrintInfo(props: InfoProps) {
  const { label, value, image } = props;

  return (
    <div className="info">
      <div className="contents">
        {image ? (
          <div className="image" style={{ backgroundImage: `url(${image})` }} />
        ) : null}
        <div className="value">{value}</div>
      </div>
      <div className="label">{label}</div>
    </div>
  );
}

Infos.style = `
    .infos{
      display : flex ;
      flex-direction : row ;
      align-items : flex-end ;
      justify-content : center ;
      flex-wrap : wrap ;
    }

    .infos .info{
      padding-right : 0.5cm ;
      padding-left : 0.5cm ;
      text-align : center ;
    }

    .infos .info .label{
      white-space : no-wrap ;
    }

    .infos .info .contents{
      border-bottom : 1px solid ${rgba('black', 0.2)} ;
      margin-bottom : 0.1cm ;
      padding-bottom : 0.1cm ;
      display : flex ;
      flex-direction : column ;
      align-items : center ;
    }

    .infos .info .contents .value{
      font-size : 0.4cm ;
      font-weight : 600 ;
    }

    .infos .info .contents .image{
      align-self : center ;
      width : 1cm ;
      height : 1cm ;
      background-position : center ;
      background-size : contain ;
      background-repeat : no-repeat ;
    }

  `;
