import { mdiPlus } from '@mdi/js';
import { sumBy } from 'lodash';
import { Fragment, useCallback } from 'react';
import DelayedView from 'src/components/DelayedView';
import PaymentItem from 'src/components/items/PaymentItem';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Spacer from 'src/components/Spacer';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Currency from 'src/utilities/Format';
import Model from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import PaymentCreationPanel from './PaymentCreationPanel';
import PaymentEditionPanel from './PaymentEditionPanel';

export default function PaymentsListPanel() {
  const { repository } = Services.use();
  const timelapse = AppTimelapse.use();
  const panels = usePanels();

  const payments = repository.useData(
    () => repository.getPayments(timelapse),
    [timelapse],
  );

  const onCreatePayment = useCallback(() => {
    panels.push(PaymentCreationPanel, {});
  }, []);

  const onClickPayment = useCallback((p: Model.Payment) => {
    panels.push(PaymentEditionPanel, { payment: p.id });
  }, []);

  return (
    <Panel.Container>
      <Panel.Header title="Paiements"></Panel.Header>
      <Panel.MainAction icon={mdiPlus} onClick={onCreatePayment} />
      <DelayedView promise={payments}>
        {(payments) => (
          <Fragment>
            <Panel.List
              items={payments}
              getKey={(p) => p.id}
              renderItem={(p) => (
                <PaymentItem payment={p} onClick={() => onClickPayment(p)} />
              )}
            />
            <Spacer />
            <Panel.Indicator
              label="Total perçu HT"
              value={
                Currency.format(sumBy(payments, (p) => p.amount_without_tax)) +
                ' HT'
              }
            />
            <Spacer />
            <Panel.Indicator
              label="Total perçu TTC"
              value={Currency.format(sumBy(payments, (p) => p.amount))}
            />
          </Fragment>
        )}
      </DelayedView>
    </Panel.Container>
  );
}
