/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { rgba } from 'polished';
import React, {
  createContext,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import Css from 'src/utilities/Css';
import useBooleanState from 'src/utilities/useBooleanState';
import Clickable, { Reaction } from '../Clickable';
import Spacer from '../Spacer';
import Typo from '../Typo';
import ListItem from './ListItem';

type PanelListProps<TItem> = PropsWithChildren<{
  label?: string;
  items: Array<TItem>;
  getKey: (item: TItem, i: number) => string;
  renderItem: (item: TItem) => string | ReactNode;
  extraItem?: ReactNode;
  onCreate?: () => any;
  total?: string;
}>;

export default function PanelList<TItem>(props: PanelListProps<TItem>) {
  const {
    items,
    label,
    getKey,
    extraItem,
    renderItem,
    onCreate,
    total,
    children,
  } = props;

  const containerCss = css({
    marginInline: 6,
  });

  const listCss = css({
    padding: 0,
    border: `1px solid ${rgba('black', 0.25)}`,
    borderRadius: 4,
    background: rgba('black', 0.02),
    display: 'flex',
    flexDirection: 'column',
  });

  const headerCss = css({
    display: 'flex',
  });

  const creationPortalRef = useRef<HTMLDivElement>(null);

  const headerNode = (
    <div css={headerCss}>
      {label ? <Typo typo="subheading">{label}</Typo> : null}
      <Spacer grow />
      <div ref={creationPortalRef} />
    </div>
  );

  let totalNode: ReactNode;
  if (total) {
    totalNode = <ListItem label="Total" rightText={total} />;
  }

  return (
    <div css={containerCss}>
      {headerNode}
      <div css={listCss}>
        {items.map((i, n) => {
          const key = getKey(i, n);
          let output = renderItem(i);
          if (typeof output === 'string') output = <ListItem label={output} />;
          return <Fragment key={key}>{output}</Fragment>;
        })}
        {extraItem ? extraItem : null}
        {totalNode}
      </div>
      <CreationButtonPortalRef.Provider value={creationPortalRef}>
        {children}
        {onCreate ? <PanelListCreation onClick={onCreate} /> : null}
      </CreationButtonPortalRef.Provider>
    </div>
  );
}

function PanelListCreation(props: Reaction) {
  const context = useContext(CreationButtonPortalRef);
  usePostMountRender();
  if (!context) return <Fragment />;
  const element = context.current;
  if (!element) return <Fragment />;
  return ReactDOM.createPortal(
    <Clickable css={Css.buttonReset} {...props}>
      <Icon path={mdiPlus} size={'24px'} />
    </Clickable>,
    element,
  );
}

PanelList.Creation = PanelListCreation;

const CreationButtonPortalRef =
  createContext<React.RefObject<HTMLElement> | null>(null);

function usePostMountRender() {
  const [mounted, setMounted] = useBooleanState(false);
  useEffect(setMounted.toTrue, []);
  return mounted;
}
