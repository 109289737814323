import { Fragment, useCallback } from 'react';
import DelayedView from 'src/components/DelayedView';
import ProjectForm from 'src/components/forms/ProjectForm';
import TopicItem from 'src/components/items/TopicItem';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Model, { ProjectPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import TopicCreationPanel from '../Topics/TopicCreationPanel';
import TopicEditionPanel from '../Topics/TopicEditionPanel';

type ProjectEditionPanelProps = {
  project: string;
};

export default function ProjectEditionPanel(props: ProjectEditionPanelProps) {
  const id = props.project;
  const { repository } = Services.use();
  const panels = usePanels();
  const project = repository.useData(() => repository.getProject(id), [id]);
  const topics = repository.useData(
    () => repository.getProjectTopics(id),
    [id],
  );

  const onCreateTopic = useCallback(() => {
    panels.push(TopicCreationPanel, { ref_project: id });
  }, [id]);

  const onEditTopic = useCallback(
    (topic: Model.Topic) => {
      panels.push(TopicEditionPanel, { topic: topic.id });
    },
    [id],
  );

  const onSubmit = useSubmit(
    async (paylaod: ProjectPayload) => {
      await repository.axios.patch(`/projects/${id}`, paylaod);
      repository.invalidate(repository.storage.searchKeys(id, 'projects'));
    },
    [id],
  );

  return (
    <Panel.Container>
      <DelayedView promise={project}>
        {(project) => (
          <Fragment>
            <Panel.Header title={project.name} />
            <ProjectForm project={project} onSubmit={onSubmit} />
            <DelayedView promise={topics}>
              {(topics) => {
                return (
                  <Fragment>
                    <Panel.List
                      label="Sujets"
                      items={topics}
                      getKey={(t) => t.id}
                      renderItem={(t) => (
                        <TopicItem topic={t} onClick={() => onEditTopic(t)} />
                      )}
                      onCreate={onCreateTopic}
                    />
                  </Fragment>
                );
              }}
            </DelayedView>
          </Fragment>
        )}
      </DelayedView>
    </Panel.Container>
  );
}
