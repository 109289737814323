import Time from './Time';
import Updater from './Updater';

type EtalabHolidays = { [day: string]: string };

const updater = new Updater();
let days: EtalabHolidays = {};

async function init() {
  const result = await fetch(
    'https://etalab.github.io/jours-feries-france-data/json/metropole.json',
  );
  days = (await result.json()) as EtalabHolidays;
  updater.update();
}

function useIsHolyday(date: Date) {
  const list = updater.useValue(() => days);
  if (Time.isWeekend(date)) return true;
  return Object.keys(list).includes(Time.format(date, 'yyyy-MM-dd'));
}

function isHolyday(date: Date) {
  if (Time.isWeekend(date)) return true;
  return Object.keys(days).includes(Time.format(date, 'yyyy-MM-dd'));
}

const Holidays = {
  init,
  useIsHolyday,
  isHolyday,
};

export default Holidays;
