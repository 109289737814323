/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { PropsWithChildren } from 'react';
import Button from 'src/components/Button';
import Clickable from 'src/components/Clickable';
import DelayedView from 'src/components/DelayedView';
import MoveInfoItem from 'src/components/items/MoveInfoItem';
import Panel from 'src/components/Panel';
import Spacer from 'src/components/Spacer';
import Typo from 'src/components/Typo';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Css from 'src/utilities/Css';
import Zones, { Routing } from 'src/utilities/Routes';
import Services from 'src/utilities/Services';

export default function Moves() {
  const { repository } = Services.use();
  const timelapse = AppTimelapse.use();
  const moves = repository.useData(
    () => repository.getMoves(timelapse),
    [timelapse],
  );

  return (
    <Page>
      <PageHeader title="Déplacements" />
      <DelayedView promise={moves}>
        {(moves) => {
          return (
            <Panel.List
              items={moves}
              renderItem={(m) => (
                <MoveInfoItem
                  move={m}
                  to={Routing.getPath(Zones.MoveEdition, { move: m.id })}
                />
              )}
              getKey={(m) => m.id}>
              <Panel.List.Creation to={Routing.getPath(Zones.MoveCreation)} />
            </Panel.List>
          );
        }}
      </DelayedView>
      <Spacer />
      <Button to={Routing.getPath(Zones.MovesExport)} label="Exporter" />
    </Page>
  );
}

type PageProps = PropsWithChildren<{}>;

export function Page(props: PageProps) {
  const containerCss = css({
    paddingInline: 20,
    paddingTop: 20,
    paddingBottom: 20,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  });

  const innerCss = css({
    background: 'white',
    borderRadius: 4,
    flexGrow: 1,
    boxShadow: `0px 0px 100px rgba(0,0,0,0.2)`,
  });

  return (
    <div css={containerCss}>
      <div css={innerCss}>{props.children}</div>
    </div>
  );
}

export type PageHeaderProps = {
  title: string;
};

function PageHeader(props: PageHeaderProps) {
  const { title } = props;

  const headerCss = css({
    padding: 20,
    display: 'flex',
    alignItems: 'center',
  });

  return (
    <div css={headerCss}>
      <Clickable onClick={() => {}} css={Css.buttonReset}>
        <Icon path={mdiArrowLeft} size={'24px'} />
      </Clickable>
      <Spacer />
      <Typo typo="heading">{title}</Typo>
    </div>
  );
}

Page.Header = PageHeader;
