import ProjectionForm from 'src/components/forms/ProjectionForm';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Model, { ProjectionPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import ProjectionEditionPanel from './ProjectionEditionPanel';

export default function ProjectionCreationPanel() {
  const { repository } = Services.use();
  const panels = usePanels();

  const onSubmit = useSubmit(async (payload: ProjectionPayload) => {
    const r = await repository.axios.post<Model.Projection>(
      '/projections',
      payload,
    );
    repository.invalidate(repository.storage.searchKeys('projections'));
    panels.replace(ProjectionEditionPanel, { projection: r.data.id });
  }, []);

  return (
    <Panel.Container>
      <Panel.Header title="Nouvelle projection" />
      <ProjectionForm projection={null} onSubmit={onSubmit} />
    </Panel.Container>
  );
}
