import Model, { InvoicePayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import { Required } from '../Field/FieldValidation';
import Form from '../Field/Form';
import useDateField from '../Field/useDateField';
import useFileField from '../Field/useFileField';
import useNumberField from '../Field/useNumberField';
import useSelectField from '../Field/useSelectField';
import useTextField from '../Field/useTextField';
import ProjectItem from '../items/ProjectItem';

type InvoiceFormProps = {
  invoice: Model.Invoice | null;
  onSubmit: (invoice: InvoicePayload) => any;
  onDelete?: () => any;
};

export default function InvoiceForm(props: InvoiceFormProps) {
  const { invoice, onSubmit, onDelete } = props;
  const { repository } = Services.use();

  const projects = repository.useData(() => repository.getProjects(), []);

  const labelField = useTextField({
    label: 'Libélle',
    initialValue: invoice ? invoice.label : null,
    validation: (v) => Required(v),
  });

  const referenceField = useTextField({
    label: 'Référence',
    initialValue: invoice ? invoice.reference : null,
    validation: (v) => Required(v),
  });

  const amountField = useNumberField({
    label: 'Montant HT',
    initialValue: invoice ? invoice.amount : null,
    decimals: 2,
    unit: (n) => ' €',
    validation: (v) => Required(v),
  });

  const taxRateField = useNumberField({
    label: 'Taux de TVA',
    initialValue: invoice ? invoice.tax_rate : null,
    decimals: 1,
    unit: (n) => '%',
    validation: (v) => Required(v),
  });

  const billedAtField = useDateField({
    label: 'Date de facturation',
    initialValue: invoice ? invoice.billed_at : null,
    validation: (v) => Required(v),
  });

  const paymentLimitField = useDateField({
    label: 'Date de paiement',
    initialValue: invoice ? invoice.payment_limit : null,
    validation: (v) => Required(v),
  });

  const projectField = useSelectField({
    label: 'Projet',
    initialValue: invoice ? invoice.ref_project : null,
    options: projects,
    keyExtractor: (p) => p.id,
    labelExtractor: (p) => p.name,
    itemExtractor: (p) => <ProjectItem project={p} />,
    validation: (p) => Required(p),
  });

  const fileField = useFileField({
    label: 'Fichier',
    initialValue: invoice ? invoice.file : null,
    getUrl: (slot) => repository.createInvoiceFile(slot),
    validation: (p) => Required(p),
  });

  const onSubmitForm = useSubmit(() => {
    const label = labelField.validate();
    const reference = referenceField.validate();
    const amount = amountField.validate();
    const tax_rate = taxRateField.validate();
    const billed_at = billedAtField.validate();
    const payment_limit = paymentLimitField.validate();
    const ref_project = projectField.validate();
    const file = fileField.validate();
    onSubmit({
      label,
      reference,
      ref_project,
      amount,
      tax_rate,
      billed_at,
      payment_limit,
      file,
    });
  }, []);

  return (
    <Form onSubmit={onSubmitForm} onRemove={onDelete}>
      {labelField.render()}
      {referenceField.render()}
      {amountField.render()}
      {taxRateField.render()}
      {billedAtField.render()}
      {paymentLimitField.render()}
      {projectField.render()}
      {fileField.render()}
    </Form>
  );
}
