/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import DelayedView from 'src/components/DelayedView';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Model from 'src/utilities/Model';
import Services from 'src/utilities/Services';

type GridRow = {
  client: Model.Client;
  project: Model.Project | null;
  projection: number;
  contract: number | null;
  achieved: number;
};

type GridColumn = 'Projections' | 'Contracts' | 'Achieved';

export default function YearPerformances() {
  const { repository } = Services.use();

  const timelapse = AppTimelapse.use();
  const reportings = repository.useData(
    () =>
      repository.axios.get<Array<Reporting>>('reportings', {
        params: { timelapse },
      }),
    [timelapse],
  );

  return (
    <DelayedView promise={reportings}>
      {(reportings) => {
        console.table(
          reportings.map((r) => ({
            ...r,
            client: r.client.name,
            project: r.project ? r.project.name : '*',
          })),
        );
        return <Fragment></Fragment>;
      }}
    </DelayedView>
  );

  return null;
}

type Reporting = {
  client: Model.Client;
  project: Model.Project | null;
  projected: number;
  done: number;
  invoiced: number;
  paid: number;
};
// export default function YearPerformances() {
//   const { store } = Services.use();
//   const year = Routing.useParam('timelapse');

//   useEffect(() => {
//     store.loadProjections({ year });
//     store.loadContracts();
//     store.loadTasks(null, year);
//     store.loadExtras(null, year);
//   }, []);

//   const
//   const projections = store.useProjections({ year });
//   const contracts = store.useContracts();
//   const tasks = store.useTasks({ timelapse: '2022' });
//   const extras = store.useExtras({ timelapse: '2022' });

//   const rows = useMemo<Array<RowConfig<GridRow>>>(() => {
//     const output: Array<RowConfig<GridRow>> = [];

//     clients.forEach((client) => {
//       const clientProjects = projects.filter((p) => p.ref_client === client.id);
//       if (clientProjects.length === 0) return;
//       const clientProjectsIds = clientProjects.map((p) => p.id);
//       const clientProjections = projections.filter((p) =>
//         clientProjectsIds.includes(p.ref_project),
//       );
//       const projection = sumBy(
//         clientProjections,
//         (p) => p.quantity * p.unit_price,
//       );
//       const clientContracts = contracts.filter(
//         (c) => c.ref_client === client.id,
//       );
//       const contract = sumBy(clientContracts, (c) => {
//         let sum = c.extras || 0;
//         if (c.hours !== null) sum += (c.hours / c.daily_hours) * c.daily_price;
//         return sum;
//       });
//       const done = getPerf({ projects: clientProjects, tasks, extras });

//       output.push({
//         id: client.id,
//         data: {
//           client,
//           project: null,
//           projection,
//           contract,
//           achieved: done.perf,
//         },
//       });

//       clientProjects.forEach((project) => {
//         const projectProjections = projections.filter(
//           (p) => p.ref_project === project.id,
//         );
//         const projection = sumBy(
//           projectProjections,
//           (p) => p.quantity * p.unit_price,
//         );
//         const done = getPerf({ projects: [project], tasks, extras });
//         output.push({
//           id: project.id,
//           data: {
//             client,
//             project,
//             projection,
//             contract: null,
//             achieved: done.perf,
//           },
//         });
//       });
//     });

//     return output;
//   }, [clients, projects, projections, extras, tasks]);

//   const columns = useMemo<Array<ColumnConfig<GridColumn>>>(() => {
//     const output: Array<ColumnConfig<GridColumn>> = [
//       {
//         id: 'Projections',
//         data: 'Projections',
//         width: 150,
//       },
//       {
//         id: 'Contracts',
//         data: 'Contracts',
//         width: 150,
//       },
//       {
//         id: 'Achieved',
//         data: 'Achieved',
//         width: 150,
//       },
//     ];

//     return output;
//   }, [clients, projects]);

//   const sidesColumns = useMemo<Array<SideColumnConfig<GridRow>>>(() => {
//     const output: Array<SideColumnConfig<GridRow>> = [
//       {
//         id: 'Projection',
//         width: 300,
//         side: 'left',
//         CellComponent: LeftCell,
//       },
//     ];
//     return output;
//   }, [clients, projects]);

//   const endsRows = useMemo<Array<EndRowConfig<GridColumn>>>(() => {
//     const output: Array<EndRowConfig<GridColumn>> = [
//       {
//         id: 'Header',
//         end: 'top',
//         CellComponent: HeaderCell,
//       },
//       {
//         id: 'Footer',
//         end: 'bottom',
//         CellComponent: (p) => <FooterCell {...p} rows={rows} />,
//       },
//     ];
//     return output;
//   }, [clients, projects, rows]);

//   return (
//     <Grid
//       rows={rows}
//       columns={columns}
//       CellComponent={GridCell}
//       sidesColumns={sidesColumns}
//       endsRows={endsRows}
//     />
//   );
// }

// function LeftCell(
//   props: CellComponnentProps<SideColumnConfig<GridRow>, RowConfig<GridRow>>,
// ) {
//   const { row } = props;
//   const text = row.data.project
//     ? `-> ${row.data.project.name}`
//     : row.data.client.name;
//   return (
//     <div>
//       <Typo>{text}</Typo>
//     </div>
//   );
// }

// function HeaderCell(
//   props: CellComponnentProps<
//     ColumnConfig<GridColumn>,
//     EndRowConfig<GridColumn>
//   >,
// ) {
//   const { column } = props;

//   let text: string = '';
//   if (column.data === 'Projections') text = 'Annoncé';
//   else if (column.data === 'Contracts') text = 'Signé';
//   else if (column.data === 'Achieved') text = 'Realisé';
//   return (
//     <div>
//       <Typo>{text}</Typo>
//     </div>
//   );
// }

// function FooterCell(
//   props: CellComponnentProps<
//     ColumnConfig<GridColumn>,
//     EndRowConfig<GridColumn>
//   > & { rows: Array<RowConfig<GridRow>> },
// ) {
//   const { column, rows } = props;

//   if (column.data === 'Projections') {
//     const value = sumBy(rows, (r) => (r.data.project ? 0 : r.data.projection));
//     return (
//       <div>
//         <Typo>{Currency.format(value)}</Typo>
//       </div>
//     );
//   } else if (column.data === 'Contracts') {
//     const value = sumBy(rows, (r) => (r.data.contract ? r.data.contract : 0));
//     return (
//       <div>
//         <Typo>{Currency.format(value)}</Typo>
//       </div>
//     );
//   } else if (column.data === 'Achieved') {
//     const value = sumBy(rows, (r) => (r.data.project ? 0 : r.data.achieved));
//     return (
//       <div>
//         <Typo>{Currency.format(value)}</Typo>
//       </div>
//     );
//   } else {
//     return null;
//   }
// }

// function GridCell(
//   props: CellComponnentProps<ColumnConfig<GridColumn>, RowConfig<GridRow>>,
// ) {
//   const { column, row } = props;
//   if (column.data === 'Projections') {
//     return (
//       <div>
//         <Typo>{Currency.format(row.data.projection)}</Typo>
//       </div>
//     );
//   } else if (column.data === 'Contracts') {
//     return (
//       <div>
//         {row.data.contract !== null ? (
//           <Typo>{Currency.format(row.data.contract)}</Typo>
//         ) : null}
//       </div>
//     );
//   } else if (column.data === 'Achieved') {
//     return (
//       <div>
//         <Typo>{Currency.format(row.data.achieved)}</Typo>
//       </div>
//     );
//   } else {
//     return null;
//   }
// }
