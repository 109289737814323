import ContractForm from 'src/components/forms/ContractForm';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Model, { ContractPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import ContractEditionPanel from './ContractEditionPanel';

export default function ContractCreationPanel() {
  const { repository } = Services.use();
  const panels = usePanels();
  const onSubmitContract = useSubmit(async (contract: ContractPayload) => {
    const response = await repository.axios.post<Model.Contract>(
      '/contracts',
      contract,
    );
    panels.replace(ContractEditionPanel, { contract: response.data.id });
    repository.invalidate(repository.storage.searchKeys('contracts'));
  }, []);
  return (
    <Panel.Container>
      <Panel.Header title="Nouveau contrat" />
      <ContractForm contract={null} onSubmit={onSubmitContract} />
    </Panel.Container>
  );
}
