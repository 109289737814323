import PaymentForm from 'src/components/forms/PaymentForm';
import Panel from 'src/components/Panel';
import Model, { Payload, PaymentPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';

export default function PaymentCreationPanel() {
  const { repository } = Services.use();

  const onSubmit = useSubmit(async (data: Payload<PaymentPayload>) => {
    await repository.axios.post<Model.Payment>('/payments', data);
    console.log(repository.storage.searchKeys('payments'));
    repository.invalidate(repository.storage.searchKeys('payments'));
  }, []);

  return (
    <Panel.Container>
      <Panel.Header title="Nouveau paiement" />
      <PaymentForm payment={null} onSubmit={onSubmit} />
    </Panel.Container>
  );
}
