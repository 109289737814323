/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { rgba } from 'polished';
import { PropsWithChildren } from 'react';
import useResponsive from 'src/utilities/useResponsive';

type PanelContainerProps = PropsWithChildren<{}>;

export function PanelContainer(props: PanelContainerProps) {
  const Component = useResponsive({
    1200: MobileContainer,
    bigger: DesktopContainer,
  });

  return <Component {...props} />;
}

function DesktopContainer(props: PanelContainerProps) {
  const wrapperCss = css({
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  });

  const containerCss = css({
    background: 'white',
    flex: 1,
    width: 500,
    borderRadius: 4,
    border: `1px solid ${rgba('black', 0.25)}`,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flexShrink: 0,
    overflow: 'auto',
  });

  const contentCss = css({
    flexGrow: 1,
    overflow: 'auto',
  });

  return (
    <div css={wrapperCss}>
      <div css={containerCss}>
        <div css={contentCss}>{props.children}</div>
      </div>
    </div>
  );
}

function MobileContainer(props: PanelContainerProps) {
  const wrapperCss = css({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  });

  const containerCss = css({
    background: 'white',
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flexShrink: 0,
  });

  const contentCss = css({
    flexGrow: 1,
    overflow: 'auto',
  });

  return (
    <div css={wrapperCss}>
      <div css={containerCss}>
        <div css={contentCss}>{props.children}</div>
      </div>
    </div>
  );
}
