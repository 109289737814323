import { css } from '@emotion/react';
import Css from 'src/utilities/Css';
import Typos from 'src/utilities/Typos';

const containerCss = css`
  border: 1px solid grey;
  border-radius: 4px;
  margin: 4px;
  background-color: white;
`;

const labelCss = css`
  display: block;
  opacity: 0.5;
  padding: 4px;
  padding-bottom: 0px;
`;

const inputCss = css`
  ${Css.inputReset};
  ${Typos.body};
  padding: 4px;
  width: 100%;
`;

const fieldStyles = { containerCss, labelCss, inputCss };

export default fieldStyles;
