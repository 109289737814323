/** @jsxImportSource @emotion/react */

import { useCallback } from 'react';
import DelayedView from 'src/components/DelayedView';
import ProjectionItem from 'src/components/items/ProjectionItem';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Model from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import ProjectionCreationPanel from './ProjectionCreationPanel';
import ProjectionEditionPanel from './ProjectionEditionPanel';

export default function ProjectionsListPanel() {
  const timelapse = AppTimelapse.use();
  const { repository } = Services.use();
  const panels = usePanels();

  const projections = repository.useData(
    () => repository.getProjections(timelapse),
    [timelapse],
  );

  const onCreate = useCallback(() => {
    panels.push(ProjectionCreationPanel, {});
  }, []);

  const onClickProjection = useCallback((projection: Model.Projection) => {
    panels.push(ProjectionEditionPanel, { projection: projection.id });
  }, []);

  return (
    <Panel.Container>
      <Panel.Header title="Projections" />
      <DelayedView promise={projections}>
        {(projections) => {
          return (
            <Panel.List
              items={projections}
              renderItem={(p) => (
                <ProjectionItem
                  projection={p}
                  timelapse={timelapse}
                  onClick={() => onClickProjection(p)}
                />
              )}
              getKey={(p) => p.id}
              onCreate={onCreate}
            />
          );
        }}
      </DelayedView>
    </Panel.Container>
  );
}

//   const { store, api, repository } = Services.use();

//   const year = Routing.useParam('year');

//   useEffect(() => {
//     store.loadProjections({ year });
//   }, []);

//   const clients = repository.useData(() => repository.getClients(), []);
//   const projects = repository.useData(() => repository.getProjects(), []);
//   const projections = store.useProjections({ year });

//   const [createProjection, creating] = useAsyncCallback(
//     async (project: Model.Project) => {
//       const projection = await api.createProjection(year, project.id);
//       store.mergeProjections([projection]);
//     },
//     [year],
//   );

//   const renderProjection = useCallback((projection: Model.Projection) => {
//     return <Projection key={projection.id} projection={projection} />;
//   }, []);

//   const renderProject = useCallback(
//     (project: Model.Project) => {
//       const projectProjections = projections.filter(
//         (p) => p.ref_project === project.id,
//       );
//       return (
//         <Section label={project.name} key={project.id}>
//           <div>
//             {projectProjections.map(renderProjection)}
//             <Button label="+" onClick={() => createProjection(project)} />
//           </div>
//         </Section>
//       );
//     },
//     [projections, renderProjection],
//   );

//   const renderClient = useCallback(
//     (client: Model.Client, projects: Array<Model.Project>) => {
//       const clientProjects = projects.filter((p) => p.ref_client === client.id);
//       return (
//         <Section label={client.name} key={client.id}>
//           {clientProjects.map(renderProject)}
//         </Section>
//       );
//     },
//     [projects, renderProject],
//   );

//   return (
//     <DelayedView promise={clients}>
//       {(clients) => (
//         <DelayedView promise={projects}>
//           {(projects) => (
//             <div>{clients.map((c) => renderClient(c, projects))}</div>
//           )}
//         </DelayedView>
//       )}
//     </DelayedView>
//   );
// }

// type ProjectionItemProps = {
//   projection: Model.Projection;
// };

// function Projection(props: ProjectionItemProps) {
//   const { api, store } = Services.use();
//   const { projection } = props;

//   const [label, setLabel] = useState(projection.label);
//   const [quantity, setQuantity] = useState<number | null>(projection.quantity);
//   const [unitPrice, setUnitPrice] = useState<number | null>(
//     projection.unit_price,
//   );

//   const [updateProjection, updating] = useAsyncCallback(async () => {
//     if (quantity === null) return;
//     if (unitPrice === null) return;
//     const newProjection = await api.updateProjection(
//       projection.id,
//       label,
//       quantity,
//       unitPrice,
//     );
//     store.mergeProjections([newProjection]);
//   }, [label, quantity, unitPrice]);

//   const [deleteProjection, deleting] = useAsyncCallback(async () => {
//     await api.deleteProjection(projection.id);
//     store.deleteProjections([projection.id]);
//   }, [projection]);

//   return (
//     <Item>
//       <TextField label="Libellé" value={label} onChange={setLabel} />
//       <br />
//       <NumberField label="Quantité" value={quantity} onChange={setQuantity} />
//       <br />
//       <NumberField
//         label="Prix unitaire"
//         value={unitPrice}
//         onChange={setUnitPrice}
//       />
//       <Button label="OK" onClick={updateProjection} />
//       <Button label="x" onClick={deleteProjection} />
//     </Item>
//   );
// }

// type ItemProps = PropsWithChildren<{}>;

// function Item(props: ItemProps) {
//   const { children } = props;

//   const contentCss = css`
//     padding: 10px;
//     margin: 10px;
//     border: 1px solid lightgrey;
//   `;

//   return <div css={contentCss}>{children}</div>;
// }
