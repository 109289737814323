import DelayedView from 'src/components/DelayedView';
import ClientItem from 'src/components/items/ClientItem';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Services from 'src/utilities/Services';
import ClientEditionPanel from './ClientEditionPanel';

export default function ClientsListPanel() {
  const { repository } = Services.use();

  const clients = repository.useData(() => repository.getClients(), []);
  const panels = usePanels();

  return (
    <Panel.Container>
      <Panel.Header title="Clients" />
      <DelayedView promise={clients}>
        {(clients) => {
          return (
            <Panel.List
              items={clients}
              getKey={(c) => c.id}
              renderItem={(c) => (
                <ClientItem
                  client={c}
                  onClick={() =>
                    panels.push(ClientEditionPanel, { client: c.id })
                  }
                />
              )}
            />
          );
        }}
      </DelayedView>
    </Panel.Container>
  );
}
