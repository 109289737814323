import { Fragment, useEffect } from 'react';
import DelayedView from 'src/components/DelayedView';
import InvoiceForm from 'src/components/forms/InvoiceForm';
import PaymentItem from 'src/components/items/PaymentItem';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Spacer from 'src/components/Spacer';
import { InvoicePayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import PaymentEditionPanel from '../Payments/PaymentEditionPanel';

type InvoicePanelProps = {
  invoice: string;
};

export default function InvoiceEditionPanel(props: InvoicePanelProps) {
  const id = props.invoice;
  const { store, repository } = Services.use();
  const panels = usePanels();

  useEffect(() => {
    store.loadProjects();
  }, []);

  const payments = repository.useData(
    () => repository.getInvoicePayments(id),
    [id],
  );

  const invoice = repository.useData(() => repository.getInvoice(id), [id]);

  const onSubmit = useSubmit(async (data: InvoicePayload) => {
    await repository.updateInvoice(id, data);
    repository.invalidate(repository.storage.searchKeys('invoices', id));
  }, []);

  const onDelete = useSubmit(async () => {
    await repository.deleteInvoice(id);
    panels.close();
    repository.invalidate(repository.storage.searchKeys('invoices', id));
  }, []);

  return (
    <Panel.Container>
      <DelayedView promise={invoice}>
        {(invoice) => {
          return (
            <Fragment>
              <Panel.Header title={invoice.label} />
              <InvoiceForm
                invoice={invoice}
                onSubmit={onSubmit}
                onDelete={onDelete}
              />
              <Spacer />
              <DelayedView promise={payments}>
                {(payments) => (
                  <Panel.List
                    label="Paiements"
                    items={payments}
                    getKey={(p) => p.id}
                    renderItem={(p) => (
                      <PaymentItem
                        payment={p}
                        onClick={() =>
                          panels.push(PaymentEditionPanel, { payment: p.id })
                        }
                      />
                    )}
                  />
                )}
              </DelayedView>
            </Fragment>
          );
        }}
      </DelayedView>
    </Panel.Container>
  );
}
