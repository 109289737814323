import { Fragment } from 'react';
import DelayedView from 'src/components/DelayedView';
import ProjectionForm from 'src/components/forms/ProjectionForm';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Model, { ProjectionPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';

type ProjectionEditionPanelProps = {
  projection: string;
};

export default function ProjectionEditionPanel(
  props: ProjectionEditionPanelProps,
) {
  const id = props.projection;
  const { repository } = Services.use();
  const panels = usePanels();

  const projection = repository.useData(
    () => repository.getProjection(id),
    [id],
  );

  const onSubmit = useSubmit(
    async (payload: ProjectionPayload) => {
      const r = await repository.axios.patch<Model.Projection>(
        `/projections/${id}`,
        payload,
      );
      console.log(r, payload);
      repository.invalidate(repository.storage.searchKeys('projections', id));
    },
    [id],
  );

  const onDelete = useSubmit(
    async () => {
      await repository.axios.delete(`/projections/${id}`);
      repository.invalidate(repository.storage.searchKeys('projections', id));
      panels.close();
    },
    { confirmation: 'Confirmer' },
    [id],
  );

  return (
    <Panel.Container>
      <DelayedView promise={projection}>
        {(projection) => {
          return (
            <Fragment>
              <Panel.Header title="Projection" />
              <ProjectionForm
                projection={projection}
                onSubmit={onSubmit}
                onDelete={onDelete}
              />
            </Fragment>
          );
        }}
      </DelayedView>
    </Panel.Container>
  );
}
