/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import overlapColors from 'src/utilities/overlapColors';
import Theme from 'src/utilities/Theme';

type EmptyBottomLeftCellProps = {};

export default function EmptyBottomLeftCell(props: EmptyBottomLeftCellProps) {
  const containerCss = css`
    background-color: ${overlapColors('white', Theme.useColor(2))};
    border-right: 1px solid ${Theme.useColor(2)};
    border-top: 1px solid ${Theme.useColor(2)};
  `;

  return <div css={containerCss}></div>;
}
