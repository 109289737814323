/** @jsxImportSource @emotion/react */
import { useNavigate, useParams } from 'react-router-dom';
import DelayedView from 'src/components/DelayedView';
import MoveForm from 'src/components/forms/MoveForm';
import { MovePayload } from 'src/utilities/Model';
import Zones, { Routing } from 'src/utilities/Routes';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import { Page } from '.';

export default function Moves() {
  const id = useParams().move;
  if (!id) throw new Error('No move id');
  const { repository } = Services.use();

  const navigate = useNavigate();
  const move = repository.useData(() => repository.getMove(id), [id]);

  const onUpdate = useSubmit(
    async (p: MovePayload) => {
      await repository.updateMove(id, p);
    },
    [id],
  );

  const onDelete = useSubmit(
    async () => {
      await repository.deleteMove(id);
      navigate(Routing.getPath(Zones.Moves));
    },
    { confirmation: 'Supprimer ?' },
    [id],
  );

  return (
    <Page>
      <Page.Header title="Déplacement" />
      <DelayedView promise={move}>
        {(move) => (
          <MoveForm move={move} onSubmit={onUpdate} onDelete={onDelete} />
        )}
      </DelayedView>
    </Page>
  );
}
