import React, { useContext } from 'react';
import Api from 'src/utilities/Api';
import DataSet from 'src/utilities/DataSet';
import Model from 'src/utilities/Model';
import R from 'src/utilities/R';
import { IsoDay } from 'src/utilities/Time';
import { Timelapse } from 'src/utilities/Timelapses';

export type Unsubscibrer = () => void;
export const EmptyUnsubscriber = () => {
  /** */
};

export interface Service {
  init(): Promise<Unsubscibrer>;
}

export default class TimesheetTableController implements Service {
  private r = new R();
  private tasks!: Array<Model.Task>;
  private contracts!: Array<Model.Contract>;

  constructor(private api: Api, private timelapse: Timelapse) {}

  async init() {
    const [tasks, contracts] = await Promise.all([
      this.fetchTasks(),
      this.fetchContracts(),
    ]);
    this.tasks = tasks;
    this.contracts = contracts;
    return EmptyUnsubscriber;
  }

  private async fetchTasks() {
    return this.api.getTasks(null, this.timelapse);
  }

  private async fetchContracts() {
    return this.api.getContracts(this.timelapse);
  }

  // Tasks

  useTasks() {
    return this.r.useSelector(() => this.tasks);
  }

  useTasksByDayAndProject(day: IsoDay, ref_project: string) {
    return this.r.useSelector(
      () =>
        this.tasks.filter(
          (t) => t.day === day && t.ref_project === ref_project,
        ),
      [day, ref_project],
    );
  }

  updateTasks(tasks: Array<Model.Task>) {
    this.tasks = DataSet.upsertAll(this.tasks, tasks);
    this.r.notify();
  }

  removeTasks(tasks: Array<string>) {
    this.tasks = DataSet.removeAll(this.tasks, tasks);
    this.r.notify();
  }

  // Contracts

  useContracts() {
    return this.r.useSelector(() => this.contracts);
  }

  useContractsByClient(ref_client: string) {
    return this.r.useSelector(
      () => this.contracts.filter((c) => c.ref_client === ref_client),
      [ref_client],
    );
  }
}

export const TimesheetTableControllerContext =
  React.createContext<TimesheetTableController | null>(null);
export function useTimesheetTableController() {
  const tasks = useContext(TimesheetTableControllerContext);
  if (!tasks) throw new Error('No tasks service');
  return tasks;
}
