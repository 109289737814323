/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import Calendar from 'react-calendar';
import Css from 'src/utilities/Css';
import { Dropdown } from 'src/utilities/Dropdown';
import { IsoDay } from 'src/utilities/Time';
import useMemoState from 'src/utilities/useMemoState';
import Clickable from '../Clickable';
import FieldContainer from './FieldContainer';
import FieldDropdown from './FieldDropdown';
import FieldInput from './FieldInput';
import fieldStyles from './fieldStyles';
import { useValidateFn, ValidationFn } from './FieldValidation';

type DateFieldConfig<TValidated extends IsoDay | null> = {
  initialValue: IsoDay | null | undefined;
  label: string;
  validation?: ValidationFn<IsoDay | null, TValidated>;
  placeholder?: string;
};

export default function useDateField<TValidated extends IsoDay | null>(
  config: DateFieldConfig<TValidated>,
) {
  const { initialValue, label, validation, placeholder } = config;

  const [value, setValue] = useMemoState(() => {
    if (initialValue === undefined) return null;
    else if (initialValue === null) return null;
    const parsed = dayjs(initialValue, 'YYYY-MM-DD');
    if (!parsed.isValid()) return null;
    return parsed.format('YYYY-MM-DD');
  }, [initialValue]);

  const [dropdown, showDropdown, hideDropdown] = Dropdown.useSpeaker();

  const onChange = useCallback((v: Date | null) => {
    if (v === null) {
      setValue(null);
    } else {
      setValue(dayjs(v).format('YYYY-MM-DD'));
    }

    hideDropdown();
  }, []);

  const displayedValue = useMemo(() => {
    if (!value) return '';
    else {
      const d = dayjs(value, 'YYYY-MM-DD', true);
      if (d.isSame(dayjs(), 'day')) return "Aujourd'hui";
      else return dayjs(value).format('LL');
    }
  }, [value]);

  const valueAsDate = useMemo(() => {
    if (value === null) return null;
    else return dayjs(value).toDate();
  }, [value]);

  const containerCss = css({
    display: 'flex',
    alignItems: 'center',
  });

  const fieldCss = css({
    flexGrow: 1,
  });

  const render = () => (
    <FieldContainer label={label} stopPropagation>
      <div css={containerCss}>
        <div css={fieldCss}>
          <FieldInput
            type="text"
            css={fieldStyles.inputCss}
            value={displayedValue}
            readOnly
            onFocus={showDropdown}
            placeholder={placeholder}
          />
        </div>
        {value ? (
          <Clickable css={Css.buttonReset} onClick={() => onChange(null)}>
            <Icon path={mdiClose} size={'20px'} />
          </Clickable>
        ) : null}
      </div>

      <FieldDropdown visible={dropdown}>
        <Calendar
          maxDetail="month"
          minDetail="century"
          onClickDay={(v) => onChange(v)}
          value={valueAsDate}
        />
      </FieldDropdown>
      {dropdown}
    </FieldContainer>
  );

  const validate = useValidateFn(value, validation);

  return { value, set: setValue, render, validate };
}
