/** @jsxImportSource @emotion/react */

type SpacerProps = {
  scale?: number;
  block?: boolean;
};

export default function Spacer(props: SpacerProps) {
  const { scale = 1, block = false } = props;
  const size = scale * 0.5 + 'cm';
  return (
    <div
      style={{
        display: block ? 'block' : 'inline-block',
        width: size,
        height: size,
      }}
    />
  );
}

Spacer.style = ``;
