import { useEffect, useState } from 'react';

export type IdlingDefered = { status: 'idling' };
export type PendingDefered = { status: 'pending' };
export type ResolvedDefered<V> = { status: 'resolved'; value: V };
export type RejectedDefered = { status: 'rejected'; error: any };
export type AnyDefered<V> =
  | PendingDefered
  | ResolvedDefered<V>
  | RejectedDefered;

function isPending<V>(d: AnyDefered<V>): d is PendingDefered {
  return d.status === 'pending';
}
function isResolved<V>(d: AnyDefered<V>): d is ResolvedDefered<V> {
  return d.status === 'resolved';
}
function isRejected<V>(d: AnyDefered<V>): d is RejectedDefered {
  return d.status === 'rejected';
}

function pending(): PendingDefered {
  return { status: 'pending' };
}
function resolved<V>(value: V): ResolvedDefered<V> {
  return { status: 'resolved', value };
}
function rejected(error: any): RejectedDefered {
  return { status: 'rejected', error };
}

function fromPromise<TValue>(
  promise: Promise<TValue>,
  onChange: (d: AnyDefered<TValue>) => any,
) {
  onChange(pending());
  promise.then(
    (v) => onChange(resolved(v)),
    (err) => onChange(rejected(err)),
  );
}

function updateValue<TValue>(
  defered: AnyDefered<TValue>,
  changer: (value: TValue) => TValue,
) {
  if (!isResolved(defered)) return defered;
  try {
    const newValue = changer(defered.value);
    return resolved(newValue);
  } catch (err) {
    return rejected(err);
  }
}

function select<TValue, TNewValue>(
  defered: AnyDefered<TValue>,
  changer: (value: TValue) => TNewValue,
) {
  if (!isResolved(defered)) return defered;
  try {
    const newValue = changer(defered.value);
    return resolved(newValue);
  } catch (err) {
    return rejected(err);
  }
}

function merge<T1, T2, TOut>(
  d1: AnyDefered<T1>,
  d2: AnyDefered<T2>,
  merger: (v1: T1, v2: T2) => TOut,
) {
  if (Defered.isPending(d1) || Defered.isPending(d2)) return Defered.pending();
  else if (Defered.isRejected(d1)) return d1;
  else if (Defered.isRejected(d2)) return d2;
  return Defered.resolved(merger(d1.value, d2.value));
}

const Defered = {
  isPending,
  isResolved,
  isRejected,
  pending,
  resolved,
  rejected,
  fromPromise,
  updateValue,
  select,
  merge,
};

export default Defered;

export function useDefered<TValue>(
  input: Promise<TValue> | AnyDefered<TValue>,
  keepResult?: boolean,
) {
  const [deferred, setDefered] = useState(() =>
    input instanceof Promise
      ? (Defered.pending() as AnyDefered<TValue>)
      : input,
  );

  useEffect(() => {
    if (input instanceof Promise) {
      const i = setTimeout(() => {
        if (keepResult) {
          if (!Defered.isResolved(deferred)) {
            setDefered(Defered.pending());
          }
        } else {
          setDefered(Defered.pending());
        }
      }, 10);
      input.then(
        (value) => {
          clearTimeout(i);
          setDefered(Defered.resolved(value));
        },
        (error) => {
          clearTimeout(i);
          setDefered(Defered.rejected(error));
        },
      );
    } else {
      if (keepResult) {
        setDefered((current) => {
          if (Defered.isResolved(current) && Defered.isPending(input))
            return current;
          else return input;
        });
      } else {
        setDefered(input);
      }
    }
  }, [input]);

  return deferred;
}
