import { Fragment, useCallback } from 'react';
import DelayedView from 'src/components/DelayedView';
import ContractForm from 'src/components/forms/ContractForm';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Model, { ContractPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';

type ContractEditionPanelProps = {
  contract: string;
};

export default function ContractEditionPanel(props: ContractEditionPanelProps) {
  const id = props.contract;
  const { repository } = Services.use();
  const panels = usePanels();

  const contract = repository.useData(() => repository.getContract(id), [id]);

  const onSubmitContract = useCallback(
    async (payload: ContractPayload) => {
      await repository.axios.patch<Model.Contract>(`/contracts/${id}`, payload);
      repository.invalidate(repository.storage.searchKeys(id, 'contracts'));
    },
    [id],
  );

  const onDelete = useCallback(async () => {
    await repository.axios.delete<Model.Contract>(`/contracts/${id}`);
    repository.invalidate(repository.storage.searchKeys(id, 'contracts'));
    panels.close();
  }, [id]);

  return (
    <Panel.Container>
      <DelayedView promise={contract}>
        {(contract) => {
          return (
            <Fragment>
              <Panel.Header title={contract.label} />
              <ContractForm
                contract={contract}
                onSubmit={onSubmitContract}
                onDelete={onDelete}
              />
            </Fragment>
          );
        }}
      </DelayedView>
    </Panel.Container>
  );
}
