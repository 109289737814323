import { round } from 'lodash';

function currecy(price: number, decimals: number = 2) {
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });

  return formatter.format(price);
}

function percentage(n: number, decimals: number = 0) {
  return `${round(n * 100, decimals)}%`;
}

function kilometers(km: number) {
  return `${round(km, 2)} km`;
}

const Currency = { format: currecy, currecy, percentage, kilometers };

export default Currency;
