import dayjs from 'dayjs';
import Currency from 'src/utilities/Format';
import Model from 'src/utilities/Model';
import { Reaction } from '../Clickable';
import ListItem from '../Panel/ListItem';

type PaymentItemProps = {
  payment: Model.Payment;
} & Reaction;

export default function PaymentItem(props: PaymentItemProps) {
  const { payment, ...reaction } = props;

  return (
    <ListItem
      label={
        `Paiement de ` + Currency.format(payment.amount_without_tax) + ' HT'
      }
      helper={Currency.format(payment.amount) + ' TTC'}
      rightText={dayjs(payment.paid_at).format('LL')}
      {...reaction}
    />
  );
}
