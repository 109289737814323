import { useEffect } from 'react';
import Speakers from './Speakers';

export const Dropdown = new Speakers();

export function useDropdown() {
  useEffect(() => {
    const fn = () => Dropdown.shutUp();
    document.addEventListener('click', fn);
    return () => {
      document.removeEventListener('click', fn);
    };
  }, []);
}
