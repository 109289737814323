import { useMemo } from 'react';
import DefaultImages from 'src/utilities/DefaultImages';
import Currency from 'src/utilities/Format';
import Model from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import Timelapses, { Timelapse } from 'src/utilities/Timelapses';
import { Reaction } from '../Clickable';
import { useDelayedString } from '../DelayedView';
import ListItem from '../Panel/ListItem';

type InvoiceItemProps = {
  projection: Model.Projection;
  timelapse?: Timelapse;
} & Reaction;

export default function ProjectionItem(props: InvoiceItemProps) {
  const { projection, timelapse, ...reaction } = props;
  const { repository } = Services.use();
  const project = repository.useData(
    () => repository.getProject(projection.ref_project),
    [projection.ref_project],
  );

  const label = useMemo(() => {
    const purpose = projection.purpose;
    if (!purpose) return "Pas d'objet";
    if (purpose.type === 'days') {
      return `${purpose.days} jour(s) ${Timelapses.format(
        projection.timelapse,
      )}`;
    } else {
      return `${purpose.label} ${Timelapses.format(projection.timelapse)}`;
    }
  }, [project, projection]);

  const icon = useDelayedString(
    project,
    (p) => p.icon || DefaultImages.project,
  );
  const helper = useDelayedString(project, (p) => `sur ${p.name}`);

  const rightText = useMemo(() => {
    const purpose = projection.purpose;
    if (!purpose) return '-';
    let total: number;
    if (purpose.type === 'days') total = purpose.days * purpose.daily_price;
    else total = purpose.price;
    if (timelapse) {
      const maskedTimelapse = Timelapses.mask(timelapse, projection.timelapse);
      if (maskedTimelapse === null) {
        total = 0;
      } else {
        total =
          total *
          Timelapses.getOverlapRatio(maskedTimelapse, projection.timelapse);
      }
    }
    return Currency.format(total);
  }, [project, projection, timelapse]);

  return (
    <ListItem
      label={label}
      helper={helper}
      rightText={rightText}
      image={icon}
      {...reaction}
    />
  );
}
