/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typo from 'src/components/Typo';
import Css from 'src/utilities/Css';
import Currency from 'src/utilities/Format';
import Holidays from 'src/utilities/Holidays';
import Model from 'src/utilities/Model';
import overlapColors from 'src/utilities/overlapColors';
import Theme from 'src/utilities/Theme';
import Time, { IsoDay } from 'src/utilities/Time';
import usePerf from 'src/utilities/usePerf';

type DayPerfProps = {
  day: IsoDay;
  tasks: Array<Model.Task>;
  extras: Array<Model.Extra>;
  projects: Array<Model.Project>;
};

export default function DayPerf(props: DayPerfProps) {
  const { day, tasks, extras, projects } = props;
  const perf = usePerf({ tasks, extras, projects });
  let background = overlapColors('white', Theme.useColor(1));
  const isOnHolyday = Holidays.useIsHolyday(Time.parseDay(day));
  if (isOnHolyday) background = overlapColors(background, Theme.useColor(1));

  const containerCss = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    &:before {
      ${Css.absoluteFill};
      z-index: -1;
      content: ' ';
      background-color: ${background};
      border-left: 1px solid ${Theme.useColor(2)};
      border-bottom: 1px solid ${Theme.useColor(2)};
    }
  `;

  return (
    <div css={containerCss}>
      <Typo typo="minor">{perf.hours > 0 ? `${perf.hours}h` : '-'}</Typo>
      <Typo>{perf.tasksPerf > 0 ? `${Currency.format(perf.perf)}` : '-'}</Typo>
    </div>
  );
}
