/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';
import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import { rgba } from 'polished';
import React, {
  Fragment,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import DelayedView from 'src/components/DelayedView';
import Center from 'src/components/Print/PrintCenter';
import Divider from 'src/components/Print/PrintDivider';
import Spacer from 'src/components/Print/PrintSpacer';
import Typo from 'src/components/Typo';
import InvoiceForm from 'src/components/forms/InvoiceForm';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Format from 'src/utilities/Format';
import Model, { InvoicePayload } from 'src/utilities/Model';
import Zones, { Routing } from 'src/utilities/Routes';
import Services from 'src/utilities/Services';
import Timelapses from 'src/utilities/Timelapses';
import useSubmit from 'src/utilities/useSubmit';
import InvoicesController, {
  InvoicesControllerContext,
  useInvoicesController,
} from './InvoicesController';

export default function Invoices() {
  const appTimelapse = AppTimelapse.use();

  const { api } = Services.use();

  const controller = useMemo(async () => {
    const controller = new InvoicesController(api, appTimelapse);
    await controller.init();
    return controller;
  }, [api, appTimelapse]);

  return (
    <Container>
      <Spacer />
      <Heading label="Facturation" to={Routing.getPath(Zones.Invoices)} />
      <DelayedView promise={controller}>
        {(controller) => (
          <InvoicesControllerContext.Provider value={controller}>
            <Outlet />
          </InvoicesControllerContext.Provider>
        )}
      </DelayedView>
    </Container>
  );
}

export function InvoicesPanel() {
  const controller = useInvoicesController();
  const invoices = controller.useInvoices();

  const { from, to } = useMemo(() => {
    const boundaries = Timelapses.getBoundaries(controller.timelapse);
    const from = dayjs(boundaries.start).startOf('day').toISOString();
    const to = dayjs(boundaries.end).endOf('day').toISOString();
    return { from, to };
  }, [controller.timelapse]);

  const styles = css`
    .timeline {
      .legend {
        .legend-item {
          border-left: 1px solid ${rgba('black', 0.5)};
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 10px;
        }
      }
      .mark {
        border-left: 1px solid ${rgba('black', 0.5)};
      }
    }
  `;

  const renderLegend = useCallback((date: string, unit: TimelineUnit) => {
    if (unit === 'year') return dayjs(date).format('YYYY');
    else if (unit === 'quarter') return `Q${dayjs(date).quarter()}`;
    else if (unit === 'month') return dayjs(date).format('MMM');
    else if (unit === 'week') return `S${dayjs(date).week()}`;
    else if (unit === 'day') return `${dayjs(date).date()}`;
    else return '-';
  }, []);

  const stats = useMemo(() => {
    const invoiced = sumBy(invoices, (i) => i.amount);
    const paid = sumBy(invoices, (i) => i.paid_amount);
    const to_pay = sumBy(invoices, (i) => i.to_pay_amount);
    return { invoiced, payment_rate: 1 - paid / to_pay };
  }, [invoices]);

  return (
    <Card>
      <Global styles={styles} />
      <Container>
        <Spacer />
        <Timeline from={from} to={to} renderLegend={renderLegend}>
          <Divider />
          <Spacer scale={0.5} />
          {invoices.map((invoice) => (
            <InvoiceItem invoice={invoice} />
          ))}
        </Timeline>
        <Spacer />
        <Grid2 columns={2} gap={20}>
          <Stat label="Facturé" value={Format.currecy(stats.invoiced)} />
          <Stat
            label="Paiement en attente"
            value={Format.percentage(stats.payment_rate)}
            tone={
              stats.payment_rate <= 0.2
                ? 'good'
                : stats.payment_rate > 0.6
                ? 'bad'
                : null
            }
          />
        </Grid2>
        <Spacer />
        <Center>
          <Button
            to={Routing.getPath(Zones.InvoiceCreation)}
            label="Nouvelle facture"
          />
        </Center>
        <Spacer />
      </Container>
    </Card>
  );
}

export function InvoiceDetail() {
  const id = Routing.useParam('id');
  const controller = useInvoicesController();
  const invoice = controller.useInvoice(id);
  const navigate = useNavigate();

  const onSubmit = useSubmit(
    async (data: InvoicePayload) => {
      await controller.updateInvoice(id, data);
      navigate(-1);
    },
    [id],
  );

  const onDelete = useSubmit(
    async () => {
      navigate(-1);
      await controller.deleteInvoice(id);
    },
    { confirmation: 'Confirmez' },
    [],
  );

  return (
    <Fragment>
      <Heading
        label={`${invoice.label}`}
        to={Routing.getPath(Zones.Invoice, { id })}
      />
      <Grid2 columns={2}>
        <Card>
          <Spacer />
          <InvoiceForm
            invoice={invoice}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
        </Card>
      </Grid2>
    </Fragment>
  );
}

export function InvoiceCreation() {
  const controller = useInvoicesController();
  const navigate = useNavigate();

  const onSubmit = useSubmit(async (data: InvoicePayload) => {
    const invoice = await controller.createInvoice(data);
    navigate(Routing.getPath(Zones.Invoice, { id: invoice.id }), {
      replace: true,
    });
  }, []);

  return (
    <Fragment>
      <Heading
        label={`Nouvelle facture`}
        to={Routing.getPath(Zones.InvoiceCreation)}
      />
      <Grid2 columns={2}>
        <Card>
          <Spacer />
          <InvoiceForm invoice={null} onSubmit={onSubmit} />
        </Card>
      </Grid2>
    </Fragment>
  );
}

type Grid2Props = PropsWithChildren<{
  columns: number;
  gap?: number;
}>;

export function Grid2(props: Grid2Props) {
  const { columns, children, gap } = props;
  const gridCss = css({
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: gap,
  });

  return <div css={gridCss}>{children}</div>;
}

type StatProps = {
  label: string;
  value: string;
  tone?: 'good' | 'bad' | null;
};

export function Stat(props: StatProps) {
  const { label, value, tone } = props;
  const color =
    tone === 'good' ? 'darkgreen' : tone === 'bad' ? 'darkred' : 'black';
  const containerCss = css({
    background: rgba(color, 0.1),
    padding: 20,
    borderRadius: 10,
    color,
  });
  return (
    <div css={containerCss}>
      <Typo typo="heading" opacity={0.5}>
        {label}
      </Typo>
      <Typo typo="page">{value}</Typo>
    </div>
  );
}

type InvoiceItemProps = {
  invoice: Model.Invoice;
};

function InvoiceItem(props: InvoiceItemProps) {
  const { invoice } = props;

  const itemCss = css({
    paddingBlock: 6,
    display: 'flex',
  });
  const tileCss = css({
    padding: 6,
    background: rgba('black', 1),
    color: 'white',
    borderRadius: 4,
  });

  return (
    <TimelineItem at={invoice.billed_at}>
      <Link to={Routing.getPath(Zones.Invoice, { id: invoice.id })}>
        <div css={itemCss}>
          <div css={tileCss}>
            <Typo
              opacity={invoice.paid_amount >= invoice.to_pay_amount ? 0.5 : 1}>
              ⬤ {invoice.label}
            </Typo>
          </div>
        </div>
      </Link>
    </TimelineItem>
  );
}

// Card

type CardProps = PropsWithChildren<{
  children: ReactNode;
}>;

export function Card(props: CardProps) {
  const { children } = props;

  const containerCss = css({
    background: 'white',
    borderRadius: 10,
    minHeight: 300,
    boxShadow: `0px 0px 100px ${rgba('black', 0.1)}`,
  });

  return <div css={containerCss}>{children}</div>;
}

type HeadingProps = {
  label: string;
  to: string;
};

export function Heading(props: HeadingProps) {
  return (
    <Link to={props.to}>
      <Typo typo="page" opacity={0.3}>
        {props.label}
      </Typo>
    </Link>
  );
}

// Container

type ContainerProps = PropsWithChildren<{}>;

export function Container(props: ContainerProps) {
  const { children } = props;

  const wrapperCss = css({
    paddingInline: 20,
  });

  return <div css={wrapperCss}>{children}</div>;
}

// Timeline

type TimelineProps = PropsWithChildren<{
  from: string;
  to: string;
  renderLegend?: LegendRenderFn;
}>;

type TimelineUnit =
  | 'year'
  | 'quarter'
  | 'month'
  | 'week'
  | 'day'
  | 'hour'
  | 'minute';

type LegendRenderFn = (date: string, unit: TimelineUnit) => ReactNode;

function Timeline(props: TimelineProps) {
  const { from, to, renderLegend, children } = props;

  const styles = css`
    .timeline {
      .legend {
        display: flex;
      }
      .contents {
        position: relative;
        .background {
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          right: 0px;
          z-index: 1;
          .mark {
            position: absolute;
            top: 0px;
            bottom: 0px;
          }
        }
        .children {
          position: relative;
          z-index: 2;
        }
      }
    }
  `;

  const majorUnit = useMemo(() => {
    const duration = dayjs.duration(dayjs(to).diff(from));
    if (duration.asYears() > 1) return 'year';
    else if (duration.asMonths() > 3) return 'quarter';
    else if (duration.asMonths() > 1) return 'month';
    else if (duration.asDays() > 7) return 'week';
    else if (duration.asDays() > 1) return 'day';
    else if (duration.asHours() > 1) return 'hour';
    else if (duration.asMinutes() > 1) return 'minute';
    else return null;
  }, []);

  const minorUnit = useMemo(() => {
    const duration = dayjs.duration(dayjs(to).diff(from));
    if (duration.asYears() > 1) return 'quarter';
    else if (duration.asMonths() > 3) return 'month';
    else if (duration.asMonths() > 1) return 'week';
    else if (duration.asDays() > 7) return 'day';
    else if (duration.asDays() > 1) return 'hour';
    else if (duration.asHours() > 1) return 'minute';
    else return null;
  }, []);

  const context = useMemo(() => ({ from, to }), [from, to]);

  return (
    <TimelineContext.Provider value={context}>
      <div className="timeline">
        <Global styles={styles} />
        {majorUnit ? (
          <TimelineLegend renderLegend={renderLegend} unit={majorUnit} />
        ) : null}
        {minorUnit ? (
          <TimelineLegend renderLegend={renderLegend} unit={minorUnit} />
        ) : null}
        <div className="contents">
          <div className="background">
            {majorUnit ? <TimelineMarks unit={majorUnit} /> : null}
            {minorUnit ? <TimelineMarks unit={minorUnit} /> : null}
          </div>
          <div className="children">{children}</div>
        </div>
      </div>
    </TimelineContext.Provider>
  );
}

type TimelineLegendProps = {
  unit: TimelineUnit;
  renderLegend?: LegendRenderFn;
};

function TimelineLegend(props: TimelineLegendProps) {
  const { unit, renderLegend } = props;

  const renderFn = renderLegend || (() => <Fragment />);

  const { from, to } = useContext(TimelineContext);

  const getStops = useCallback(
    (from: string, to: string, unit: TimelineUnit | null) => {
      const stops: Array<string> = [];
      if (!unit) return stops;
      let cursor = dayjs(from);
      while (dayjs(cursor).isSameOrBefore(to)) {
        stops.push(cursor.toISOString());
        cursor = dayjs(cursor).endOf(unit).add(1, 'ms');
      }
      return stops;
    },
    [],
  );

  const stops = useMemo(() => getStops(from, to, unit), [from, to, unit]);

  const getWidth = useCallback(
    (date: string, next: string | null) => {
      const end = next || to;
      const total = dayjs.duration(dayjs(to).diff(from)).asMilliseconds();
      const duration = dayjs.duration(dayjs(end).diff(date)).asMilliseconds();
      const width = duration / total;
      return width;
    },
    [from, to],
  );

  return (
    <div className="legend">
      {stops.map((s, i) => {
        const width = getWidth(s, stops[i + 1] || null);
        const percentage = `${width * 100}%`;
        return (
          <div className="legend-item" style={{ width: percentage }}>
            {renderFn(s, unit)}
          </div>
        );
      })}
    </div>
  );
}

type TimelineMarksProps = {
  unit: TimelineUnit;
};

function TimelineMarks(props: TimelineMarksProps) {
  const { unit } = props;

  const { from, to } = useContext(TimelineContext);

  const getStops = useCallback(
    (from: string, to: string, unit: TimelineUnit | null) => {
      const stops: Array<string> = [];
      if (!unit) return stops;
      let cursor = dayjs(from);
      while (dayjs(cursor).isSameOrBefore(to)) {
        stops.push(cursor.toISOString());
        cursor = dayjs(cursor).endOf(unit).add(1, 'ms');
      }
      return stops;
    },
    [],
  );

  const stops = useMemo(() => getStops(from, to, unit), [from, to, unit]);

  const getWidth = useCallback(
    (date: string, next: string | null) => {
      const end = next || to;
      const total = dayjs.duration(dayjs(to).diff(from)).asMilliseconds();
      const duration = dayjs.duration(dayjs(end).diff(date)).asMilliseconds();
      const width = duration / total;
      return percent(width);
    },
    [from, to],
  );

  const getOffset = useCallback(
    (date: string) => {
      const total = dayjs.duration(dayjs(to).diff(from)).asMilliseconds();
      const ellapsed = dayjs.duration(dayjs(date).diff(from)).asMilliseconds();
      const offset = ellapsed / total;
      return percent(offset);
    },
    [from, to],
  );

  return (
    <Fragment>
      {stops.map((s, i) => {
        return (
          <div
            className="mark"
            style={{
              width: getWidth(s, stops[i + 1] || null),
              left: getOffset(s),
            }}></div>
        );
      })}
    </Fragment>
  );
}

const TimelineContext = React.createContext<{ from: string; to: string }>({
  from: '',
  to: '',
});

type TimelineItemProps = PropsWithChildren<{
  at: string;
}>;

function TimelineItem(props: TimelineItemProps) {
  const { at, children } = props;
  const { from, to } = useContext(TimelineContext);

  const paddingLeft = useMemo(() => {
    const total = dayjs.duration(dayjs(to).diff(from)).asMilliseconds();
    const before = dayjs.duration(dayjs(at).diff(from)).asMilliseconds();
    const paddingLeft = before / total;
    return percent(paddingLeft);
  }, [from, to, at]);

  return (
    <div style={{ paddingLeft }}>
      <div>{children}</div>
    </div>
  );
}

function percent(value: number) {
  return `${value * 100}%`;
}
