import { Fragment, useMemo } from 'react';
import DelayedView from 'src/components/DelayedView';
import useSelectField from 'src/components/Field/useSelectField';
import Spacer from 'src/components/Print/PrintSpacer';
import ProjectItem from 'src/components/items/ProjectItem';
import AppTimelapse from 'src/utilities/AppTimelapse';
import Format from 'src/utilities/Format';
import Zones, { Routing } from 'src/utilities/Routes';
import Services from 'src/utilities/Services';
import { Card, Container, Grid2, Heading, Stat } from './Invoices';

export default function Performances() {
  const { api } = Services.use();
  const appTimelapse = AppTimelapse.use();
  const timelapse = Routing.useQueryParam('timelapse', appTimelapse);

  const projects = useMemo(() => {
    return api.getProjects();
  }, []);

  const projectField = useSelectField({
    label: 'Projet',
    initialValue: null,
    options: projects,
    keyExtractor: (p) => p.id,
    labelExtractor: (p) => p.name,
    itemExtractor: (p) => <ProjectItem project={p} />,
  });

  const project = projectField.value;

  const perf = useMemo(() => {
    console.log('call', project);
    return api.getReporting(timelapse, project || null);
  }, [project]);

  return (
    <Container>
      <Spacer />
      <Heading label="Performances" to={Routing.getPath(Zones.Performances)} />
      <Card>
        <DelayedView promise={perf}>
          {(perf) => (
            <Fragment>
              <Spacer />
              <Container>
                {projectField.render()}
                <Spacer />
                <Grid2 columns={3} gap={20}>
                  <Stat label="Activité" value={Format.currecy(perf.done)} />
                  <Stat label="Facturé" value={Format.currecy(perf.invoiced)} />
                  <Stat label="Payé" value={Format.currecy(perf.paid)} />
                </Grid2>
              </Container>
            </Fragment>
          )}
        </DelayedView>
      </Card>
    </Container>
  );
  // const timelapse = Routing.useParam('timelapse', null);
  // if (timelapse === null) {
  //   const currentYear = Time.format(new Date(), 'yyyy');
  //   return (
  //     <Navigate
  //       to={Routing.getPath(Zones.YearPerformances, { timelapse: currentYear })}
  //     />
  //   );
  // } else {
  //   return <Outlet />;
  // }
}
