/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import Clickable from 'src/components/Clickable';
import { Required } from 'src/components/Field/FieldValidation';
import Form from 'src/components/Field/Form';
import useNumberField from 'src/components/Field/useNumberField';
import useSelectField from 'src/components/Field/useSelectField';
import useTextField from 'src/components/Field/useTextField';
import TopicItem from 'src/components/items/TopicItem';
import Css from 'src/utilities/Css';
import Model, { ExtraPayload } from 'src/utilities/Model';
import useSubmit from 'src/utilities/useSubmit';

type ExtraFormProps = {
  extra: Model.Extra | null;
  topics: Array<Model.Topic>;
  onSubmit: (update: ExtraPayload) => any;
  onRemove?: () => any;
};

export default function ExtraForm(props: ExtraFormProps) {
  const { extra, topics, onSubmit, onRemove } = props;

  const gridCss = css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr auto',
  });

  const labelField = useTextField({
    label: null,
    placeholder: 'Libellé',
    initialValue: extra ? extra.label : null,
    validation: (v) => Required(v),
    inputProps: {
      onKeyDown: (e) => {
        const hours = priceField.value || 0;
        if (e.code === 'ArrowUp') {
          priceField.set(hours + 1);
          e.preventDefault();
        } else if (e.code === 'ArrowDown') {
          priceField.set(hours - 1);
          e.preventDefault();
        }
      },
    },
  });

  const topicField = useSelectField({
    label: null,
    placeholder: 'Sujet',
    initialValue: extra ? extra.ref_topic : null,
    options: topics,
    keyExtractor: (t) => t.id,
    labelExtractor: (t) => t.label,
    itemExtractor: (t) => <TopicItem topic={t} />,
  });

  const priceField = useNumberField({
    label: null,
    placeholder: 'Prix',
    initialValue: extra ? extra.price : null,
    unit: (g) => ' €',
    validation: (v) => Required(v),
    decimals: 2,
    minimum: 0,
  });

  const onFormSubmit = useSubmit(() => {
    const label = labelField.validate();
    const topic = topicField.validate();
    const price = priceField.validate();
    onSubmit({ label, ref_topic: topic, price });
  }, [onSubmit]);

  return (
    <Form onSubmit={onFormSubmit}>
      {labelField.render()}
      <div css={gridCss}>
        {topicField.render()}
        {priceField.render()}
        {onRemove ? (
          <Clickable onClick={onRemove} css={Css.buttonReset}>
            <Icon path={mdiDelete} size="24px" />
          </Clickable>
        ) : null}
      </div>
    </Form>
  );
}
