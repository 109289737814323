import { groupBy, sum, sumBy } from 'lodash';
import hash from 'object-hash';
import { useMemo } from 'react';
import DataSet from './DataSet';
import Model from './Model';

export type Perf = ReturnType<typeof usePerf>;

type PerfConfig = {
  tasks: Array<Model.Task>;
  extras: Array<Model.Extra>;
  projects: Array<Model.Project>;
};

export function getPerf(config: PerfConfig) {
  const { tasks, extras, projects } = config;

  // Hours
  const hours = sum(tasks.map((t) => t.quantity));

  // Days
  const tasksPerProject = groupBy(tasks, (t) => t.ref_project);
  let days: number = 0;
  Object.entries(tasksPerProject).forEach(([projectId, tasks]) => {
    const project = DataSet.search(projects, projectId);
    if (!project) return;
    const tasksHours = sumBy(tasks, (t) => t.quantity);
    const projectDays = tasksHours / project.default_hours_per_day;
    days += projectDays;
  });

  // tasksPerf
  const tasksPerf = sumBy(tasks, (t) => {
    const project = DataSet.search(projects, t.ref_project);
    if (!project) return 0;
    return (
      (t.quantity / project.default_hours_per_day) * project.default_daily_price
    );
  });

  // extraPerfs
  const extrasPerf = sumBy(extras, (e) => e.price);

  const perf = tasksPerf + extrasPerf;

  return { hours, days, tasksPerf, extrasPerf, perf };
}

export default function usePerf(config: PerfConfig) {
  return useMemo(() => {
    return getPerf(config);
  }, [hash(config)]);
}
