/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import Clickable from 'src/components/Clickable';
import { Required } from 'src/components/Field/FieldValidation';
import Form from 'src/components/Field/Form';
import useNumberField from 'src/components/Field/useNumberField';
import useSelectField from 'src/components/Field/useSelectField';
import useTextField from 'src/components/Field/useTextField';
import TopicItem from 'src/components/items/TopicItem';
import Css from 'src/utilities/Css';
import Model, { TaskPayload } from 'src/utilities/Model';
import useSubmit from 'src/utilities/useSubmit';
import ContractItem from '../items/ContractItem';

type TaskEditorProps = {
  task: Model.Task | null;
  topics: Array<Model.Topic>;
  contracts: Array<Model.Contract>;
  onSubmit: (update: TaskPayload) => any;
  onRemove?: () => any;
};

export default function TaskEditor(props: TaskEditorProps) {
  const { task, topics, contracts, onSubmit, onRemove } = props;

  const gridCss = css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr auto',
  });

  const labelField = useTextField({
    label: null,
    placeholder: 'Libellé',
    initialValue: task ? task.label : null,
    validation: (v) => Required(v),
    inputProps: {
      onKeyDown: (e) => {
        const quantity = quantityField.value || 0;
        if (e.code === 'ArrowUp') {
          quantityField.set(quantity + 1);
          e.preventDefault();
        } else if (e.code === 'ArrowDown') {
          quantityField.set(quantity - 1);
          e.preventDefault();
        }
      },
    },
  });

  const topicField = useSelectField({
    label: null,
    placeholder: 'Sujet',
    initialValue: task ? task.ref_topic : null,
    options: topics,
    keyExtractor: (t) => t.id,
    labelExtractor: (t) => t.label,
    itemExtractor: (t) => <TopicItem topic={t} />,
  });

  const contractField = useSelectField({
    label: null,
    placeholder: 'Contrat',
    initialValue: task
      ? task.ref_contract
      : contracts.length === 1
      ? contracts[0].id
      : null,
    options: contracts,
    keyExtractor: (t) => t.id,
    labelExtractor: (t) => t.label,
    itemExtractor: (c) => <ContractItem contract={c} />,
  });

  const quantityField = useNumberField({
    label: null,
    placeholder: 'Heures',
    initialValue: task ? task.quantity : null,
    unit: (g) => (g > 1 ? ' heures' : 'heure'),
    validation: (v) => Required(v),
    minimum: 0,
  });

  const onFormSubmit = useSubmit(async () => {
    const label = labelField.validate();
    const topic = topicField.validate();
    const contract = contractField.validate();
    const quantity = quantityField.validate();
    await onSubmit({
      label,
      ref_topic: topic,
      quantity,
      ref_contract: contract,
      unit_price: null,
    });
  }, [onSubmit]);

  return (
    <Form onSubmit={onFormSubmit}>
      {labelField.render()}
      {contractField.render()}
      <div css={gridCss}>
        {topicField.render()}
        {quantityField.render()}
        {onRemove ? (
          <Clickable onClick={onRemove} css={Css.buttonReset}>
            <Icon path={mdiDelete} size="24px" />
          </Clickable>
        ) : null}
      </div>
    </Form>
  );
}
