/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Spacer from 'src/components/Spacer';
import Css from 'src/utilities/Css';
import Model from 'src/utilities/Model';
import overlapColors from 'src/utilities/overlapColors';
import Theme from 'src/utilities/Theme';
import usePerf from 'src/utilities/usePerf';
import Typo from '../../../components/Typo';
import Currency from '../../../utilities/Format';
import Layout from '../../../utilities/Layout';

type ProjectPerfCellProps = {
  project: Model.Project;
  tasks: Array<Model.Task>;
  extras: Array<Model.Extra>;
};

export default function ProjectPerfCell(props: ProjectPerfCellProps) {
  const { project, extras, tasks } = props;

  const perf = usePerf({ tasks, extras, projects: [project] });

  let containerCss = css`
    display: flex;
    border-right: 1px solid ${Layout.borderColor};
    align-items: center;
    padding: 6px;
    &:before {
      ${Css.absoluteFill};
      z-index: -1;
      content: ' ';
      background-color: ${overlapColors('white', Theme.useColor(1))};
      border-right: 1px solid ${Theme.useColor(2)};
      border-top: 1px solid ${Theme.useColor(2)};
    }
  `;

  const infosCss = css`
    flex: 1;
  `;

  const totalCss = css``;

  return (
    <div css={containerCss}>
      <div css={infosCss}>
        <Typo typo="minor" oneLine>
          Jours : {perf.days}
        </Typo>
        <Typo typo="minor" oneLine>
          Extras : {Currency.format(perf.extrasPerf, 0)}
        </Typo>
      </div>
      <Spacer />
      <div css={totalCss}>
        <Typo>{Currency.format(perf.perf)}</Typo>
      </div>
    </div>
  );
}
