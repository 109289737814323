/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Css from '../utilities/Css';

type LoadingProps = {
  containerCss?: SerializedStyles;
};

export default function Loading(props: LoadingProps) {
  const containerCss = css`
    ${props.containerCss}
    ${Css.centerContent}
  `;
  return (
    <div css={containerCss}>
      <ScaleLoader />
    </div>
  );
}
