/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Fragment } from 'react';
import { SpacerHalf } from 'src/components/Spacer';
import TopicBadge from 'src/components/TopicBadge';
import Typo from 'src/components/Typo';
import DataSet from 'src/utilities/DataSet';
import Currency from 'src/utilities/Format';
import Holidays from 'src/utilities/Holidays';
import Model from 'src/utilities/Model';
import overlapColors from 'src/utilities/overlapColors';
import Theme from 'src/utilities/Theme';
import Time, { IsoDay } from 'src/utilities/Time';
import usePerf from 'src/utilities/usePerf';

type WorkCellProps = {
  day: IsoDay;
  project: Model.Project;
  topics: Array<Model.Topic>;
  tasks: Array<Model.Task>;
  extras: Array<Model.Extra>;
  onClick: () => any;
};

export default function WorkCell(props: WorkCellProps) {
  const { onClick, day, project, tasks, extras, topics } = props;

  const perf = usePerf({ tasks, extras, projects: [project] });
  const isOnHolyday = Holidays.useIsHolyday(Time.parseDay(day));

  //const controller = useGridController<Model.Project, IsoDay>()
  // const selected = controller.useIsSelected(project, day);
  const selected = false;

  let background = 'white';
  if (selected) background = overlapColors(background, Theme.useColor(1));

  const containerCss = css({
    backgroundColor: background,
    borderBottom: `1px solid ${Theme.useColor(isOnHolyday ? 4 : 1)}`,
    borderRight: `1px solid ${Theme.useColor(1)}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
  });

  const taskCss = css({
    display: 'flex',
    alignItems: 'center',
  });

  let tasksNode = tasks.map((t) => {
    const topic = DataSet.search(topics, t.ref_topic);
    return (
      <div key={t.id} css={taskCss}>
        {topic ? (
          <Fragment>
            <TopicBadge typo="minor" topic={topic} />
            <SpacerHalf />
          </Fragment>
        ) : null}
        <Typo typo="minor" oneLine inline>
          {t.label}
        </Typo>
      </div>
    );
  });

  const extrasNode = extras.map((e) => {
    const topic = DataSet.search(topics, e.ref_topic);
    return (
      <div key={e.id} css={taskCss}>
        {topic ? (
          <Fragment>
            <TopicBadge typo="minor" topic={topic} />
            <SpacerHalf />
          </Fragment>
        ) : null}
        <Typo typo="minor" oneLine inline>
          {e.label}
        </Typo>
      </div>
    );
  });

  const isEmpty = tasks.length === 0 && extras.length === 0;
  const totalNode = <Typo>{Currency.format(perf.perf, 2)}</Typo>;

  return (
    <div css={containerCss} onClick={onClick}>
      {tasksNode}
      {extrasNode}
      {isEmpty ? null : totalNode}
    </div>
  );
}
