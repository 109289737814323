/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Model from 'src/utilities/Model';
import { TypoName } from 'src/utilities/Typos';
import Typo from './Typo';

type TopicBadgeProps = {
  topic: Model.Topic;
  typo?: TypoName;
};
export default function TopicBadge(props: TopicBadgeProps) {
  const { topic, typo = 'body' } = props;

  let containerCss = css`
    display: inline-block;
    padding: 3px 6px;
    background-color: ${topic.color || 'rgba(0, 0, 0, 0.1)'};
    border-radius: 12px;
  `;

  return (
    <span css={containerCss}>
      <Typo typo={typo} oneLine>
        {topic.label}
      </Typo>
    </span>
  );
}
