import { uniqBy } from 'lodash';
type Id = number | string;
type Filter = Id | { id: Id };

function replace<TItem extends { id: Id }>(
  list: Array<TItem>,
  replacement: TItem,
) {
  const id = extractId(replacement);
  return list.map((item) => (item.id === id ? replacement : item));
}

function upsert<TItem extends { id: Id }>(list: Array<TItem>, upserted: TItem) {
  if (has(list, upserted)) return replace(list, upserted);
  else return [...list, upserted];
}

function upsertAll<TItem extends { id: Id }>(
  list: Array<TItem>,
  upserted: Array<TItem>,
) {
  let stock = [...list];
  upserted.forEach((i) => (stock = upsert(stock, i)));
  return stock;
}

function remove<TItem extends { id: Id }>(list: Array<TItem>, filter: Filter) {
  const id = extractId(filter);
  return list.filter((item) => item.id !== id);
}

function removeAll<TItem extends { id: Id }>(
  list: Array<TItem>,
  filters: Array<Filter>,
) {
  let all = list;
  filters.forEach((f) => (all = remove(all, f)));
  return all;
}

function search<TItem extends { id: Id }>(
  list: Array<TItem>,
  filter: Filter | null,
): TItem | null {
  if (!filter) return null;
  const id = extractId(filter);
  const match = list.find((item) => item.id === id);
  return match || null;
}

function find<TItem extends { id: Id }>(
  list: Array<TItem>,
  filter: Filter,
): TItem {
  const id = extractId(filter);
  const match = list.find((item) => item.id === id);
  if (match) return match;
  else throw new Error('No match found');
}

function has<TItem extends { id: Id }>(list: Array<TItem>, filter: Filter) {
  const id = extractId(filter);
  const match = list.find((item) => item.id === id);
  return !!match;
}

function extractId(filter: Filter): Id {
  if (typeof filter === 'object') return filter.id;
  return filter;
}

function appendAll<TItem extends { id: Id }>(
  base: Array<TItem>,
  appended: Array<TItem>,
): Array<TItem> {
  return uniqBy([...base, ...appended], 'id');
}

const DataSet = {
  replace,
  remove,
  removeAll,
  find,
  has,
  search,
  appendAll,
  upsert,
  upsertAll,
};

export default DataSet;
