/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom';
import MoveForm from 'src/components/forms/MoveForm';
import { MovePayload } from 'src/utilities/Model';
import Zones, { Routing } from 'src/utilities/Routes';
import Services from 'src/utilities/Services';
import useSubmit from 'src/utilities/useSubmit';
import { Page } from '.';

export default function Moves() {
  const { repository } = Services.use();
  const navigate = useNavigate();

  const onCreate = useSubmit(async (p: MovePayload) => {
    const response = await repository.createMove(p);
    navigate(Routing.getPath(Zones.MoveEdition, { move: response.data.id }), {
      replace: true,
    });
  }, []);

  return (
    <Page>
      <Page.Header title="Nouveau déplacement" />
      <MoveForm move={null} onSubmit={onCreate} />
    </Page>
  );
}
