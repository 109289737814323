/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Css from 'src/utilities/Css';
import Currency from 'src/utilities/Format';
import Model from 'src/utilities/Model';
import overlapColors from 'src/utilities/overlapColors';
import Theme from 'src/utilities/Theme';
import usePerf from 'src/utilities/usePerf';
import Typo from '../../../components/Typo';

type TotalCellProps = {
  tasks: Array<Model.Task>;
  extras: Array<Model.Extra>;
  projects: Array<Model.Project>;
};

export default function TotalCell(props: TotalCellProps) {
  const { tasks, extras, projects } = props;

  const perf = usePerf({ tasks, extras, projects });

  const containerCss = css`
    display: flex;
    justify-content: center;
    align-items: center;
    ${Css.absoluteFill};
    background-color: ${overlapColors('white', Theme.useColor(2))};
    border-right: 1px solid ${Theme.useColor(2)};
    border-top: 1px solid ${Theme.useColor(2)};
  `;

  return (
    <div css={containerCss}>
      <Typo>{Currency.format(perf.perf)}</Typo>
    </div>
  );
}
