/** @jsxImportSource @emotion/react */

import { rgba } from 'polished';

type DividerProps = {};

export default function Divider(props: DividerProps) {
  const {} = props;
  const size = '0.01cm';
  return (
    <div
      style={{ width: '100%', height: size, background: rgba('black', 0.1) }}
    />
  );
}

Divider.style = ``;
