import { DependencyList, useEffect, useRef, useState } from 'react';

export default function useMemoState<V>(getter: () => V, deps: DependencyList) {
  const mountPassed = useRef<boolean>(false);
  const [state, setState] = useState<V>(getter);

  useEffect(() => {
    if (!mountPassed.current) {
      mountPassed.current = true;
      return;
    }
    setState(getter());
  }, deps);

  return [state, setState] as const;
}
