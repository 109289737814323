import { useCallback } from 'react';
import TopicForm from 'src/components/forms/TopicForm';
import Panel from 'src/components/Panel';
import { usePanels } from 'src/components/PanelsDisplayer';
import Model, { TopicPayload } from 'src/utilities/Model';
import Services from 'src/utilities/Services';
import TopicEditionPanel from './TopicEditionPanel';

type TopicCreationPanelProps = {
  ref_project: string;
};

export default function TopicCreationPanel(props: TopicCreationPanelProps) {
  const { ref_project } = props;

  const { repository } = Services.use();
  const panels = usePanels();

  const onSubmit = useCallback(
    async (payload: TopicPayload) => {
      const topic = await await repository.axios.post<Model.Topic>('/topics', {
        ...payload,
        ref_project,
      });
      repository.invalidate(repository.storage.searchKeys('topics'));
      panels.replace(TopicEditionPanel, { topic: topic.data.id });
    },
    [ref_project],
  );

  return (
    <Panel.Container>
      <Panel.Header title="Nouveau sujet" />
      <TopicForm topic={null} onSubmit={onSubmit} />
    </Panel.Container>
  );
}
