import { uniqBy } from 'lodash';
import hash from 'object-hash';
import { useEffect } from 'react';
import Api from './Api';
import AsyncValues, { AsyncValue } from './AsyncValues';
import DataSet from './DataSet';
import Model from './Model';
import Timelaspses, { Timelapse } from './Timelapses';
import Updater from './Updater';

type LoadInvoicesConfig = {
  timelapse: Timelapse;
};

type UseTasksConfig = {
  projects?: Array<string> | null;
  timelapse?: Timelapse;
  load?: boolean;
  dropEmpty?: boolean;
};

type UseExtrasConfig = {
  projects?: Array<string> | null;
  timelapse?: Timelapse;
  load?: boolean;
  dropEmpty?: boolean;
};

type UseProjectsConfig = {
  clients?: Array<string>;
};

type UseTopicsConfig = {
  projects?: Array<string>;
};

type UseProjectionsConfig = {
  year: string;
};

type UseInvoicesConfig = {};

export default class Store {
  private updater = new Updater();
  readonly api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  // Projets

  private projects: AsyncValue<Array<Model.Project>> | null = null;

  async loadProjects() {
    if (this.projects && AsyncValues.isResolved(this.projects)) return;
    this.projects = { loading: true };
    this.updater.update();
    try {
      const projects = await this.api.getProjects();
      this.projects = { loading: false, ok: true, value: projects };
      this.updater.update();
    } catch (err) {
      this.projects = { loading: false, ok: false, error: err };
      this.updater.update();
    }
  }

  useProjects(config: UseProjectsConfig = {}): Array<Model.Project> {
    const clients = config.clients || null;
    const configHash = hash({ clients });
    return this.updater.useValue(() => {
      let output = AsyncValues.getValue(this.projects, []);
      if (Array.isArray(clients)) {
        output = output.filter((p) => clients.includes(p.ref_client));
      }
      return output;
    }, [configHash]);
  }

  useProject(id: string) {
    return this.updater.useValue(() => {
      const projects = AsyncValues.getValue(this.projects, []);
      const project = projects.find((c) => c.id === id);
      return project || null;
    });
  }

  mergeProject(projects: Array<Model.Project>) {
    if (!this.projects || !AsyncValues.isResolved(this.projects)) return;
    this.projects.value = DataSet.upsertAll(this.projects.value, projects);
    this.updater.update();
  }

  // Tasks

  private tasks: Array<Model.Task> = [];

  // async loadTasks(projects: Array<string> | null, timelapse: Timelapse | null) {
  //   const newTasks = await this.api.getTasks(projects, timelapse);
  //   this.tasks = uniqBy([...newTasks, ...this.tasks], (t) => t.id);
  //   this.updater.update();
  // }

  getTasks(config: UseTasksConfig = {}) {
    const projects = config.projects || null;
    const timelapse = config.timelapse || null;
    const dropEmpty = config.dropEmpty || false;

    let output = [...this.tasks];
    if (projects) {
      output = output.filter((t) => projects.includes(t.ref_project));
    }
    if (timelapse) {
      output = output.filter((t) => {
        return Timelaspses.includes(timelapse, t.day);
      });
    }
    if (dropEmpty) {
      output = output.filter((t) => {
        return t.quantity > 0;
      });
    }
    return output;
  }

  // useTasks(config: UseTasksConfig = {}) {
  //   const projects = config.projects || null;
  //   const timelapse = config.timelapse || null;
  //   const load = config.load || false;
  //   const configHash = hash({ projects, timelapse });

  //   useEffect(() => {
  //     if (!load) return undefined;
  //     else this.loadTasks(projects, timelapse);
  //   }, [configHash]);

  //   return this.updater.useValue(() => {
  //     return this.getTasks(config);
  //   }, [configHash]);
  // }

  mergeTasks(tasks: Array<Model.Task>) {
    this.tasks = DataSet.upsertAll(this.tasks, tasks);
    this.updater.update();
  }

  deleteTasks(tasks: Array<string>) {
    this.tasks = this.tasks.filter((t) => !tasks.includes(t.id));
    this.updater.update();
  }

  // Extras

  private extras: Array<Model.Extra> = [];

  async loadExtras(
    projects: Array<string> | null,
    timelapse: Timelapse | null,
  ) {
    const newExtras = await this.api.getExtras(projects, timelapse);
    this.extras = uniqBy([...newExtras, ...this.extras], (t) => t.id);
    this.updater.update();
  }

  getExtras(config: UseExtrasConfig = {}) {
    const projects = config.projects || null;
    const timelapse = config.timelapse || null;
    const dropEmpty = config.dropEmpty || false;

    let output = [...this.extras];
    if (projects) {
      output = output.filter((t) => projects.includes(t.ref_project));
    }
    if (timelapse) {
      output = output.filter((t) => {
        return Timelaspses.includes(timelapse, t.day);
      });
    }
    if (dropEmpty) {
      output = output.filter((t) => {
        return t.price > 0;
      });
    }
    return output;
  }

  useExtras(config: UseExtrasConfig = {}) {
    const projects = config.projects || null;
    const timelapse = config.timelapse || null;
    const load = config.load || false;
    const configHash = hash({ projects, timelapse });

    useEffect(() => {
      if (!load) return undefined;
      else this.loadExtras(projects, timelapse);
    }, [configHash]);

    return this.updater.useValue(() => {
      return this.getExtras(config);
    }, [configHash]);
  }

  mergeExtras(extras: Array<Model.Extra>) {
    this.extras = DataSet.upsertAll(this.extras, extras);
    this.updater.update();
  }

  deleteExtras(extras: Array<string>) {
    this.extras = this.extras.filter((t) => !extras.includes(t.id));
    this.updater.update();
  }
}
