import Model from 'src/utilities/Model';
import { Reaction } from '../Clickable';
import ListItem from '../Panel/ListItem';

type TopicItemProps = {
  topic: Model.Topic;
} & Reaction;

export default function TopicItem(props: TopicItemProps) {
  const { topic, ...reaction } = props;

  return (
    <ListItem
      label={topic.label}
      color={topic.color ? topic.color : undefined}
      {...reaction}
    />
  );
}
